import {getObjectByPrefix} from '../utils/object';
import keys from '../utils/keys';

export const getButtonProps = (props: Object, prefix: string = 'button') =>
  mapKeysToProps(keys('ButtonProps'), getObjectByPrefix(prefix, props));

export const mapKeysToProps = (keys: any, propsIn: Object) => {
  return keys
    ? keys.reduce((newObj: Object, key: string) => {
        if (propsIn[key] !== undefined && key !== 'children') {
          newObj[key] = propsIn[key];
        }

        return newObj;
      }, {})
    : {};
};
