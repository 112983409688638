import {isMobile} from "mobile-device-detect";
import keys from '../utils/keys';
import {
  CarouselProps,
  Cloudinary,
  ConfigProps,
  ConfigSelectors,
  IndicatorProps,
  MediaSymbolProps,
  NavigationButtonProps,
  SelectedItemProps,
} from '../typing';

import {Axis, CarouselLocation, CarouselStyle, DisplayMode, PreloadTypes,} from '../typing/enums';
import {mapKeysToProps} from './props';
import {getObjectByPrefix, isObject} from '../utils/object';
import {toCamelCase} from '../utils/string';
import {getWidth} from '../utils/dom';

export const selectConfig = (config: ConfigProps): ConfigProps => config;

export const selectThumbnailsCarouselProps = (
  config: ConfigProps
): CarouselProps => ({
  navigationIconColor: config.themeProps.onPrimary,
  navigationColor: config.themeProps.primary,
  ...mapKeysToProps(keys('CarouselProps'), config.thumbnailProps),
  axis: selectAxis(config),
  itemWidth: config.thumbnailProps.width,
  itemHeight: config.thumbnailProps.height,
  navigation: true,
});

export const selectThumbnailSelectedProps = (
  config: ConfigProps
): SelectedItemProps => {
  const props = getObjectByPrefix('selected', config.thumbnailProps);
  const themeProps = {
    gradientStart: config.themeProps.primary,
    gradientEnd: config.themeProps.primary,
    borderColor: config.themeProps.active,
  };

  return mapKeysToProps(keys('SelectedItemProps'), {...themeProps, ...props});
};

export const selectMediaSymbolProps = (
  config: ConfigProps
): MediaSymbolProps => {
  const propsIn: any = getObjectByPrefix('mediaSymbol', config.thumbnailProps);

  const props = Object.keys(propsIn).reduce(
    (acc: {[key: string]: string}, propName: string) => {
      acc[toCamelCase(propName.replace('bg', ''))] = propsIn[propName];
      return acc;
    },
    {}
  );

  const themeProps = {
    iconColor: config.themeProps.onPrimary,
    color: config.themeProps.primary,
  };

  return mapKeysToProps(keys('MediaSymbolProps'), {...themeProps, ...props});
};

export const selectIndicatorProps = (config: ConfigProps): IndicatorProps => {
  const themeProps = {
    color: config.themeProps.onPrimary,
    selectedColor: config.themeProps.active,
  };

  return mapKeysToProps(keys('IndicatorProps'), {
    ...themeProps,
    ...config.indicatorProps,
  });
};

export const selectZoomPopupProps = (config: ConfigProps): IndicatorProps => {
  const themeProps = {
    buttonColor: config.themeProps.onPrimary,
    buttonIconColor: config.themeProps.primary,
  };

  return mapKeysToProps(keys('ZoomPopupProps'), {
    ...themeProps,
    ...config.zoomPopupProps,
  });
};

export const selectNavigationButtonProps = (
  config: ConfigProps
): NavigationButtonProps =>
  mapKeysToProps(keys('NavigationButtonProps'), {
    iconColor: config.themeProps.onPrimary,
    color: config.themeProps.primary,
    ...config.navigationButtonProps,
  });

export const selectAxis = (config: ConfigProps): Axis =>
  selectCarouselLocation(config) === CarouselLocation.LEFT ||
  selectCarouselLocation(config) === CarouselLocation.RIGHT
    ? Axis.VERTICAL
    : Axis.HORIZONTAL;

export const selectThumbnailWidth = (config: ConfigProps): number =>
  config.thumbnailProps.width || 0;

export const selectThumbnailHeight = (config: ConfigProps): number =>
  config.thumbnailProps.height || 0;

export const selectViewerWidth = (config: ConfigProps): number => {
  const columns = selectDisplayPropsMode(config) === DisplayMode.EXPANDED && config.displayProps && config.displayProps.columns || 1;
  const container = selectContainer(config);
  const isIndicators = config.carouselStyle === CarouselStyle.INDICATORS;
  const isNone = config.carouselStyle === CarouselStyle.NONE;

  const targetWidth: number =
    (isIndicators
      ? config.indicatorProps.size
      : isNone
      ? 0
      : config.thumbnailProps.width) || 0;

  const axis = selectAxis(config);

  return container
    ? getWidth(container) -
        (axis === Axis.VERTICAL && !config.thumbnailContainer ? (columns === 1 && targetWidth || 0) : 0) -
        (axis === Axis.VERTICAL ? config.carouselOffset || 0 : 0)
    : 0;
};

export const selectContainer = (config: ConfigProps): HTMLElement | null => {
  return config.container instanceof HTMLElement
    ? config.container
    : document.querySelector(config.container);
};

export const selectPreloadImage = (config: ConfigProps): boolean => {
  return config.preload
    ? config.preload.indexOf(PreloadTypes.IMAGE) > -1
    : false;
};

export const selectPreloadVideo = (config: ConfigProps): boolean => {
  return config.preload
    ? config.preload.indexOf(PreloadTypes.VIDEO) > -1
    : false;
};

export const selectPreloadSpin = (config: ConfigProps): boolean => {
  return config.preload
    ? config.preload.indexOf(PreloadTypes.SPIN) > -1
    : false;
};

export const selectPreloadThree = (config: ConfigProps): boolean => {
  return config.preload
    ? config.preload.indexOf(PreloadTypes.THREE) > -1
    : false;
};

export const selectSticky = (config: ConfigProps): boolean => {
  const carouselStyle = config.carouselStyle === CarouselStyle.THUMBNAILS ? 'thumbnailProps' : 'indicatorProps';
  return config[carouselStyle] && config[carouselStyle].sticky || false;
};

export const selectCarouselLocation = (config: ConfigProps): string => {
  let location = config.carouselLocation;
  // In expanded mode and desktop - force thumbnails to either left or right
  if (config.displayProps && config.displayProps.mode === DisplayMode.EXPANDED && !isMobile) {
    location = config.carouselLocation !== CarouselLocation.RIGHT ? CarouselLocation.LEFT :  CarouselLocation.RIGHT;
  }

  return location;
};

// For now, we support only CLASSIC display mode for mobile
export const selectDisplayPropsMode = (config: ConfigProps): string => {
  return !isMobile && config.displayProps && config.displayProps.mode || DisplayMode.CLASSIC;
};


export const selectors = {
  selectConfig,
  selectAxis,
  selectViewerWidth,
  selectThumbnailWidth,
  selectThumbnailHeight,
  selectContainer,
  selectThumbnailsCarouselProps,
  selectThumbnailSelectedProps,
  selectMediaSymbolProps,
  selectIndicatorProps,
  selectNavigationButtonProps,
  selectPreloadImage,
  selectPreloadVideo,
  selectPreloadSpin,
  selectPreloadThree,
  selectZoomPopupProps,
  selectSticky,
  selectCarouselLocation,
  selectDisplayPropsMode
};

export const bindSelectorsToConfig = (
  config: ConfigProps,
  cloudinary: Cloudinary
): ConfigSelectors => {
  const autoSelectors = keys('ConfigProps').reduce(
    (acc: Object, key: string) => {
      const selectorName = toCamelCase(`select ${key}`);

      if (!/children/.test(key)) {
        if (isObject(config[key]) && ~key.toLowerCase().indexOf('props')) {
          Object.keys(config[key]).forEach(k => {
            acc[toCamelCase(`select ${key} ${k}`)] = () => config[key][k];
          });
        }

        acc[selectorName] = () => config[key];
      }

      return acc;
    },
    {}
  );

  const customSelectors = Object.keys(selectors).reduce(
    (acc: Object, selector: string) => {
      acc[selector] = selectors[selector].bind(null, config, cloudinary);

      return acc;
    },
    {}
  );

  return {
    __orig: config,
    ...selectors,
    ...autoSelectors,
    ...customSelectors,
  };
};
