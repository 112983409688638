import styled from 'react-emotion';
import {Axis, Transition} from '../../typing/enums';
interface Props {
  axis: Axis;
  perView: number;
  itemWidth: number;
  itemHeight: number;
  spacing: number;
  children: [];
  transition: Transition;
  slideTo: number;
}

const getWrapperCls = (props: Props) => {
  switch (props.axis) {
    case Axis.VERTICAL:
      return `
                flex-direction: column;
                width: ${props.itemWidth}px;
                height: 100%;
            `;
    case Axis.HORIZONTAL:
      return `
                width: 100%;
                height: ${props.itemHeight}px;
            `;
    default:
      return ``;
  }
};

export const Wrapper: any = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${(props: Props) => getWrapperCls(props)};
`;

const getCarouselWrapperCls = (props: Props) => {
  switch (props.axis) {
    case Axis.VERTICAL:
      return `
                width: ${props.itemWidth}px;
                height: ${
                  props.perView
                    ? (props.itemHeight + props.spacing) * props.perView + 'px'
                    : '100%'
                };
            `;
    case Axis.HORIZONTAL:
      return `
                height: 100%;
                width: ${
                  props.perView
                    ? (props.itemWidth + props.spacing) * props.perView + 'px'
                    : '100%'
                };
            `;
    default:
      return ``;
  }
};

export const CarouselWrapper: any = styled('div')`
  display: block;
  position: relative;
  overflow: hidden;
  ${(props: Props) => getCarouselWrapperCls(props)};
`;

const getCarouselInnerWrapperCls = (props: Props): string => {
  if (props.transition === Transition.SLIDE) {
    switch (props.axis) {
      case 'vertical':
        return `
                        flex-direction: column;
                        transform: translate(0, ${props.slideTo}px);
                        height: ${(props.itemHeight + props.spacing) *
                          props.children.length -
                          props.spacing}px;
                        width: 100%;
                    `;
      case 'horizontal':
        return `
                        transform: translate(${props.slideTo}px);
                        width: ${(props.itemWidth + props.spacing) *
                          props.children.length -
                          props.spacing}px;
                    `;
      default:
        return ``;
    }
  } else {
    return `
            position: relative;
            height: ${props.itemHeight}px;
        `;
  }
};
//
export const CarouselInnerWrapper: any = styled('div')`
  display: flex;
  // transition: transform 0.25s ease-in-out;

  img {
    display: block;
    max-width: 100%;
  }

  ${(props: Props) => getCarouselInnerWrapperCls(props)};
`;
