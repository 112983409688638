import { Axis, DisplayMode } from "../typing/enums";

type SizeByAxisMode = {
    axis: Axis,
    mode: DisplayMode,
    wrapWidth: number,
    wrapHeight: number,
};

export const getSizeByAxisAndDisplayMode = (data: SizeByAxisMode) =>
    data.axis === Axis.HORIZONTAL ?
        `width: ${data.wrapWidth}px;` :
        (data.mode === DisplayMode.CLASSIC ? `height: ${data.wrapHeight}px;` : 'height: auto;');