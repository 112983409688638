import {snakeToCamel} from './string';

export const getObjectByPrefix = (prefix: string, obj: Object): Object => {
  return Object.keys(obj)
    .filter((key: string) => key.startsWith(prefix))
    .reduce((newObj: Object, key: string): Object => {
      let newKey = key.replace(prefix, '');
      newKey = newKey.replace(newKey.charAt(0), newKey.charAt(0).toLowerCase());
      newObj[newKey] = obj[key];

      return newObj;
    }, {});
};

export const keysFromSnakeToCamel = (obj: {}): {} => {
  return {
    ...Object.keys(obj).reduce((acc: {}, key: string) => {
      acc[snakeToCamel(key)] = obj[key];
      return acc;
    }, {}),
  };
};

export const isObject = (value: any): boolean =>
  (value && value.constructor === Object) || false;

export const isEmpty = (value: any): boolean => {
  return isObject(value) ? Object.keys(value).length === 0 : false;
};

// not supporting arrays  - not needed at the momemnt
export const objectToQuerystring = (obj: {} = {}) =>
  Object.keys(obj).reduce(function(str: string, key: string, i: number) {
    let delimiter, val;
    delimiter = i === 0 ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(obj[key]);
    return [str, delimiter, key, '=', val].join('');
  }, '');
