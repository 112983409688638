import {Component, h} from 'preact';
import styled from 'react-emotion';
import {Context} from '../App/App';
import Carousel from '../Carousel/Carousel';
import Asset from '../Asset/Asset';
import {MediaAssets} from '../../typing';
import {Axis, MediaSymbolTypes, Navigation} from '../../typing/enums';
import {VIEWER_DEFAULTS} from '../../config/defaults';
import {getTransformation} from "../../utils/assetHelpers";
import NavButton from "../NavButton/NavButton";

export interface CarouselViewerProps {
  width: number;
  height: number;
  index: number;
  items: MediaAssets;
  onItemSwipe?: Function;
  navigation: Navigation;
    prevItem: Function,
    nextItem: Function,
}

export interface CarouselViewerState {
  isZoomPopup: boolean;
}

const $Wrap: any = styled('div')`
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > img {
    max-width: 100%;
    width: 100%;
  }

  border: ${(props: any) =>
    props.borderWidth + 'px solid ' + props.borderColor};
  border-radius: ${(props: any) => props.radius}px;
`;

class CarouselViewer extends Component<CarouselViewerProps> {
  render(propsIn: CarouselViewerProps, state: CarouselViewerState) {
    const props = { ...VIEWER_DEFAULTS, ...propsIn };

    return (
      <Context.Consumer>
        {({ config }) => {

            const navigationProps = {
                navigation: props.navigation,
                offset: config.selectNavigationOffset(),
                ...config.selectNavigationButtonProps(),
            };

          return (
            <$Wrap
              data-test="carousel-viewer-wrap"
              borderWidth={config.selectBorderWidth()}
              borderColor={config.selectBorderColor()}
              radius={config.selectRadius()}
            >
              <Carousel
                index={props.index}
                perView={1}
                itemWidth={props.width}
                itemHeight={props.height}
                spacing={0}
                transition={config.selectTransition()}
                axis={Axis.HORIZONTAL}
                navigation={false}
                onItemSwipe={props.onItemSwipe || undefined}
                allowSwipe={() =>
                  props.items[props.index].mediaType === MediaSymbolTypes.VIDEO ||
                  props.items[props.index].mediaType === MediaSymbolTypes.IMAGE
                }
              >
                {props.items.map((item: any, index: number) => {
                  return (
                    <Asset
                      {...item}
                      index={props.index}
                      mediaType={item.mediaType}
                      resourceType={item.resourceType}
                      width={props.width}
                      height={props.height}
                      useBreakpoint={true}
                      inView={index === props.index}
                      zoom={config.selectZoom()}
                      transformation={getTransformation(
                        item.transformation,
                        config
                      )}
                    />
                  );
                })}
              </Carousel>
            {props.index > 0 ? (
                <NavButton
                    {...navigationProps}
                    direction="left"
                    onClick={props.prevItem}
                    position={config.selectNavigationPosition()}
                />
            ) : null}
            {props.index < props.items.length - 1 ? (
                <NavButton
                    {...navigationProps}
                    direction="right"
                    onClick={props.nextItem}
                    position={config.selectNavigationPosition()}
                />
            ) : null}
            </$Wrap>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default CarouselViewer;
