import {h} from 'preact';
import {css} from 'react-emotion';
import Button from '../Button/Button';
import {CarouselButtonProps} from '../../typing';
import {ButtonShape} from '../../typing/enums';
import {CAROUSEL_DEFAULTS} from '../../config/defaults';

const getIcon = (isHorizontal: boolean, isLeft: boolean): string =>
  isHorizontal
    ? isLeft
      ? 'arrowLeft'
      : 'arrowRight'
    : isLeft
    ? 'arrowUp'
    : 'arrowDown';

export const calcAbsCenter = (wrapSize: number, elemSize: number) =>
  (wrapSize - elemSize) / 2;

export const CarouselNavButton = (props: CarouselButtonProps) => {
  const propsWithDefault = {
    shape: ButtonShape.RECTANGLE,
    iconColor: CAROUSEL_DEFAULTS.navigationIconColor || '',
    size: 'lg',
    gutter: 0,
    ...props,
  };

  const isHorizontal = props.axis === 'horizontal';
  const isLeft = props.direction === 'left';
  const isRectangle = props.shape === 'rectangle';
  const icon = getIcon(isHorizontal, isLeft);
  const isAbs = props.float;

  let buttonProps = {
    ...propsWithDefault,
    icon,
    horizontal: !isHorizontal,
    className: css`
      ${isRectangle && isHorizontal ? 'height: 100%' : ''} ${isRectangle &&
      !isHorizontal
        ? 'padding: 0'
        : ''};
    `,
  };

  const wrapCls = css`
    ${isRectangle ? 'align-self: stretch' : ''};
    ${isRectangle ? 'padding: 0' : ''};
    
    /* ${
      !isRectangle
        ? isHorizontal
          ? 'margin: ' + propsWithDefault.gutter + 'px'
          : 'padding:0'
        : ''
    }; */
    ${
      isHorizontal
        ? isLeft
          ? 'margin-right: ' + propsWithDefault.gutter + 'px'
          : 'margin-left: ' + propsWithDefault.gutter + 'px'
        : isLeft
        ? 'margin-bottom: ' + propsWithDefault.gutter + 'px'
        : 'margin-top: ' + propsWithDefault.gutter + 'px'
    };
    
    ${isAbs ? 'position: absolute; z-index: 1;' : ''}
    ${
      isAbs && !isHorizontal && isLeft
        ? isRectangle
          ? 'top: 0; left: 0;width: 100%;'
          : 'top: 0; left: ' +
            calcAbsCenter(props.wrapWidth, buttonProps.size) +
            'px'
        : ''
    }  
    ${
      isAbs && !isHorizontal && !isLeft
        ? isRectangle
          ? 'bottom: 0; left: 0; width: 100%;'
          : 'bottom: 0; left: ' +
            calcAbsCenter(props.wrapWidth, buttonProps.size) +
            'px'
        : ''
    } 
    ${
      isAbs && isHorizontal && isLeft
        ? isRectangle
          ? 'top: 0; left: 0 ;height: 100%;'
          : 'left: 0; top: ' +
            calcAbsCenter(props.wrapWidth, buttonProps.size) +
            'px'
        : ''
    }  
    ${
      isAbs && isHorizontal && !isLeft
        ? isRectangle
          ? 'top: 0; right: 0; height: 100%;'
          : 'right: 0; top: ' +
            calcAbsCenter(props.wrapWidth, buttonProps.size) +
            'px'
        : ''
    }
  `;

  return (
    <div
      className={wrapCls}
      data-test={`carousel-nav-${isLeft ? 'prev' : 'next'}`}
    >
      <Button {...buttonProps} />
    </div>
  );
};

export default CarouselNavButton;
