import * as E from '../typing/enums';

export const BUTTON_DEFAULTS = {
  type: E.ButtonShape.ROUND,
  color: '#FFF',
  bgColor: '#000',
  size: E.ButtonSizes.MD,
  disabled: false,
  icon: '',
  borderColor: 'transparent',
  borderWidth: 0,
};

export const ZOOM_DEFAULTS = {
  viewerPosition: E.ZoomViewerPosition.RIGHT,
  viewerOffset: 10,
  showLens: true,
  lensBorderWidth: 1,
  lensBgColor: '#000',
  lensOpacity: 0.5,
  lensShadow: true,
};

export const CAROUSEL_DEFAULTS = {
  itemWidth: 50,
  itemHeight: 50,
  index: 0,
  axis: E.Axis.HORIZONTAL,
  transition: E.Transition.SLIDE,
  spacing: 0,
  children: [],
  perView: 5,
  navigation: true,
  navigationShape: E.ButtonShape.RECTANGLE,
  navigationColor: BUTTON_DEFAULTS.bgColor,
  navigationIconColor: BUTTON_DEFAULTS.color,
  navigationSize: E.ButtonSizes.LG,
  navigationGutter: 0,
};

export const MEDIA_ICON_DEFAULTS = {
  type: E.MediaSymbolTypes.IMAGE,
  shape: E.MediaSymbolShape.NONE,
  iconColor: '#000',
  iconShadow: true,
  size: E.ButtonSizes.MD,
};

export const MEDIA_ICON_WITH_BG_DEFAULTS = {
  ...MEDIA_ICON_DEFAULTS,
  color: '#000000',
  iconColor: '#FFF',
  iconShadow: false,
  opacity: 0.3,
  size: E.ButtonSizes.MD,
};

export const THUMBNAILS_CONFIG_DEFAULTS = {
  transformation: {},
  spacing: 2,
  gutter: 2,
  perView: 0,
  width: 64,
  height: 64,
  borderWidth: 1,
  radius: 0,
  borderColor: '#EBF0F4',
  navigationBorderColor: 'transparent',
  navigationBorderWidth: 0,
  navigationSize: CAROUSEL_DEFAULTS.navigationSize,
  navigationGutter: CAROUSEL_DEFAULTS.navigationGutter,
  navigationShape: CAROUSEL_DEFAULTS.navigationShape,
  navigationFloat: false,
  mediaSymbolPosition: E.MediaSymbolPosition.CENTER,
  mediaSymbolOpacity: MEDIA_ICON_WITH_BG_DEFAULTS.opacity,
  mediaSymbolShape: E.MediaSymbolShape.ROUND,
  mediaSymbolIconShadow: false,
  selectedStyle: E.SelectedStyles.ALL,
  selectedBorderPosition: E.SelectedBorderPosition.LEFT,
  selectedBorderWidth: 2,
  selectedBorderOpacity: 1,
  selectedGradientOpacity: 0.5,
  selectedGradientDirection: E.Direction.VERTICAL,
  sticky: true,
};

export const THUMBNAILS_DEFAULTS = {
  index: 0,
  selectedIndex: 0,
  items: [],
  axis: E.Axis.HORIZONTAL,
};

export const VIEWER_DEFAULTS = {
  width: 300,
  height: 300,
  index: 0,
  items: [],
};

export const VIEWER_CONFIG_DEFAULTS = {
  borderColor: 'transparent',
  borderWidth: 0,
  radius: 0,
};

export const INDICATOR_DEFAULTS = {
  size: 8,
  max: 9,
  spacing: 3,
  shape: E.IndicatorShape.ROUND,
};

export const INDICATOR_CONFIG_DEFAULTS = {
  size: INDICATOR_DEFAULTS.size,
  spacing: INDICATOR_DEFAULTS.spacing,
  total: INDICATOR_DEFAULTS.max,
};

export const GALLERY_DEFAULTS = {
  index: 0,
  carouselLocation: E.CarouselLocation.BOTTOM,
  axis: E.Axis.HORIZONTAL,
  items: [],
  navigation: E.Navigation.NONE,
  navigationPosition: E.GalleryNavigationPosition.INSIDE,
  navigationOffset: 0,
  navigationShape: E.ButtonShape.ROUND,
  navigationIconColor: '#FFF',
  navigationColor: '#000',
  navigationSize: 60,
};



export let CONFIG_DEFAULTS = {
  logErrors: true,
  debug: false,
  secure: true,
  focus: false,
  container: 'body',
  displayProps: {
    mode: E.DisplayMode.CLASSIC,
    spacing: 5,
    columns: 1,
    topOffset: 0,
    bottomOffset: 0,
  },
  mediaAssets: [],
  thumbnailContainer: undefined,
  transformation: {
    crop: E.Crop.PAD,
    background: '#FFFFFF',
  },
  aspectRatio: E.AspectRatio.SQUARE,
  analytics: false,
  transition: E.Transition.SLIDE,
  placeholderImage: true,
  preload: ['image'],
  imageBreakpoint: 100,
  startIndex: 0,
  sort: E.Sort.NONE,
  selectedIndex: 0,
  borderColor: 'transparent',
  borderWidth: 0,
  radius: 0,
  carouselLocation: E.CarouselLocation.LEFT,
  carouselStyle: E.CarouselStyle.THUMBNAILS,
  carouselOffset: 5,
  thumbnailProps: THUMBNAILS_CONFIG_DEFAULTS,
  indicatorProps: {
    size: 8,
    spacing: 4,
    total: 9,
    sticky: true,
  },
  navigation: E.Navigation.MOUSE_OVER,
  navigationPosition: E.GalleryNavigationPosition.INSIDE,
  navigationOffset: 0,
  navigationButtonProps: {
    size: 54,
    shape: E.ButtonShape.NONE,
  },
  zoom: true,
  zoomProps: {
    type: E.ZoomType.INLINE,
    level: 2,
    viewerPosition: E.ZoomViewerPosition.RIGHT,
    viewerOffset: 10,
    viewerRadius: 0,
    viewerZIndex: 100,
    showLens: true,
    lensBorderColor: '#FFF',
    lensBorderWidth: 0,
    lensRadius: 0,
    lensColor: '#FFF',
    lensOpacity: 0.5,
    lensShadow: true,
    trigger: E.ZoomTrigger.CLICK,
    container: undefined,
    tipText: 'Click to zoom',
    tipTouchText: 'Tap to zoom',
    tipPosition: E.TipPosition.BOTTOM,
    showTip: E.TipShow.TOUCH,
    disableZoom: false,
  },
  viewportBreakpoints: [
    {
      breakpoint: 520,
      navigation: E.Navigation.ALWAYS,
      carouselLocation: E.CarouselLocation.BOTTOM,
      thumbnailProps: {
        sticky: false,
        selectedBorderPosition: 'bottom',
      },
      displayProps: {
        mode: E.DisplayMode.CLASSIC,
      }
    },
  ],
  themeProps: {
    primary: '#FFFFFF',
    onPrimary: '#000000',
    active: '#0078ff',
    onActive: '#FFFFFF',
  },
  spinProps: {
    animate: E.SpinAnimation.START,
    animationDuration: 2000,
    spinDirection: E.SpinDirection.CLOCKWISE,
    disableZoom: false,
    tipText: 'Drag to rotate',
    tipTouchText: 'Swipe to rotate',
    tipPosition: E.TipPosition.BOTTOM,
    showTip: E.TipShow.ALL,
  },
  zoomPopupProps: {
    backdropColor: '#000000',
    backdropOpacity: 0.8,
    buttonShape: E.ZoomPopupShape.NONE,
    buttonSize: 42,
    zIndex: 100,
  },
  tipProps: {
    textColor: '#FFFFFF',
    color: '#000000',
    radius: 5,
    opacity: 0.5,
  },
  videoProps: {
    autoplay: true,
    loop: false,
    controls: 'none',
    sound: false,
  },
  loaderProps: {
    opacity: 0.5,
    color: '#000',
    size: 0,
    style: E.LoaderStyle.CLOUDINARY,
    url: '',
  },
};

export const RESOURCE_IMAGE_DEFAULTS = {
  publicId: '',
  mediaType: E.MediaSymbolTypes.IMAGE,
  resourceType: 'image',
  transformation: {},
};
