import {FunctionComponent, h} from 'preact';
import styled from 'react-emotion';
import BaseButton from './BaseButton';
import {ButtonProps} from '../../typing';

const $RoundButton = styled(BaseButton)`
  border-radius: 100%;
`;

const RoundButton: FunctionComponent<ButtonProps> = (props) => <$RoundButton {...props} />;

export default RoundButton;
