import {FunctionalComponent, h} from 'preact';
import BaseButton from './BaseButton';
import RectangleButton from './RectangleButton';
//import DiamondButton from './DiamondButton';
import RoundButton from './RoundButton';
import RadiusButton from './RadiusButton';
import TransparentButton from './TransparentButton';
import {ButtonProps} from '../../typing';
import {ButtonShape} from '../../typing/enums';
import {BUTTON_DEFAULTS} from '../../config/defaults';

const Button: FunctionalComponent<ButtonProps> = (propsIn) => {
  const props = {...BUTTON_DEFAULTS, ...propsIn},
    shape = props.shape;

  return shape === ButtonShape.ROUND ? (
    <RoundButton {...props} />
  ) : shape === ButtonShape.SQUARE ? (
    <BaseButton {...props} />
  ) : shape === ButtonShape.RADIUS ? (
    <RadiusButton {...props} />
  ) : shape === ButtonShape.RECTANGLE ? (
    <RectangleButton {...props} />
  ) : shape === ButtonShape.NONE ? (
    <TransparentButton {...props} />
  ) : (
    <BaseButton {...props} />
  );
};

export default Button;
