export interface IntersectionService{
    isIntersecting: (elm: HTMLElement) => boolean,
    destroy: Function
}

type ObserverOptions = {
    root?: Element | null,
    rootMargin?: string,
    threshold?: number | number[],
};

type Config = {
    observer?: ObserverOptions,
    debug?: boolean,
};

const observe = (
    selector: string | HTMLElement | HTMLElement[],
    config: Config,
    cb: Function
) : IntersectionService => {
    const elements = typeof selector === 'string' ?
        document.querySelectorAll(selector) :
        selector;

    const observerOptions = {
        //defaults
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
        //overrides
        ...config.observer,
    };

    const observer = new IntersectionObserver((entries) => {
        const crossed = entries.filter((entry) => entry.isIntersecting);

        if (crossed.length) {
            cb(crossed);
        }
    }, observerOptions);

    const targets = Array.prototype.slice.apply(elements);

    targets.forEach((target: HTMLElement) => {
        observer.observe(target);
    });

    if (config.debug) { //TODO: use rootBounds from Entry
        drawIntersectionArea(observerOptions);
    }

    //return a fn to turn off the observer
    return {
        isIntersecting: (elm: HTMLElement) => {
            const match = observer.takeRecords()
                .find((r: IntersectionObserverEntry) => r.target === elm);

            return match ? match.isIntersecting : false;
        },
        destroy: () => {
            targets.forEach((target: HTMLElement) => {
                observer.unobserve(target);
            });

            observer.disconnect();
        },
    };
};

const TurnToPositive = (value: string) => value[0] === '-' ? value.substr(1) : value;

// TODO improve element creation
const drawIntersectionArea = (options: any) => {
    let margins: string[] = options.rootMargin.split(' ');

    if (Array.isArray(margins) && margins.length === 1) {
        margins = new Array(4).fill(options.rootMargin);
    }

    const top = TurnToPositive(margins[0]),
        right = TurnToPositive(margins[1]),
        bottom = TurnToPositive(margins[2]),
        left = TurnToPositive(margins[3]),
        element: HTMLElement = document.createElement('div');

    element.setAttribute('id', 'intersection-area');
    element.setAttribute('style', `
                        pointer-events: none;
                        position: fixed;
                        background: rgba(0, 255, 0, 0.2);
                        top: ${top};
                        bottom: ${bottom};
                        right: ${right};
                        left: ${left}`);
    const infoElm = document.createElement('div');
    infoElm.setAttribute('style', `
                        width: 200px;
                        height: auto;
                        border: 1px solid black;
                        border-radius: 3px;
                        padding: 10px;
                        margin: 10px;
                        background: rgba(255, 255, 255, 0.8);
                        font-size: 12px;
                        pointer-events: all;
                        float: right;`);

    // We use innerHTML because margins value is not exposed in configuration.
    // If we choose to expose margins value, we should not use innerHTML
    infoElm.innerHTML = `
        <u><b>Intersection margins:</b></u><br />
        <div style="padding-left: 5px;">
            top: ${top}<br />
            right: ${right}<br />
            bottom: ${bottom}<br />
            left: ${left}
        </div>`;
    element.appendChild(infoElm);

    const oldElement = document.getElementById('intersection-area');
    oldElement && oldElement.remove();

    document.body.appendChild(element);
};

export default observe;
