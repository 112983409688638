export type Event = {[key: string]: {name: string; action: string}};

export const Events: Event = {
  MOUSE_ENTER: {name: 'mouseenter', action: 'Mouse Entered Widget'},
  MOUSE_LEAVE: {name: 'mouseleave', action: 'Mouse Left Widget'},
  THUMBNAIL_CLICK: {name: 'thumbclick', action: 'Thumbnail Clicked'},
  THUMBNAIL_HOVER: {
    name: 'thumbhover',
    action: 'Navigation on Thumbnail Hover',
  },
  INDICATOR_CLICK: {name: 'indicatorclick', action: 'Indicator Clicked'},
  THUMBNAIL_NEXT: {
    name: 'thumbnext',
    action: 'Next Thumbnail Navigation Button Clicked',
  },
  THUMBNAIL_PREV: {
    name: 'thumbprev',
    action: 'Previous Thumbnail Navigation Button Clicked',
  },
  VIEWER_PREV: {
    name: 'viewerprev',
    action: 'Previous Viewer Navigation Button Clicked',
  },
  VIEWER_NEXT: {
    name: 'viewernext',
    action: 'Next Viewer Navigation Button Clicked',
  },
  ZOOM_IN: {name: 'zoomin', action: 'Zoomed In'},
  ZOOM_OUT: {name: 'zoomout', action: 'Zoomed Out'},
  SPIN_START: {name: 'spinclick', action: 'Spin Image Clicked'},
  VIDEO_START: {name: 'videostart', action: 'Video Started'},
  VIDEO_PAUSE: {name: 'videopause', action: 'Video Paused'},
  VIDEO_PROGRESS: {name: 'videoprogress', action: 'Video Progress'},
};

// add video progress - percentage 25% ..... videoPause

export const createEvents = (
  registeredEvents: {[key: string]: Function[]},
  isAnalytics: boolean
) => {
  return (event: Event, label: string, data: any) => {
    const eventName = event.name.toString();
    const eventData = {
      category: 'product gallery widget',
      event: 'cloudinaryEvent',
      name: eventName,
      action: event.action,
      label: label,
      timestamp: new Date().getTime(),
      ...data,
    };

    if (window.dataLayer && isAnalytics) {
      window.dataLayer.push(eventData);
    }

    if (Object.keys(registeredEvents).length) {
      if (registeredEvents[eventName]) {
        registeredEvents[eventName].forEach(callback => {
          callback.call(undefined, eventData);
        });
      }
    }
  };
};
