import {Transformation} from '../../typing';

export const imageLoader = (
  cacher: Function,
  assets: any[] = [],
  cldApi: any,
  width: number,
  height: number,
  transformation: Transformation = {}
): Promise<HTMLImageElement[]> =>
  new Promise((resolve: any, reject: any) => {
    let totalLoaded = 0;
    let images: any[] = [];

    assets.forEach((asset: any, i: number) => {
      const url = cldApi.getImageUrl(
        asset.publicId,
        width,
        height,
        transformation
      );
      images.push('');

      cacher(asset.publicId, url, width, height, transformation).then(
        (loadedAsset: any) => {
          images[i] = loadedAsset.domNode;

          totalLoaded = totalLoaded + 1;
          if (totalLoaded === assets.length) {
            resolve(images);
          }
        },
        () => {
          if (totalLoaded === assets.length) {
            reject(url);
          }
        }
      );
    });
  });
