import {AspectRatio} from '../typing/enums';
import {Dimensions} from "../typing";

export const getDimensions = (
  width: number,
  aspectRatio: AspectRatio
):  Dimensions => {
  if (aspectRatio === AspectRatio.SQUARE || aspectRatio === AspectRatio.A_1_1) {
    return {
      width: Math.round(width), // need to work with integers to make calculation easier
      height: Math.round(width),
    };
  }

  const ar = aspectRatio.split(':');

  const height = width / (Number(ar[0]) / Number(ar[1]));

  return {
    width: Math.round(width),
    height: Math.round(height),
  };
};
