export enum DisplayMode {
    CLASSIC = 'classic',
    EXPANDED = 'expanded',
}

export enum Navigation {
  NONE = 'none',
  ALWAYS = 'always',
  MOUSE_OVER = 'mouseover',
}

export enum MediaSymbolPosition {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  CENTER = 'center',
}

export enum CarouselLocation {
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
  BOTTOM = 'bottom',
}

export enum CarouselStyle {
  THUMBNAILS = 'thumbnails',
  INDICATORS = 'indicators',
  NONE = 'none',
}

export enum GalleryNavigationPosition {
  INSIDE = 'inside', // equals offset zero
  MIDDLE = 'middle', // TODO: change to edge
  OFFSET = 'offset',
}

export enum Angle {
  ANGLE_0 = '0',
  ANGLE_90 = '90',
  ANGLE_180 = '180',
  ANGLE_270 = '-90',
}

export enum MediaSymbolShape {
  //DIAMOND = 'diamond',
  ROUND = 'round',
  SQUARE = 'square',
  RADIUS = 'radius',
  NONE = 'none',
}

export enum MediaSymbolTypes {
  SPIN = 'spin',
  VIDEO = 'video',
  IMAGE = 'image',
  THREE = '3d',
  NONE = 'none',
}

export enum Axis {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum Direction {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum ButtonShape {
  //DIAMOND = 'diamond',
  ROUND = 'round',
  SQUARE = 'square',
  RADIUS = 'radius',
  RECTANGLE = 'rectangle',
  NONE = 'none',
}

export enum ButtonSizes {
  SM = 16,
  MD = 32,
  LG = 42,
}

export enum Transition {
  SLIDE = 'slide',
  FADE = 'fade',
  NONE = 'none',
}

export enum SelectedStyles {
  ALL = 'all',
  BORDER = 'border',
  GRADIENT = 'gradient',
}

export enum SelectedBorderPosition {
  ALL = 'all',
  TOP = 'top',
  TOP_BOTTOM = 'top-bottom',
  LEFT_RIGHT = 'left-right',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum AspectRatio {
  SQUARE = 'square',
  A_1_1 = '1:1',
  A_16_9 = '16:9',
  A_9_16 = '9:16',
  A_3_4 = '3:4',
  A_4_3 = '4:3',
  A_4_6 = '4:6',
  A_6_4 = '6:4',
  A_5_7 = '5:7',
  A_7_5 = '7:5',
  A_8_5 = '8:5',
  A_5_8 = '5:8',
}

export enum IndicatorShape {
  ROUND = 'round',
  SQUARE = 'square',
  RADIUS = 'radius',
}

export enum ZoomViewerPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ZoomType {
  INLINE = 'inline',
  FLYOUT = 'flyout',
  POPUP = 'popup',
}

export enum ZoomButtonPosition {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
}

export enum ZoomTrigger {
  CLICK = 'click',
  HOVER = 'hover',
}

export enum AssetType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum EaseType {
  LINEAR = 'Linear',
  QUADRATIC = 'Quadratic',
  CUBIC = 'Cubic',
  QUARTIC = 'Quartic',
  QUINTIC = 'Quintic',
  SINUSOIDAL = 'Sinusoidal',
  EXPONENTIAL = 'Exponential',
  CIRCULAR = 'Circular',
  ELASTIC = 'Elastic',
  BACK = 'Back',
  BOUNCE = 'Bounce',
}

export enum LoaderPosition {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
  CENTER = 'center',
}

export enum Skin {
  ADIDAS = 'adidas',
  CA = 'ca',
}

export enum Crop {
  PAD = 'pad',
  FILL = 'fill',
}

export enum PreloadTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  SPIN = 'spin',
  THREE = '3d',
}

export enum SpinAnimation {
  START = 'start',
  END = 'end',
  BOTH = 'both',
  NONE = 'none',
}

export enum SpinDirection {
  CLOCKWISE = 'clockwise',
  COUNTERCLOCKWISE = 'counter-clockwise',
}

export enum Sort {
  NONE = 'none',
  ASC = 'asc',
  DESC = 'desc',
}

export enum TipPosition {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum TipShow {
  ALL = 'all',
  TOUCH = 'touch',
  DESKTOP = 'desktop',
}

export enum ZoomPopupShape {
  ROUND = 'round',
  SQUARE = 'square',
  RADIUS = 'radius',
  NONE = 'none',
}

export enum Controls {
  DEPRECATED_CONTROLS_ON = 'true',
  DEPRECATED_CONTROLS_OFF = 'false',
  NONE = 'none',
  ALL = 'all',
  PLAY = 'play'
}

export enum LoaderStyle {
  CLOUDINARY = 'cloudinary',
  CIRCLE = 'circle',
  CUSTOM = 'custom'
}
