import {h, Component} from 'preact';
import styled from 'react-emotion';
import {CarouselItemProps} from '../../typing';
import {Transition, Axis} from '../../typing/enums';
import {clickEventListener} from '../../utils/mouse';
import shallowCompare from '../../utils/shallowCompare';

const $Wrapper = styled('div')`
  user-select: none;
`;

const $SlideItemWrapper = styled('div')`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: CarouselItemProps) => props.width};
  height: ${(props: CarouselItemProps) => props.height};
  overflow: hidden;
  margin-${(props: CarouselItemProps) =>
    props.axis === Axis.VERTICAL ? 'bottom' : 'right'}: ${(
  props: CarouselItemProps
) => props.spacing}px;
  cursor: pointer;
`;

const $ItemWrapper = styled('div')`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  opacity: ${(props: CarouselItemProps) =>
    props.show || (props.show && props.transition === Transition.NONE) ? 1 : 0};
  z-index: ${(props: CarouselItemProps) =>
    props.show || (props.show && props.transition === Transition.NONE) ? 1 : 0};
  ${(props: CarouselItemProps) =>
    props.transition === Transition.FADE
      ? 'transition: opacity 0.25s ease-in;'
      : ''}
  width: ${(props: CarouselItemProps) => props.width};
  height: ${(props: CarouselItemProps) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

class CarouselItem extends Component<CarouselItemProps> {
  mouseClickTracker: any;
  trigger: HTMLElement;

  shouldComponentUpdate(nextProps: CarouselItemProps) {
    return shallowCompare(this, nextProps, {});
  }

  componentDidUpdate() {
    if (this.trigger && !this.mouseClickTracker) {
      this.mouseClickTracker = clickEventListener(this.trigger, () => {
        const {index, onItemClick} = this.props;

        if (onItemClick) {
          onItemClick(index);
        }
      });
    }
  }

  render() {
    const props = this.props;

    return (
      <$Wrapper
        data-test={`carousel-item-${props.index}`}
        innerRef={(elem: HTMLElement) => (this.trigger = elem)}
      >
        {props.transition !== Transition.SLIDE ? (
          <$ItemWrapper
            data-test="fade-item-wrap"
            onClick={
              props.onItemClick
                ? () => {
                    if (props.onItemClick) {
                      props.onItemClick(props.index);
                    }
                  }
                : null
            }
            onMouseOver={
              props.onItemHover
                ? () => {
                    if (props.onItemHover) {
                      props.onItemHover(props.index);
                    }
                  }
                : null
            }
            {...props}
          >
            {props.children}
          </$ItemWrapper>
        ) : (
          <$SlideItemWrapper
            data-test="slide-item-wrap"
            onMouseOver={
              props.onItemHover
                ? () => {
                    if (props.onItemHover) {
                      props.onItemHover(props.index);
                    }
                  }
                : null
            }
            {...props}
          >
            {props.children}
          </$SlideItemWrapper>
        )}
      </$Wrapper>
    );
  }
}

export default CarouselItem;
