import { h, Component } from 'preact';
import styled from 'react-emotion';
import observeIntersection, { IntersectionService } from '../../utils/intersctionService';
import scrollTo from "../../utils/scrollToHelper";

type Props = {
    itemHeight: number,
    scrollToIndex: number,
    items: Object[],
    renderItem: Function,
    itemProps?: Object,
    intersectThreshold: number,
    intersectionMargin: string,
    topOffset: number,
    columns: number,
    setSelected: Function,
};

const $ScrollWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

class ScrollListItems extends Component<Props> {

    itemsRefs: HTMLElement[] = [];
    windowInnerHeight: number = 0;
    itemsObserver : IntersectionService | null = null;
    viewableItems : number = 0;
    isDebugMode: boolean = false;

    componentDidMount(): void {
        this.windowInnerHeight = window.innerHeight;
        this.observeItems();
    }

    componentDidUpdate(prevProps: Props): void {

        if (prevProps.itemHeight !== this.props.itemHeight ||
            this.windowInnerHeight !== window.innerHeight ||
            prevProps.items !== this.props.items) {

            if (prevProps.items !== this.props.items) {
                this.itemsRefs.splice(this.props.items.length);
            }
            this.windowInnerHeight = window.innerHeight;
            this.observeItems();
        }

        const scrollToIndex = this.props.scrollToIndex;

        if (this.itemsObserver &&
            scrollToIndex !== null && scrollToIndex !== prevProps.scrollToIndex) {

            const elm = this.itemsRefs[scrollToIndex],
                isIntersecting = this.itemsObserver.isIntersecting(elm);

            if (!isIntersecting) {
                const offset = -(this.props.topOffset);
                scrollTo(elm, {offset});
            }
        }
    }

    observeItems = () => {
        if (this.itemsObserver) {
            this.itemsObserver.destroy();
        }

        if (this.itemsRefs.length) {
            this.checkDebugMode();
            this.itemsObserver = observeIntersection(this.itemsRefs, {
                observer: {
                    threshold: this.props.intersectThreshold,
                    rootMargin: this.props.intersectionMargin
                },
                debug: this.isDebugMode,
            }, (observed: IntersectionObserverEntry[]) => {

                if (observed.length) {
                    const intersectedElm: any = observed[0].target,
                        index = intersectedElm.dataset["index"];

                    this.props.setSelected(Number(index));
                }
            });
        }
    };

    setItemRef = (elm: HTMLElement, index: number) => {
        this.itemsRefs[index] = elm;
    };

    updateViewableItems = () =>  this.viewableItems = (Math.ceil(this.windowInnerHeight / this.props.itemHeight) || 1) * this.props.columns;

    checkDebugMode = () => this.isDebugMode = !!~window.location.search.indexOf("pgw-debug=true");

    render() {
        this.updateViewableItems();

        return <$ScrollWrapper>
            {this.props.items.map((item: any, index: number) =>
            this.props.renderItem(item, index, this.viewableItems, this.setItemRef))}
        </$ScrollWrapper>;
    }
}


export default ScrollListItems;
