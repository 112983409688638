import {h} from 'preact';
import styled, {keyframes, css} from 'react-emotion';
import {FlexCenterStretched} from '../../utils/emotion';
import * as tinycolor from 'tinycolor2';
import LoadingCssOnly from "./LoadingCssOnly";
import {LoaderStyle} from '../../typing/enums';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  opacity?: number;
  size?: number;
  className?: string;
  children?: any;
  style : string;
  url?: string;
}

const LOADING_DEFAULTS = {
  height: 4,
  color: '#000',
  opacity: 0.5,
  style: LoaderStyle.CLOUDINARY,
};

const QUARTER_OF_IMAGE_WIDTH = 0.25;

const $SVG = styled('svg')`
  position: relative;

  path {
    stroke-dasharray: 280;
    stroke-dashoffset: 0;
    transform-origin: center;
  }
`;

const Loading = (propsIn: Props) => {
  const props = {...LOADING_DEFAULTS, ...propsIn};
  const width = props.size || (props.width ? props.width * QUARTER_OF_IMAGE_WIDTH : 0);
  const showDefaultLoader = props.style === LoaderStyle.CLOUDINARY || (props.style === LoaderStyle.CUSTOM && !props.url);

  const color = tinycolor(props.color)
    .lighten(20)
    .toString();

  const lightColor = tinycolor(props.color)
    .lighten(40)
    .toString();

  const colorKeyframes = keyframes`
    0% {
      stroke: ${color};
    }
    50% {
      stroke: ${lightColor};
    }
    100% {
      stroke: ${color};
    }
  `;

  const dashKeyframes = keyframes`
    0% {
      stroke-dashoffset: 560;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 560;
    }
  `;

    const spinKeyframes = keyframes`
    100% {
      transform: rotate(360deg);
    }
  `;

    const spinDashKeyframes = keyframes`
   0% {
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
    }
        50% {
        stroke-dasharray: 180, 300;
        stroke-dashoffset: -70;
    }
        100% {
        stroke-dasharray: 180, 300;
        stroke-dashoffset: -248;
    }
`;

  const cldLoaderClassName = css`
    path {
      animation: ${dashKeyframes} 2s ease-in-out infinite,
        ${colorKeyframes} 8s ease-in-out infinite;
    }

    opacity: ${props.opacity};
  `;

  const circleLoaderClassName = css`
     background:0 0;
     opacity: ${props.opacity};
     animation: ${spinKeyframes} 2s linear infinite;
     
        circle {
            stroke: hsl(210, 70, 75);
            stroke-linecap: round;
            stroke-width: 8;
            animation: ${spinDashKeyframes} 1.5s ease-in-out infinite;
        }
  `;

  const Wrapper: any = styled(FlexCenterStretched)`
    .css-loader {
      display: none;
    }
    
    // for IE11 - show only pure css loader 
   @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      svg, .custom-loader {
        display: none;
      }
      
      .css-loader {
        display: block;
      }
   }
  `;

  return (
    <Wrapper
      relative
      className={props.className}
      data-test="loading"
    >
      <FlexCenterStretched absolute>{props.children}</FlexCenterStretched>

      {showDefaultLoader && <$SVG
        viewBox="-4 -4 151 100"
        preserveAspectRatio="xMidYMid"
        className={cldLoaderClassName}
        width={width}
      >
        <path
          d="M121.663 90.638c-1.796 0-99.33-.498-101.474-1.478C8.685 83.877 1.25 72.196 1.25 59.396c0-16.656 12.797-30.61 29.052-32.323 7.49-15.706 23.186-25.707 40.714-25.707 20.98 0 39.215 14.752 43.945 34.907 15.09.245 27.29 12.63 27.29 27.822 0 11.968-7.738 22.55-19.256 26.33"
          stroke-width="9"
          stroke-linecap="round"
          fill="none"
          fill-rule="evenodd"
          stroke={props.color}
        />
      </$SVG>}
      {props.style === LoaderStyle.CIRCLE && <$SVG
          width={width}
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          className={circleLoaderClassName}
      >
          <circle stroke={props.color} cx="50" cy="50" fill="none" r="40">
          </circle>
      </$SVG>}
      {props.style === LoaderStyle.CUSTOM && props.url && <img
          width={width}
          src={props.url}
          className="custom-loader"
      />}
      <LoadingCssOnly
      color={props.color}
      width={width}
      opacity={props.opacity}
      className="css-loader"
      />
    </Wrapper>
  );
};

export default Loading;
