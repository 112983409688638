import * as loadjs from 'loadjs';

export default (path: string): Promise<void> =>
  new Promise((resolve: any, reject: any) => {
    loadjs([path], 'three');

    loadjs.ready('three', {
      success: () => {
        resolve();
      },
      error: () => {
        reject();
      },
    });
  });
