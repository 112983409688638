import {h} from 'preact';
import styled from 'react-emotion';
import {Color} from '../../typing';
import {Axis, DisplayMode, IndicatorShape} from '../../typing/enums';
import {INDICATOR_DEFAULTS} from '../../config/defaults';
import {isOdd} from '../../utils/number';

interface IndicatorProps {
  amount: number;
  selected: number;
  axis: Axis;
  color: Color;
  size: number;
  shape: IndicatorShape;
  selectedColor: Color;
  onItemSelect: Function;
  spacing: number;
  wrapWidth: number;
  wrapHeight: number;
  max?: number;
  mode: DisplayMode,
}

const getSelectedSize = (size: number) => Math.round(size * 1.4);

const getItemSize = (props: IndicatorProps) => props.size + props.spacing * 2;

const getSelectedItemSize = (props: IndicatorProps) =>
  getSelectedSize(props.size) + props.spacing * 2;

const getItemsTotalSize = (props: IndicatorProps) =>
  getItemSize(props) * (props.amount - 1) + getSelectedItemSize(props);

const getItemsVisibleSize = (props: IndicatorProps) =>
  getItemSize(props) * (props.max || props.amount - 1) +
  getSelectedItemSize(props);

const isScroll = (props: IndicatorProps) =>
  props.amount > (props.max || props.amount);

const getPosition = (props: IndicatorProps) => {
  let position = 0;
  if (props.mode === DisplayMode.CLASSIC) {
    const i = props.selected,
        total = props.amount,
        max = props.max || props.amount,
        startIndex = Math.floor(max / 2),
        endIndex = total - startIndex;

    position = i > 0 && i > startIndex && total > max
        ? i >= endIndex
            ? -getItemSize(props) * (endIndex - startIndex - 1)
            : -getItemSize(props) * (i - startIndex)
        : 0;
  }

  return  position
};

const calcMaxItems = (props: IndicatorProps) => {
  const totalItemsWidth = getItemsTotalSize(props);
  const itemSize = getItemSize(props);
  const totalWrapSize =
    (props.axis === Axis.VERTICAL ? props.wrapHeight : props.wrapWidth) - 70;
  let total;

  if (totalItemsWidth > totalWrapSize) {
    total = Math.floor(totalWrapSize / itemSize);
  } else {
    total = isOdd(props.amount) ? props.amount : props.amount + 1;
  }

  return isOdd(total) ? total : total - 1;
};

// const isIncrementSizeWhenCenter = (props: IndicatorProps) => {
//   const i = props.selected,
//     total = props.amount;

//   return i - 2 > 0 && i + 2 < total;
// };

const $Wrap = styled('div')`
  display: flex;
  padding: 0;
  ${(props: any) =>
    props.axis === Axis.VERTICAL
      ? `width: ${getSelectedItemSize(props)}px; 
         height: ${props.mode === DisplayMode.CLASSIC ? `${getItemsVisibleSize(props)}px` : 'auto'};`
      : `height: ${getSelectedSize(props.size)}px; 
         width: ${getItemsVisibleSize(props)}px;`
  };
  overflow: ${(props: any) => props.mode === DisplayMode.CLASSIC ? 'hidden' : 'visible'};
  ${(props: any) =>
    !isScroll(props) ? 'align-items: center; justify-content: center;' : ''}
`;

const $InnerWrap = styled('div')`
  display: flex;
  ${props => (props.axis === Axis.VERTICAL ? 'flex-direction: column' : '')};
  align-items: center;
  justify-content: center;
  transform: translate(
    ${props => (props.axis === Axis.VERTICAL ? '0,' : '')}
      ${props => getPosition(props)}px
  );
  ${props => (props.axis === Axis.VERTICAL ? 'height' : 'width')}: ${(
    props: any
  ) => getItemsTotalSize(props)}px;
  transition: all 250ms;
`;

const $Indicator = styled('div')<{
  selected: boolean;
  color: Color;
  selectedColor: Color;
  size: number;
  spacing: number;
  shape: IndicatorShape;
}>`
  ${props =>
    props.shape === IndicatorShape.ROUND
      ? 'border-radius: 100%'
      : props.shape === IndicatorShape.SQUARE
      ? ''
      : props.shape === IndicatorShape.RADIUS
      ? 'border-radius:' + props.size / 5 + 'px'
      : ''};

  height: ${props => props.size}px;
  width: ${props => props.size}px;
  margin: ${props => props.spacing}px;
  ${props =>
    props.selected
      ? 'background-color: ' + props.selectedColor
      : 'background-color: ' + props.color};
  ${props => (props.selected ? '' : 'cursor: pointer')};
  transition: background-color 0.25s ease-in, width 0.15s, height 0.15s;
`;

const Indicators = (propsIn: IndicatorProps) => {
  const props = {...INDICATOR_DEFAULTS, ...propsIn};
  const max = calcMaxItems(props);

  return (
    <$Wrap {...props} data-test="indicators-wrap" max={max}>
      <$InnerWrap {...props} max={max}>
        {Array.apply(null, Array(props.amount)).map(
          (value: undefined, index: number) => (
            <$Indicator
              onClick={() => {
                props.onItemSelect(index);
              }}
              color={props.color}
              size={
                index === props.selected
                  ? getSelectedSize(props.size)
                  : props.size
              }
              shape={props.shape}
              spacing={props.spacing}
              selectedColor={props.selectedColor}
              selected={index === props.selected}
              data-test={`indicator-item-${index}`}
            />
          )
        )}
      </$InnerWrap>
    </$Wrap>
  );
};

export default Indicators;
