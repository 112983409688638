import { Component, h } from 'preact';
import { isMobile } from 'mobile-device-detect';
import styled from 'react-emotion';
import { AppContext, Dimensions, MediaAssets } from '../../typing';
import CarouselViewer from '../CarouselViewer/CarouselViewer';
import ScrollViewer from '../ScrollViewer/ScrollViewer';
import { DisplayMode, Axis, Navigation } from '../../typing/enums';
import { GALLERY_DEFAULTS } from '../../config/defaults';
import { Context } from '../App/App';

type StyledWrapperProps = {
    axis: Axis,
    navigation: Navigation,
};

type ViewerWrapperState = {
    scrollIndex: number | null,
};

type ViewerWrapperProps = {
    axis: Axis,
    items: MediaAssets,
    prevItem: Function,
    nextItem: Function,
    onItemSwipe: Function,
    index: number,
    mode: DisplayMode,
    viewerDims: Dimensions,
    setItem: Function,
};

const $ViewerWrapper = styled('div') <StyledWrapperProps>`
  position: relative;
  ${props =>
    props.axis === Axis.VERTICAL ? 'flex: 1 1 100%;width: 1px;' : ''};

  ${props =>
    props.navigation === Navigation.MOUSE_OVER
        ? '&:hover button { opacity: 1 }'
        : ''};
`;

class ViewerWrapper extends Component<ViewerWrapperProps, ViewerWrapperState> {

    lastScrolledIndex: number | null = null;

    state = {
        scrollIndex: null
    };

    componentDidUpdate(prevProps: ViewerWrapperProps): void {
        const index = this.props.index;

        // Set scrollTo for EXPANDED mode in desktop only
        if (prevProps.index !== undefined &&
            prevProps.index !== index &&
            index !== this.lastScrolledIndex &&
            this.props.mode === DisplayMode.EXPANDED && !isMobile) {
            this.setState(() => ({ scrollIndex: index }));
        }
    }

    setScrollItem = (index: number) => {
        this.lastScrolledIndex = index;
        this.props.setItem(index);

        if (this.state.scrollIndex !== null) {
            this.setState(() => ({ scrollIndex: null }));
        }
    };

    render(propsIn: ViewerWrapperProps) {

        const props = {...GALLERY_DEFAULTS, ...propsIn};

        return (<Context.Consumer>
            {({config}: AppContext) => {
                let navigation = config.selectNavigation();

                if (isMobile && navigation === Navigation.MOUSE_OVER) {
                    navigation = Navigation.ALWAYS;
                }

                const borderWidth = config.selectBorderWidth();
                const width = props.viewerDims.width - borderWidth * 2;
                const height = props.viewerDims.height - borderWidth * 2;

                return <$ViewerWrapper
                    axis={props.axis}
                    navigation={navigation}
                    data-test="gallery-viewer-wrap">
                    {(isMobile || props.mode === DisplayMode.CLASSIC) ?
                        <CarouselViewer
                            index={props.index}
                            items={props.items}
                            width={width}
                            height={height}
                            onItemSwipe={props.onItemSwipe}
                            navigation={navigation}
                            prevItem={props.prevItem}
                            nextItem={props.nextItem}
                        /> :
                        <ScrollViewer
                            index={props.index}
                            scrollToIndex={this.state.scrollIndex}
                            items={props.items}
                            width={width}
                            height={height}
                            setItem={this.setScrollItem}
                        />}
                </$ViewerWrapper>;
            }}</Context.Consumer>);
    }
}

export default ViewerWrapper;
