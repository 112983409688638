export const camelToSnake = (str: string) =>
    str.replace(/([A-Z])/g, function($1) {
        return '_' + $1.toLowerCase();
    });

export const snakeToCamel = (str: string) =>
    str.replace(/(\_\w)/g, function(m) {
        return m[1].toUpperCase();
    });

// https://jsperf.com/js-camelcase/5
export const toCamelCase = (str: string): string =>
    str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter: string, index: number) {
            return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
        })
        .replace(/\s+/g, '');
