import {h} from 'preact';
import {css, cx} from 'react-emotion';

interface Props {
  path: string | string[];
  className?: string;
  size?: number;
  color?: string;
  colorName?: string;
  title?: string;
  onClick?: any;
}

const SvgIcon = (props: Props) => {
  const baseCss = css({
    display: 'inline-block',
    width: !props.size ? '100%' : props.size,
    height: !props.size ? '100%' : props.size,
    cursor: props.onClick ? 'pointer' : '',
    '& path': {
      fill: props.colorName ? props.color : props.color,
    },
  });

  const path = props.path instanceof Array ? props.path : [props.path];

  return (
    <svg
      data-test="svg-image"
      className={cx(baseCss, props.className)}
      onClick={props.onClick}
      title={props.title}
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid"
    >
      {path.map(d => (
        <path d={d} />
      ))}
    </svg>
  );
};

export default SvgIcon;
