import {h} from 'preact';
import styled from 'react-emotion';
import SvgIcon from '../SvgIcon/SvgIcon';
import {Color} from '../../typing';
import {info} from '../../assets/icons';

interface Props {
  msg?: string;
  bgColor?: Color;
}

const $Wrap = styled('div')<{bgColor: Color}>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.bgColor};
  text-align: center;
`;

const NotFound = ({bgColor = 'transparent', msg}: Props) => {
  return (
    <$Wrap bgColor={bgColor}>
      <SvgIcon color={`#F9A825`} path={info} size={60} />
      <div data-test="not-found-message" style={{margin: '20px 0'}}>{msg}</div>
    </$Wrap>
  );
};

export default NotFound;
