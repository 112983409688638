import {h} from 'preact';
import {css} from 'react-emotion';
import SvgIcon from '../SvgIcon/SvgIcon';
import {error} from '../../assets/icons';
import {FlexCenterStretched} from '../../utils/emotion';
import {MediaSymbolTypes} from '../../typing/enums';

const Error = ({width}: {width: number; type: MediaSymbolTypes}) => {
  return (
    <FlexCenterStretched
      className={css`
        background-color: #e4ebf1;
      `}
    >
      <SvgIcon color="#C6D1E0" path={error} size={width / 2} />
    </FlexCenterStretched>
  );
};

export default Error;
