import { h, Component } from 'preact';
import { isMobile } from 'mobile-device-detect';
import styled, { css } from 'react-emotion';
import { Context } from '../App/App';
import { withContext } from '../../common/context';
import ViewerWrapper from "../ViewerWrapper/ViewerWrapper";
import {getSizeByAxisAndDisplayMode } from "../../utils/wrapperHelpers";
import {
  GalleryProps,
  GalleryState,
  AppContext,
} from '../../typing';
import {
    DisplayMode,
  Axis,
  CarouselLocation,
} from '../../typing/enums';

import { getWidth } from '../../utils/dom';
import debounce from '../../utils/debounce';
import { Events } from '../../utils/events';
import { getDimensions } from '../../utils/aspectRatio';
import AssetsNavigator from "../AssetsNavigator/AssetsNavigator";

type WrapperProps = {
  mode: DisplayMode,
  axis: Axis;
  wrapWidth: number;
  wrapHeight: number;
  children: any[];
  carouselLocation: CarouselLocation;
};

const flexDirectionCalculators = {
    [Axis.HORIZONTAL]: (carouselLocation: CarouselLocation) =>
        carouselLocation === CarouselLocation.TOP ? 'column-reverse' : 'column',
    [Axis.VERTICAL]: (carouselLocation: CarouselLocation) =>
        carouselLocation === CarouselLocation.LEFT ? 'row-reverse' : 'row',
};

const $Wrapper = styled("div")<WrapperProps>`
    display: flex;
    outline: none;
    
   flex-direction: ${props => 
        flexDirectionCalculators[props.axis](props.carouselLocation)};
    
    ${getSizeByAxisAndDisplayMode}
        
    // normalize css
    div{ box-sizing: border-box; user-select: none;}  
    img { border-style: none; max-width: 100%;  pointer-events: none; }
    button { font-family: inherit; /* 1 */ font-size: 100%; /* 1 */ line-height: 1.15; /* 1 */ margin: 0; /* 2 */ }
    button, input {/* 1 */ overflow: visible; }
    button {/* 1 */ text-transform: none; }
    button { -webkit-appearance: button; }
    button::-moz-focus-inner { border-style: none; padding: 0; }
    button:-moz-focusring { outline: 1px dotted ButtonText; }
    [hidden] { display: none; }
  `;

type SpacerProps = {
  axis: Axis;
  spacing: number;
};

const $Spacer = styled('div') <SpacerProps>`
  ${props =>
    props.axis === Axis.HORIZONTAL
      ? 'height: ' + props.spacing + 'px'
      : 'width: ' + props.spacing + 'px'};
  flex-shrink: 0;
`;

class Gallery extends Component<GalleryProps, GalleryState> {
  constructor(props: GalleryProps) {
    super(props);
  }

  wrapper: HTMLElement;
  onResize: any;

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(previousProps: Readonly<GalleryProps>) {
    this.HandleAssetsChanged();
  }

  componentDidMount() {
    const wrap = this.props.context.config.selectContainer();

    this.onResize = debounce(() => {
      if (wrap) {
        this.forceUpdate();
      } else {
        window.removeEventListener('resize', this.onResize);
      }
    }, 350);

    window.addEventListener('resize', this.onResize);

    if (this.props.context.config.selectFocus()) {
      this.wrapper.focus();
    }

    // remove highlight when clicking on element
    if (isMobile) {
      wrap.classList.add(css`
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent; /* For some Androids */
      `);
    }

    this.initializeIndex();
  }

  initializeIndex = () => {
      const startIndex = this.props.context.config.selectStartIndex();
      this.setItem(startIndex);
  };

  setItem = (index: number) => {
      const totalItems = this.props.items.length;

      this.setState(() => ({
          index: index < totalItems ? index : 0,
      }));
  };

  HandleAssetsChanged = () => {
    const itemsCount = this.props.items && this.props.items.length,
          currentIndex = this.state.index;

    if ( currentIndex >= itemsCount ) {
        this.setState(() => ({index: itemsCount - 1}));
    }
  };

  onMouseEnter = () => {
    this.props.context.events(
      Events.MOUSE_ENTER,
      this.props.items[this.state.index].publicId
    );
  };

  onMouseLeave = () => {
    this.props.context.events(
      Events.MOUSE_LEAVE,
      this.props.items[this.state.index].publicId
    );
  };

  nextItem = () => {
    if (this.state.index < this.props.items.length - 1) {
      this.props.context.events(
        Events.VIEWER_NEXT,
        this.props.items[this.state.index + 1].publicId
      );

      this.setState(() => ({
        index: this.state.index + 1,
      }));
    }
  };

  prevItem = () => {
    if (this.state.index > 0) {
      this.props.context.events(
        Events.VIEWER_PREV,
        this.props.items[this.state.index - 1].publicId
      );

      this.setState(() => ({
        index: this.state.index - 1,
      }));
    }
  };

  onItemSwipe = (nextIndex: number) => {
    if (nextIndex !== this.state.index) {
      this.props.context.events(
        nextIndex > this.state.index ? Events.VIEWER_NEXT : Events.VIEWER_PREV,
        this.props.items[nextIndex].publicId
      );

      this.setState(() => ({
        index: nextIndex,
      }));
    }
  };

  onKeyUp = (event: any) => {
    // down
    if (event.keyCode === 40 || event.keyCode === 39) {
      this.nextItem();
    }
    // up
    if (event.keyCode === 38 || event.keyCode === 37) {
      this.prevItem();
    }

    event.preventDefault();
  };

  render(propsIn: GalleryProps, state: GalleryState) {
    return (
      <Context.Consumer>
        {({ config }: AppContext) => {

            const { width } = getDimensions(
            getWidth(config.selectContainer()),
            config.selectAspectRatio()
          );

          const viewerDims = getDimensions(
            config.selectViewerWidth(),
            config.selectAspectRatio()
          );

          const axis = config.selectAxis(config.selectCarouselLocation()),
            mode = config.selectDisplayPropsMode(),
            columns = config.selectDisplayPropsColumns(),
            renderNavigator = (mode === DisplayMode.CLASSIC || columns === 1),
            sticky = config.selectSticky(),
            topOffset = config.selectDisplayPropsTopOffset(),
            bottomOffset = config.selectDisplayPropsBottomOffset();

          return (
            <$Wrapper
              mode={mode}
              carouselLocation={config.selectCarouselLocation()}
              axis={axis}
              wrapWidth={width}
              wrapHeight={viewerDims.height}
              innerRef={(elem: HTMLDivElement) => (this.wrapper = elem)}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              data-test="gallery-wrap"
              tabIndex={0}
              onKeyDown={this.onKeyUp}>
                <ViewerWrapper
                            index={this.state.index}
                            setItem={this.setItem}
                            items={propsIn.items}
                            axis={axis}
                            viewerDims={viewerDims}
                            mode={mode}
                            prevItem={this.prevItem}
                            nextItem={this.nextItem}
                            onItemSwipe={this.onItemSwipe}/>

            {renderNavigator && <$Spacer
                axis={axis}
                spacing={config.selectCarouselOffset()}
                data-test="gallery-spacer"
              />}

                {renderNavigator && <AssetsNavigator
		            axis={axis}
		            index={state.index}
		            items={propsIn.items}
		            selectItem={this.setItem}
		            viewerDims={viewerDims}
		            width={width}
		            mode={mode}
		            sticky={sticky}
		            topOffset={topOffset}
		            bottomOffset={bottomOffset} />}
            </$Wrapper>
          );
        }}
      </Context.Consumer>
    );
  }
}




export default withContext(Gallery);
