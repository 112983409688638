import {FunctionalComponent, h} from 'preact';
import styled from 'react-emotion';
import BaseButton from './BaseButton';
import {RectangleButtonProps} from '../../typing';

const $RectangleButton = styled(BaseButton)`
  width: ${(props: RectangleButtonProps) =>
    !props.horizontal ? 'auto' : '100%'};
  height: ${(props: RectangleButtonProps) =>
    props.horizontal ? 'auto' : 'inherit'};
  min-width: auto;
  min-height: auto;
`;

const RectangleButton: FunctionalComponent<RectangleButtonProps> = (props) => (
    <$RectangleButton {...props} />
);

export default RectangleButton;
