import { h, Component, createContext } from 'preact';
import { AppContext, ConfigProps, CloudinaryCore } from '../../typing/index';
import { CONFIG_DEFAULTS } from '../../config/defaults';
import { selectors, bindSelectorsToConfig } from '../../common/selectors';
import Gallery from '../Gallery/Gallery';
import { createEvents } from '../../utils/events';

export const Context = createContext<AppContext>({
  cloudinary: null,
  config: selectors,
  events: null,
  cacher: Function,
});

interface AppProps extends ConfigProps {
  cloudinaryCore: CloudinaryCore;
  events: { [key: string]: Function[] };
  cacher: Function;
  children?: any[];
  isNewMediaAssets: boolean;
}

class App extends Component<AppProps> {
  config: any;
  cldCore: any;
  events: any;

  constructor(props: AppProps) {
    super(props);

    this.setConfig(props);
    this.setCldCore(props);
    this.setEvents(props);
  }

  setConfig = (props: AppProps) => {
    const config = {
      ...CONFIG_DEFAULTS,
      ...props,
    };

    delete config.events;
    delete config.cacher;

    this.config = bindSelectorsToConfig(config, props.cloudinaryCore);
  };

  setCldCore = (props: AppProps) => {
    this.cldCore = props.cloudinaryCore;
  };

  setEvents = (props: AppProps) => {
    this.events = createEvents(props.events, this.config.selectAnalytics());
  };



  componentDidUpdate(prevProps: AppProps) {
    // need to force update because hwne changing between axis - the container width or height is updated after carousel has been rendered - causing the wrong calculations
    if (prevProps.carouselLocation !== this.props.carouselLocation) {
      this.forceUpdate();
    }
  }

  render(props: AppProps) {
    this.setConfig(props);

    const context = {
      cloudinary: this.cldCore,
      config: this.config,
      events: this.events,
      cacher: props.cacher,
    };

    /* In order to use context inside tests and not duplicate code */
    const isChildren =
      this.props.children && this.props.children.length ? true : false;
    return (
        <Context.Provider value={context}>
            {!isChildren ? <Gallery items={props.mediaAssets}
                                    index={props.isNewMediaAssets ? context.config.selectStartIndex() : null}/> : null}
        </Context.Provider>
    );
  }
}

export default App;
