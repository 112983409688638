import { h, Component } from 'preact';
import styled, { css, cx } from 'react-emotion';
import Carousel from '../Carousel/Carousel';
import MediaSymbol from '../MediaSymbol/MediaSymbol';
import Asset from '../Asset/Asset';
import { getRgbaColor } from '../../utils/color';
import { Events } from '../../utils/events';
import { isEmpty } from '../../utils/object';
import {
    ThumbnailsProps,
    MediaSymbolProps,
    BaseAsset,
    SelectedItemProps,
    ConfigSelectors,
    CarouselProps,
    Color,
    Transformation,
} from '../../typing';

import {
    AssetType,
    MediaSymbolTypes,
    MediaSymbolPosition,
    MediaSymbolShape,
    SelectedStyles,
    SelectedBorderPosition,
    Direction,
} from '../../typing/enums';

import { THUMBNAILS_CONFIG_DEFAULTS } from '../../config/defaults';
import { withContext } from "../../common/context";

const $Wrap = styled('div')<{
    borderWidth: number;
    radius: number;
    borderColor: Color;
    width: number;
    height: number;
    last: boolean;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: ${props => props.borderWidth + 'px solid ' + props.borderColor};
  border-radius: ${props => props.radius}px;
  align-self: start;
  overflow: hidden;
`;

const $SelectedWrap = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Selected = (props: SelectedItemProps) => {
    const pos = props.borderPosition;
    const borderValue = `${props.borderWidth}px solid ${getRgbaColor(
        props.borderColor,
        props.borderOpacity
    )}`;

    const borderCls =
        props.style !== SelectedStyles.GRADIENT
            ? css`
                ${
                pos === SelectedBorderPosition.ALL
                    ? 'border: ' + borderValue + ';'
                    : ''
                }
                ${
                pos === SelectedBorderPosition.BOTTOM
                    ? 'border-bottom: ' + borderValue + ';'
                    : ''
                }
                ${
                pos === SelectedBorderPosition.TOP
                    ? 'border-top: ' + borderValue + ';'
                    : ''
                }
                ${
                pos === SelectedBorderPosition.LEFT
                    ? 'border-left: ' + borderValue + ';'
                    : ''
                }
                ${
                pos === SelectedBorderPosition.RIGHT
                    ? 'border-right: ' + borderValue + ';'
                    : pos === SelectedBorderPosition.LEFT_RIGHT
                    ? 'border-right: ' +
                    borderValue +
                    ';border-left: ' +
                    borderValue +
                    ';'
                    : pos === SelectedBorderPosition.TOP_BOTTOM
                        ? 'border-top: ' +
                        borderValue +
                        ';border-bottom: ' +
                        borderValue +
                        ';'
                        : ''
                }`
            : '';

    const gradientCls =
        props.style !== SelectedStyles.BORDER
            ? css`
          background-image: linear-gradient(
            ${props.gradientDirection === Direction.VERTICAL ? 0 : 90}deg,
            ${getRgbaColor(props.gradientStart, props.gradientOpacity)},
            ${getRgbaColor(props.gradientEnd, props.gradientOpacity)}
          );
        `
            : '';

    return (
        <$SelectedWrap
            data-test="selected-wrap"
            className={cx(borderCls, gradientCls)}
            {...props}
        />
    );
};

const getPosition = (position: string, noShape: boolean) => {
    const pos = position.split("-");
    let res = 'top: 0; left: 0';

    if (noShape && position !== MediaSymbolPosition.CENTER) {
        res = `${pos[0]}: 5px; ${pos[1]}: 5px`;
    }

    return res;
};

const $MediaSymbolWrap = styled('div')`
  display: flex;
  align-items: center;justify-content: center;
  position: absolute;
  ${(props: any) =>
    (!props.noShape || props.position === MediaSymbolPosition.CENTER) 
        ? 'width: 100%; height: 100%;'
        : ''};
  ${(props: any) => getPosition(props.position, props.noShape)};
`;

const isImage = (mediaType: MediaSymbolTypes, resourceType: AssetType) =>
    (mediaType === MediaSymbolTypes.VIDEO && resourceType === AssetType.IMAGE) ||
    mediaType === MediaSymbolTypes.IMAGE;

const getTransformation = (
    resourceTransformation: Transformation = {},
    config: ConfigSelectors
): Transformation =>
    !isEmpty(resourceTransformation)
        ? resourceTransformation
        : isEmpty(config.selectThumbnailPropsTransformation())
        ? config.selectTransformation()
        : config.selectThumbnailPropsTransformation();

export class Thumbnails extends Component<ThumbnailsProps> {

    onItemClick = (index: number) => {
        const {config, events} = this.props.context,
            mediaAssets = config.selectMediaAssets();

        if (this.props.onItemSelect) {
            this.props.onItemSelect(index);
        }

        events(Events.THUMBNAIL_CLICK, mediaAssets[index].publicId);
    };


    render(propsIn: ThumbnailsProps) {
        const {config, events} = this.props.context;
        const thumbnailProps = config.selectThumbnailProps();

        const props = {
                ...THUMBNAILS_CONFIG_DEFAULTS,
                ...thumbnailProps,
                ...propsIn,
            },
            position = config.selectThumbnailPropsMediaSymbolPosition(),
            mediaAssets = config.selectMediaAssets(),
            mediaSymbolProps: MediaSymbolProps = {
                size: Math.round(config.selectThumbnailPropsWidth() / 2.5),
                ...config.selectMediaSymbolProps(),
            },
            selectedProps: SelectedItemProps = config.selectThumbnailSelectedProps(),
            carouselProps: CarouselProps = {
                ...config.selectThumbnailsCarouselProps(),
                index: props.index,
                perView: 0,
                onNext: () => {
                    events(
                        Events.THUMBNAIL_NEXT,
                        mediaAssets[props.selectedIndex].publicId
                    );
                },
                onPrev: () => {
                    events(
                        Events.THUMBNAIL_PREV,
                        mediaAssets[props.selectedIndex].publicId
                    );
                },
                navigationGutter: props.gutter,
            };

        return (
            <Carousel
                {...carouselProps}
                onItemClick={this.onItemClick}>
                {mediaAssets.map((resource: BaseAsset, resourceIndex: number) => {
                    return (
                        <$Wrap
                            borderColor={config.selectThumbnailPropsBorderColor()}
                            borderWidth={config.selectThumbnailPropsBorderWidth()}
                            radius={config.selectThumbnailPropsRadius()}
                            width={config.selectThumbnailPropsWidth()}
                            height={config.selectThumbnailPropsHeight()}
                            last={resourceIndex === mediaAssets.length - 1}
                            data-test="thumbnails-wrap"
                        >
                            <Asset
                                {...resource}
                                resourceType={AssetType.IMAGE}
                                mediaType={resource.mediaType}
                                width={config.selectThumbnailWidth()}
                                height={config.selectThumbnailHeight()}
                                zoom={false}
                                inView={true}
                                transformation={getTransformation(
                                    !isEmpty(resource.thumbnailTransformation)
                                        ? resource.thumbnailTransformation
                                        : !isEmpty(
                                        config.selectThumbnailPropsTransformation()
                                        )
                                        ? config.selectThumbnailPropsTransformation()
                                        : resource.transformation,
                                    config
                                )}
                            />

                            {!isImage(resource.mediaType, resource.resourceType) ? (
                                <$MediaSymbolWrap
                                    {...mediaSymbolProps}
                                    noShape={
                                        mediaSymbolProps.shape === MediaSymbolShape.NONE
                                    }
                                    position={position}
                                    data-test="media-icon-wrap"
                                >
                                    <MediaSymbol
                                        {...mediaSymbolProps}
                                        type={resource.mediaType}
                                    />
                                </$MediaSymbolWrap>
                            ) : null}

                            {resourceIndex === props.selectedIndex ? (
                                <Selected {...selectedProps} />
                            ) : null}
                        </$Wrap>
                    );
                })}
            </Carousel>
        );
    }
}

export default withContext(Thumbnails);
