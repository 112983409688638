import {FunctionComponent, h} from 'preact';
import styled from 'react-emotion';
import BaseButton from './BaseButton';
import {ButtonProps} from '../../typing';

const $RadiusButton = styled(BaseButton)`
  border-radius: 5px;
`;

const RadiusButton: FunctionComponent<ButtonProps> = (props)  => (
    <$RadiusButton {...props} />
);

export default RadiusButton;
