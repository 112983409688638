import {FunctionalComponent, h} from 'preact';
import {css, cx} from 'react-emotion';

const stretchedCls = css({
  width: '100%',
  height: '100%',
});

const flexCenterCls = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const positionRelative = css({position: 'relative'});

export const withDiv = (Div: any) => (props: any) => {
  const cls = css({
    ...(props.relative ? {position: 'relative'} : {}),
    ...(props.absolute ? {position: 'absolute', top: 0, left: 0} : {}),
  });
  return <Div {...props} className={cx(cls, props.className)} />;
};

type StretchedDivProps = any;

export const StretchedDiv: FunctionalComponent<StretchedDivProps> = (props) => {
  return (
    <div {...props} className={cx(stretchedCls, props.className, positionRelative)}>
      {props.children}
    </div>
  );
};

type FlexCenterStretchedProps = any;

const FlexCenterStretchedComponent: FunctionalComponent<FlexCenterStretchedProps> = (propsIn) => {
    const props = {
        ...propsIn,
        ...(propsIn.innerRef
            ? {ref: (elem: HTMLElement) => props.innerRef(elem)}
            : {}),
    };

    return (
        <div
            {...props}
            className={cx(
                stretchedCls,
                flexCenterCls,
                props.className,
                css`
            display: ${props.hide ? 'none' : 'flex'};
          `
            )}
        >
            {props.children}
        </div>
    );
}

export const FlexCenterStretched = withDiv(
    FlexCenterStretchedComponent
);
