import keys from '../utils/keys';
import {ViewportBreakpointConfigProps} from '../typing';
import {mapKeysToProps} from '../common/props';
import debounce from './debounce';

type MediaQueryListItem = {mq?: string; config?: Object};

const prepareMediaQueryList = (
  list: ViewportBreakpointConfigProps[]
): MediaQueryListItem[] => {
  let data = list.map(
    (config: ViewportBreakpointConfigProps, index: number) => {
      if (index === 0) {
        return {
          mq: `(max-width: ${Number(config.breakpoint)}px)`,
          config: mapKeysToProps(keys('BreakpointProps'), config) || {},
        };
      } else if (index > 0 && index < list.length) {
        return {
          mq: `(min-width: ${Number(list[index - 1].breakpoint) +
            1}px) and (max-width: ${config.breakpoint}px)`,
          config: mapKeysToProps(keys('BreakpointProps'), config) || {},
        };
      }

      return {};
    }
  );

  if (list.length > 0) {
    data = [
      ...data,
      {
        mq: `(min-width: ${Number(list[list.length - 1].breakpoint) + 1}px)`,
        config: {},
      },
    ];
  }

  return data;
};

class mediaQuery {
  constructor(config: ViewportBreakpointConfigProps[], matchHandler: Function) {
    this.config = config.sort(
      (a: ViewportBreakpointConfigProps, b: ViewportBreakpointConfigProps) =>
        Number(a.breakpoint) - Number(b.breakpoint)
    );
    this.list = prepareMediaQueryList(this.config);
    this.matchHandler = matchHandler;

    this.list.forEach((breakpoint: MediaQueryListItem, index: number) => {
      this.mediaQueries.push(window.matchMedia(breakpoint.mq || ''));

      if (this.mediaQueries[index].matches) {
        this.matchHandler(breakpoint.config);
      }

      const handler = (changed: any) => {
        if (changed.matches) {
          this.activeMatchHandler = () => {
            this.matchHandler(breakpoint.config);
          };
        }
      };

      this.mediaQueryListener.push(handler);
      this.mediaQueries[index].addListener(this.mediaQueryListener[index]);
    });

    // because resize and matchMedia works the same, only change widget when resize is over
    window.addEventListener('resize', this.onResizeListener);
  }

  config: ViewportBreakpointConfigProps[];
  matchHandler: Function;
  activeMatchHandler: Function | null;
  viewportBreakpoints: number[];
  mediaQueries: MediaQueryList[] = [];
  mediaQueryListener: any[] = [];
  list: MediaQueryListItem[];

  private onResizeListener: EventListener = debounce(() => {
    if (this.activeMatchHandler) {
      this.activeMatchHandler();
      this.activeMatchHandler = null;
    }
  }, 250);

  public destroy(): void {
    this.mediaQueries.forEach((mq: MediaQueryList, index: number) => {
      mq.removeListener(this.mediaQueryListener[index]);
    });

    window.removeEventListener('resize', this.onResizeListener);
  }
}

export default mediaQuery;
