import {FunctionalComponent, h} from 'preact';
import styled from 'react-emotion';
import BaseButton from './BaseButton';
import {ButtonProps} from '../../typing';

const $TransparentButton = styled(BaseButton)`
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const TransparentButton: FunctionalComponent<ButtonProps> = (props) => (
    <$TransparentButton {...props} />
);

export default TransparentButton;
