import {Component, h} from 'preact';
import styled from 'react-emotion';
import {AppContext, LoadedAsset} from '../../typing';
import {MediaSymbolTypes} from '../../typing/enums';
import {withContext} from '../../common/context';
import {Context} from '../App/App';
import Loading from '../Loading/Loading';
import {FlexCenterStretched} from '../../utils/emotion';

interface PlaceholderProps {
  publicId: string;
  mediaType: MediaSymbolTypes;
  width: number;
  height: number;
  context: AppContext;
  transformation?: Object;
}

interface PlaceholderState {
  url: string;
}

const Img = styled('img')< {width: number; height: number}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  filter: blur(20px);
`;

class Placeholder extends Component<PlaceholderProps, PlaceholderState> {
  getCacher = () => this.props.context.cacher;
  loadPlaceholder = (width: number, height: number): void => {
    const loadedWidth = Math.round((width / height) * 32);
    const loadedHeight = Math.round((height / width) * 32);

    this.getCacher()(
      this.props.publicId,
      this.getPlaceholderUrl(loadedWidth, loadedHeight),
      loadedWidth,
      loadedHeight
    ).then(
      (asset: LoadedAsset) => {
        this.setState(() => ({
          url: asset.url,
        }));
      },
      () => {}
    );
  };

  getPlaceholderUrl = (width: number, height: number): string => {
    const {publicId, transformation, context} = this.props;

    return this.props.mediaType === MediaSymbolTypes.VIDEO
      ? context.cloudinary.getVideoThumbnailUrl(
          publicId,
          width,
          height,
          transformation
        )
      : `${context.cloudinary.getImageUrl(publicId, width, height, {
          ...transformation,
          quality: 10,
        })}`;
  };

  componentDidMount() {
    if (this.props.context.config.selectPlaceholderImage()) {
      this.loadPlaceholder(this.props.width, this.props.height);
    }
  }

  componentWillReceiveProps(nextProps: PlaceholderProps) {
    const {width, height} = this.props;
    const nextWidth = nextProps.width || width;
    const nextHeight = nextProps.height || height;

    if (nextWidth / nextHeight !== width / height) {
      this.loadPlaceholder(nextWidth, nextHeight);
    }
  }

  render(props: PlaceholderProps, state: PlaceholderState) {
    return (
      <Context.Consumer>
        {({config}) => {
          return (
            <FlexCenterStretched relative>
              {config.selectPlaceholderImage() ? (
                <Img
                  width={props.width}
                  height={props.height}
                  src={state.url}
                />
              ) : null}
              <FlexCenterStretched absolute>
                <Loading
                  width={props.width}
                  height={props.height}
                  color={
                    config.selectLoaderPropsColor() ||
                    config.selectThemePropsPrimary()
                  }
                  size={config.selectLoaderPropsSize()}
                  opacity={config.selectLoaderPropsOpacity()}
                  style={config.selectLoaderPropsStyle()}
                  url={config.selectLoaderPropsUrl()}
                />
              </FlexCenterStretched>
            </FlexCenterStretched>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default withContext(Placeholder);
