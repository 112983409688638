import {h, Component } from 'preact';
import { createPortal } from 'preact/compat';
import styled, {css} from 'react-emotion';
import {withContext} from 'common/context';
import Button from '../Button/Button';
import { Color, AppContext} from 'typing';
import {getDimensions} from '../../utils/aspectRatio';
import {ZoomPopupShape} from '../../typing/enums';
import {getButtonProps} from '../../common/props';
import {getRgbaColor} from '../../utils/color';

interface ZoomPopupProps {
  publicId: string;
  render: Function;
  onClose: Function;
  buttonShape: ZoomPopupShape;
  buttonColor: Color;
  buttonIconColor: Color;
  buttonSize: number;
  backdropColor: Color;
  backdropOpacity: number;
  zIndex: number;
  context: AppContext;
}

interface ZoomPopupState {
  isOpen: boolean;
}

const $Overlay = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${(props: any) =>
    getRgbaColor(props.backdropColor, props.backdropOpacity)};
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: ${(props: any) => (props.isOpen ? 1 : 0)};
  transition: opacity 250ms;
  z-index: ${(props: any) => props.zIndex};
`;

const $Wrap = styled('div')`
  display: block;
  position: absolute;
  height: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const $InnerWrap = styled('div')`
  width: ${(props: any) => props.wrapWidth}px;
  height: ${(props: any) => props.wrapHeight}px;

  img {
    max-width: 100%;
    width: ${(props: any) => props.wrapWidth}px;
    height: ${(props: any) => props.wrapHeight}px;
  }
`;

class ZoomPopup extends Component<ZoomPopupProps, ZoomPopupState> {
  pageBodyOverflow: any;
  overlayElem: HTMLElement;

  componentWillUnmount() {
    document.body.style.overflow = this.pageBodyOverflow;
  }

  componentDidMount() {
    this.pageBodyOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    if (!this.state.isOpen) {
      this.setState(() => ({
        isOpen: true,
      }));
    }
  }

  close = (e: any) => {
    this.setState(() => ({
      isOpen: false,
    }));

    if (this.props.onClose) {
      this.props.onClose(e);
    }
  };

  getAssetDimensions = (): {width: number; height: number} => {
    const aspectRatio = this.props.context.config.selectAspectRatio();
    const windowW = window.innerWidth;
    const windowH = window.innerHeight;
    // const isHoriz =
    //   Number(aspectRatio.split(':')[0]) > Number(aspectRatio.split(':')[2]);
    let dims = getDimensions(windowW, aspectRatio);

    if (dims.height > windowH) {
      dims = getDimensions((windowH / dims.height) * dims.width, aspectRatio);
    }

    // if (dims.height > windowH) {
    //   dims = getDimensions(
    //     getDimensions(windowH, aspectRatio).width,
    //     aspectRatio
    //   );
    // }

    return dims;
  };

  render(props: ZoomPopupProps, state: ZoomPopupState) {
    const dims = this.getAssetDimensions();
      return (
        createPortal(
            <$Overlay
                isOpen={state.isOpen}
                {...props}
                data-test="popup"
                ref={(elem: HTMLElement) => {
                  this.overlayElem = elem;
                }}
            >
              <Button
                  {...getButtonProps(props)}
                  icon="close"
                  className={css({
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    zIndex: 1,
                  })}
                  onClick={this.close}
              />
              <$Wrap>
                <$InnerWrap wrapWidth={dims.width} wrapHeight={dims.height}>
                  {props.render(dims.width, dims.height)}
                </$InnerWrap>
              </$Wrap>
            </$Overlay>
        , document.body)
      );
    }
}

export default withContext(ZoomPopup);
