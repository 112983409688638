import { h, Component } from 'preact';
import { AppContext, LoadedAsset, Transformation } from '../../typing';
import { MediaSymbolTypes, ZoomType } from '../../typing/enums';
import Zoom from '../Zoom/Zoom';
import Placeholder from '../Placeholder/Placeholder';
import Error from '../Error/Error';
import { withContext } from '../../common/context';
import { StretchedDiv, FlexCenterStretched } from '../../utils/emotion';
import helpers from './ImageHelpers';
import { isMobile } from 'mobile-device-detect';

export interface ImageProps {
  publicId: string;
  mediaType: MediaSymbolTypes;
  resourceType: 'image';
  transformation?: Transformation;
  width: number;
  height: number;
  breakpoint?: number;
  zoom: boolean;
  active: boolean;
  context: AppContext;
}

export interface ImageState {
  url: string | undefined;
  zoomUrl: string | undefined;
  error: boolean;
  isZoom: boolean;
}

class ImageAsset extends Component<ImageProps, ImageState> {
  currTransformation: Transformation;
  state = {
    error: false,
    url: undefined,
    zoomUrl: undefined,
    isZoom: false
  };

  componentDidMount(): void {
    const { width, height, breakpoint, zoom, context: { cacher }  } = this.props,
        adjustedDims = helpers.getAdjustedDims(width, height, breakpoint);

    if (this.props.active) {
      this.initImage(
          cacher,
          adjustedDims.width,
          adjustedDims.height,
          true,
          zoom
      );
    }
  }

  componentDidUpdate(prevProps: ImageProps) {
    const { width, height, breakpoint, context } = this.props;

    const currDims: { width: number; height: number } = helpers.getAdjustedDims(
      prevProps.width,
      prevProps.height,
      breakpoint
    );

    const nextDims: { width: number; height: number } = helpers.getAdjustedDims(
      width || 0,
      height || 0,
      breakpoint
    );

    const isSameAspectRatio: boolean = helpers.checkSameRatio(
      currDims.width,
      currDims.height,
      nextDims.width,
      nextDims.height
    );

    const isSameTrasnformation: boolean =
      this.props.transformation === prevProps.transformation;

    const isActive: boolean = this.props.active && prevProps.active === false;

    if ((nextDims.width > currDims.width && isSameAspectRatio) || isActive) {
      this.initImage(
        context.cacher,
        nextDims.width,
        nextDims.height,
        true,
        this.props.zoom
      );
    } else if (!isSameAspectRatio || !isSameTrasnformation) {

      this.setState(() => ({
        url: undefined
      }));

      this.initImage(
        context.cacher,
        nextDims.width,
        nextDims.height,
        true,
        this.props.zoom,
        this.getTransformation(prevProps.transformation)
      );
    } else if (this.props.zoom && !prevProps.zoom) {
      this.initImage(
        context.cacher,
        nextDims.width,
        nextDims.height,
        false,
        true
      );
    }
  }

  getTransformation = (transformation?: Transformation): Transformation => {
    if (
      (transformation && transformation !== this.props.transformation) ||
      !this.currTransformation
    ) {
      const nextTransformation = {
        ...(this.props.transformation || transformation),
      };

      this.currTransformation = nextTransformation;
    }

    return this.currTransformation;
  };

  initImage(
    cacher: Function,
    width: number,
    height: number,
    loadHighRes: boolean,
    loadZoomRes: boolean,
    transformation?: Transformation
  ): void {
    transformation = transformation || this.getTransformation();

    this.setState(() => ({
      error: false
    }));

    if (loadHighRes) {
      helpers
        .loadHighResImage(this.props, cacher, width, height, transformation)
        .then(
          (asset: LoadedAsset) => {

            this.setState(()=> ({
              url: asset.url
            }));
          },
          () => {
            this.setState(()=> ({
              error: true
            }));
          }
        );
    }

    if (loadZoomRes) {
      helpers
        .loadZoomImage(this.props, cacher, width, height, transformation)
        .then(
          (asset: LoadedAsset) => {
            this.setState(() => ({
              zoomUrl: asset.url
            }));
          },
          () => {
            // if zoom failed load instead the regular url instead of showing an error
            this.setState(() => ({
              zoomUrl: this.state.url
            }));
          }
        );
    }
  }

  render(props: ImageProps) {
    const { config } = this.props.context;

    return (
      <FlexCenterStretched
        data-test="image-wrap"
        data-ready={!!this.state.url}
      >
        {!this.state.error &&
          !this.state.url &&
          (props.width > 150 || props.height > 150) ? (
            <Placeholder
              publicId={props.publicId}
              width={props.width}
              height={props.height}
              transformation={this.getTransformation()}
              mediaType={props.mediaType}
            />
          ) : null}
        {this.state.url && !this.state.error ? (
          <StretchedDiv data-test="img-zoom-wrap">
            {
              //@ts-ignore
              <img itemprop="image" src={this.state.url}/>
            }
            {props.zoom && (
              <Zoom
                key={`zoom-${this.props.publicId}`}
                width={this.props.width}
                height={this.props.height}
                url={this.state.zoomUrl || this.state.url}
                publicId={this.props.publicId}
                trigger={this.props.context.config.selectZoomPropsTrigger()}
                type={
                  isMobile && config.selectZoomPropsType() !== ZoomType.POPUP
                    ? ZoomType.INLINE
                    : config.selectZoomPropsType()
                }
                viewerPosition={config.selectZoomPropsViewerPosition()}
                viewerContainer={
                  isMobile ? undefined : config.selectZoomPropsContainer()
                }
                level={config.selectZoomPropsLevel()}
                onZoomIn={config.selectZoomPropsType() === ZoomType.INLINE ? () => {
                  this.setState(()=>({
                    isZoom: true
                  }));
                } : null}
                onZoomOut={config.selectZoomPropsType() === ZoomType.INLINE ? () => {
                  this.setState(()=>({
                    isZoom: false
                  }));
                } : null}
              />
            )}
          </StretchedDiv>
        ) : null}
        {this.state.error ? (
          <Error width={props.width} type={MediaSymbolTypes.IMAGE} />
        ) : null}
      </FlexCenterStretched>
    );
  }
}

export default withContext(ImageAsset);
