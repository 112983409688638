import {FunctionComponent, h} from 'preact';
import styled from 'react-emotion';
import SvgIcon from '../SvgIcon/SvgIcon';
import * as ICONS from '../../assets/icons';
import {ButtonProps} from '../../typing';

const $BaseButton = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  border: ${(props: ButtonProps) =>
    props.borderWidth
      ? props.borderWidth + 'px solid ' + props.borderColor
      : 'none'};
  outline: 0;
  cursor: pointer;
  background-color: ${(props: ButtonProps) => props.color};
  width: ${(props: ButtonProps) => props.size}px;
  height: ${(props: ButtonProps) => props.size}px;
  min-width: ${(props: ButtonProps) => props.size}px;
  min-height: ${(props: ButtonProps) => props.size}px;
  padding-top: ${(props: ButtonProps) => Number(props.size) / 4}px;
  padding-bottom: ${(props: ButtonProps) => Number(props.size) / 4}px;
  opacity: ${(props: ButtonProps) => (props.disabled ? 0.2 : 1)};
  transition: opacity 0.25s;
`;

const BaseButton: FunctionComponent<ButtonProps> = (props) => {
  const icon: string | undefined = ICONS[props.icon || ''];
  const iconSize =
    props.size - (props.size / 4) * 2 - (props.borderWidth || 0) * 2;
  return (
    <$BaseButton
      {...props}
      onClick={props.onClick}
      className={props.className}
      size={props.size}
    >
      {icon ? (
        <SvgIcon color={props.iconColor} path={icon} size={iconSize} />
      ) : null}
    </$BaseButton>
  );
};

export default BaseButton;
