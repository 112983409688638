declare var VERSION: string;
declare var ENV: string;
declare var THREE_DRACO_URL: string;
declare var THREE_URL: string;
declare var ANALYTICS_URL: string;
declare var USE_ANALYTICS: string;

const _VERSION = VERSION;
const _ENV = ENV;
const _THREE_DRACO_URL = THREE_DRACO_URL;
const _THREE_URL = THREE_URL;
const _ANALYTICS_URL = ANALYTICS_URL;
const _USE_ANALYTICS = USE_ANALYTICS;

export { _VERSION as VERSION };
export { _ENV as ENV };
export { _THREE_DRACO_URL as THREE_DRACO_URL };
export { _THREE_URL as THREE_URL };
export { _ANALYTICS_URL as ANALYTICS_URL };
export { _USE_ANALYTICS as USE_ANALYTICS };
