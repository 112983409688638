const shallowDiffers = (a: Object, b: Object) => {
  for (let i in a) if (!(i in b)) return true;
  for (let i in b) if (a[i] !== b[i]) return true;
  return false;
};

export default (instance: any, nextProps: Object, nextState: Object) => {
  return (
    shallowDiffers(instance.props, nextProps) ||
    shallowDiffers(instance.state, nextState)
  );
};
