import { h, Component } from 'preact';
import CarouselItem from './CarouselItem';
import CarouselNavButton from './CarouselNavButton';
import {
  Wrapper,
  CarouselWrapper,
  CarouselInnerWrapper,
} from './Carousel.emotion';
import { CarouselProps, CarouselState } from '../../typing';
import { Axis, Transition } from '../../typing/enums';
import { CAROUSEL_DEFAULTS } from '../../config/defaults';
import { getButtonProps } from '../../common/props';
import { isMobile } from 'mobile-device-detect';
import { mouseTracker, clickTrigger } from '../../utils/mouse';

class Carousel extends Component<CarouselProps, CarouselState> {
  state = {
    index: 0,
    showNext: false,
    showPrev: false,
    dragging: false,
    position: 0,
  };

  wrapper: HTMLElement | null = null;
  innerWrapper: HTMLElement | null = null;
  mouseTracker: any;
  mouseClickTracker: any;

  componentDidMount(): void {
    this.reCalcState(this.getInViewIndex(this.props.index));
  }

  reCalcState(index: number): void {
    const showNext = this.isSlidable(index + 1);
    const showPrev = this.isSlidable(index) && index > 0;

    this.setState(() => ({
      index: !this.props.perView && !showNext && !showPrev ? 0 : index,
      showNext: this.isSlidable(index + 1),
      showPrev: this.isSlidable(index) && index > 0,
    }));
  }

  componentWillReceiveProps(nextProps: CarouselProps) {
    const isSameAxis = nextProps.axis
      ? nextProps.axis === this.props.axis
      : true;

    const isSameIndex =
      'index' in nextProps ? nextProps.index === this.props.index : true;

    if (!isSameAxis || isSameIndex) {
      this.removeTransition();
    } else {
      this.addTransition();
    }

    // on mobile - while in zoom since using stoppropagation - mouseup is not called thus not reseting position - hack
    if (nextProps.index !== undefined && nextProps.index !== this.props.index) {
      this.setState(() => ({
        position: 0,
      }));
    }
  }

  componentDidUpdate(prevProp: CarouselProps, prevState: CarouselState): void {
    const isPropChanged = Object.keys(prevProp).some(
      k => prevProp[k] !== this.props[k]
    );
    const isStateChanged = Object.keys(prevState).some(
      k => prevState[k] !== this.state[k]
    );

    if (isPropChanged) {
      setTimeout(() => {
        // hack - clearTimeout doesnt work for some reason when using unmount, instead checking if wrapper exists
        if (this.wrapper) {
          this.reCalcState(this.getInViewIndex(this.props.index));
        }
      }, 0);
    } else if (isStateChanged) {
      this.reCalcState(this.getIndex(this.state.index));
    }
  }

  getWrapper(): HTMLElement {
    if (!this.wrapper) throw new Error('Wrapper is undefined');

    return this.wrapper;
  }

  getInnerWrapper(): HTMLElement {
    if (!this.innerWrapper) throw new Error('Inner Wrapper is undefined');

    return this.innerWrapper;
  }

  getWrapperSize(): number {
    return this.props.axis === 'horizontal'
      ? this.getWrapper().clientWidth
      : this.getWrapper().clientHeight;
  }

  getInnerWrapperSize(): number {
    return this.props.axis === 'horizontal'
      ? this.getInnerWrapper().clientWidth
      : this.getInnerWrapper().clientHeight;
  }

  getOverflow(index: number): number {
    const itemSize = this.getItemSize();
    // on first mount wrapper and inner wrapper are undefined
    if (!this.wrapper && !this.innerWrapper) {
      return 0;
    }

    const wrapperSize = this.getWrapperSize();
    const innerWrapperSize = this.getInnerWrapperSize();

    const overflow = wrapperSize - (innerWrapperSize - itemSize * index);

    return overflow;
  }

  getPosition(): number {
    const { index } = this.state;
    const itemSize = this.getItemSize();
    const overflow = this.getOverflow(index);

    const position =
      overflow > 0 && overflow < itemSize
        ? itemSize * index * -1 + overflow
        : itemSize * index * -1;

    return Math.min(position, 0);
  }

  isSlidable(nextIndex: number): boolean {
    return nextIndex <= this.getMaxIndex();
  }

  getPerView(): number {
    const props = this.getProps();
    const wrapperSize = this.getWrapperSize() + props.spacing;
    const itemSize = this.getItemSize();

    return Math.floor(wrapperSize / itemSize);
  }

  getMaxIndex(): number {
    return this.props.children.length - this.getPerView();
  }

  // TODO: check if to add range as a public variable
  getCurrentRange(): { startIndex: number; endIndex: number } {
    return {
      startIndex: this.state.index,
      endIndex: this.state.index + this.getPerView() - 1,
    };
  }

  isInView(index: number = 0): boolean {
    const { startIndex = 0, endIndex = 0 } = this.getCurrentRange();

    return index < endIndex && index >= startIndex;
  }

  getIndex(index: number = 0): number {
    if (index >= this.props.children.length || index < 0) {
      return this.state.index;
    }

    const { startIndex = 0 } = this.getCurrentRange();
    const goLeft = index <= startIndex;
    const inViewIndex = this.getInViewIndex(index);
    return goLeft ? index : index <= inViewIndex ? inViewIndex : index;
  }

  getInViewIndex(index: number = 0): number {
    if (
      this.isInView(index) ||
      index >= this.props.children.length ||
      index < 0
    ) {
      return this.state.index;
    }

    const { startIndex = 0, endIndex = 0 } = this.getCurrentRange();

    return index <= startIndex ? index : index - endIndex + startIndex;
  }

  goToItem = (index: number): void => {
    const newIndex = this.getIndex(index);
    this.addTransition();

    this.setState(() => ({
      index: newIndex,
      showNext: this.isSlidable(newIndex + 1),
      showPrev: newIndex > 0,
    }));
  };

  nextItem = (): void => {
    const { index } = this.state;

    if (index < this.props.children.length) {
      this.goToItem(index + 1);

      if (this.props.onNext) {
        this.props.onNext();
      }
    }
  };

  prevItem = (): void => {
    const { index } = this.state;

    if (index > 0) {
      this.goToItem(index - 1);

      if (this.props.onPrev) {
        this.props.onPrev();
      }
    }
  };

  getItemSize(): number {
    return this.props.axis === Axis.HORIZONTAL
      ? this.getItemWidth()
      : this.getItemHeight();
  }

  getProps(props?: CarouselProps) {
    return { ...CAROUSEL_DEFAULTS, ...(props || this.props) };
  }

  getItemWidth(): number {
    const props = this.getProps(); // for typescript since all props are undefined
    return (
      props.itemWidth + (props.axis === Axis.HORIZONTAL ? props.spacing : 0)
    );
  }

  getItemHeight(): number {
    const props = this.getProps(); // for typescript since all props are undefined
    return (
      props.itemHeight + (props.axis === Axis.VERTICAL ? props.spacing : 0)
    );
  }

  getNavProps = () => {
    const props = this.getProps();

    return {
      axis: props.axis,
      ...getButtonProps(props, 'navigation'),
      float: props.navigationFloat,
    };
  };

  swipeStart = (event: any): void => {
    if (this.props.allowSwipe && !this.props.allowSwipe()) {
      return;
    }

    this.mouseTracker = mouseTracker(event, this.props.axis);
    this.mouseClickTracker = clickTrigger(event);

    this.removeTransition();

    this.setState(() => ({
      dragging: true,
      position: this.getPosition(),
    }));
  };

  swipeMove = (event: MouseEvent) => {

    if (this.mouseTracker) {
      const tracker = this.mouseTracker(event);
      const itemSize = this.getItemSize();
      const position = this.state.position + tracker.moveBy;
      const nextIndex = Math.floor(position / itemSize) * -1;
      const maxIndex = this.getMaxIndex();

      //https://cloudinary.atlassian.net/browse/CLD-12192 - enable scrolling with finger on android device
      if (Math.abs(tracker.moveByX) > 0 && Math.abs(tracker.moveByY) < 2) {
        event.preventDefault();
      }


      if (nextIndex <= maxIndex && position < 0) {
        this.setState(() => ({
          position: position,
        }));
      }
    }
  };

  swipeEnd = (event: any) => {
    if (this.mouseClickTracker && this.mouseClickTracker(event)) {
      this.setState(() => ({
        dragging: false,
        position: 0,
      }));
    } else if (this.mouseTracker) {

      const index = this.calcNextIndex(
        this.state.position,
        this.getItemSize(),
        this.getMaxIndex(),
        this.mouseTracker(event).direction
      );

      this.addTransition();

      setTimeout(() => {
        this.setState(() => ({
          index,
          dragging: false,
          position: 0,
        }));

        if (this.props.onItemSwipe) {
          this.props.onItemSwipe(index);
        }
      }, 100);

      this.mouseTracker = undefined;
    }
  };

  addTransition = () => {
    if (this.innerWrapper) {
      this.innerWrapper.style.transition = 'transform 250ms';
    }
  };

  removeTransition = () => {
    if (this.innerWrapper) {
      this.innerWrapper.style.transition = '';
    }
  };

  // onMouseWheel = (event: any) => {
  //   event.preventDefault();
  //   const itemSize = this.getItemSize();
  //   const position = this.state.position + event.deltaY;
  //   const nextIndex = Math.floor(position / itemSize) * -1;
  //   const maxIndex = this.getMaxIndex();

  //   this.removeTransition();

  //   if (nextIndex <= maxIndex && position < 0) {
  //     this.setState({
  //       ...this.state,
  //       position: position,
  //     });
  //   }

  //   const trigger = wheelStopTrigger();

  //   trigger.then(() => {
  //     const index = this.calcNextIndex(
  //       this.state.position,
  //       this.getItemSize(),
  //       this.getMaxIndex(),
  //       'next'
  //     );

  //     this.addTransition();

  //     this.setState({
  //       ...this.state,
  //       index,
  //       dragging: false,
  //       position: 0,
  //     });

  //     if (this.props.onItemSwipe) {
  //       this.props.onItemSwipe(index);
  //     }
  //   });
  // };

  calcNextIndex = (
    position: number,
    itemSize: number,
    maxIndex: number,
    direction: string
  ) => {
    //if(this.getPosition())
    const positionDelta = Math.abs(this.getPosition() - this.state.position);

    if (positionDelta < 35) {
      return this.state.index;
    }

    const index = (position / itemSize) * -1;
    const floorIndex = Math.floor(index);
    const nextIndex =
      direction === 'prev'
        ? floorIndex
        : floorIndex === maxIndex
          ? floorIndex
          : floorIndex + 1;

    return nextIndex;
  };

  render(propsIn: CarouselProps, state: CarouselState) {
    const props = this.getProps(propsIn);
    const showNavigation =
      props.navigation && !(!state.showPrev && !state.showNext);
    const navProps = {
      ...this.getNavProps(),
      wrapWidth: props.itemWidth,
      wrapHeight: props.itemHeight,
    };

    let innerWrapProps;

    if (propsIn.transition === Transition.FADE) {
      innerWrapProps = {
        ...props,
      };
    } else {
      innerWrapProps = {
        ...props,
        slideTo: this.state.position || this.getPosition(),
      };
    }

    return (
      <Wrapper
        className={props.className}
        axis={props.axis}
        itemWidth={props.itemWidth}
        itemHeight={props.itemHeight}
        navigation={props.navigation}
        data-test="carousel"
      >
        {showNavigation &&
          (!props.navigationFloat
            ? showNavigation
            : props.navigationFloat && state.showPrev) ? (
            <CarouselNavButton
              {...navProps}
              direction="left"
              disabled={!state.showPrev}
              onClick={this.prevItem}
              gutter={this.props.navigationGutter}
            />
          ) : null}
        <CarouselWrapper
          data-test="carousel-wrapper"
          innerRef={(el: HTMLElement) => (this.wrapper = el)}
          onMouseDown={!isMobile ? this.swipeStart : null}
          onMouseMove={this.state.dragging && !isMobile ? this.swipeMove : null}
          onMouseUp={!isMobile ? this.swipeEnd : null}
          onMouseLeave={!isMobile && this.state.dragging ? this.swipeEnd : null}
          onTouchStart={isMobile ? this.swipeStart : null}
          onTouchMove={this.state.dragging && isMobile ? this.swipeMove : null}
          onTouchEnd={isMobile ? this.swipeEnd : null}
          {...props}
        >
          <CarouselInnerWrapper
            data-test="carousel-inner-wrapper"
            innerRef={(el: HTMLElement) => (this.innerWrapper = el)}
            {...innerWrapProps}
          >
            {props.children.map((node: any, i: number) => {
              const itemProps = {
                spacing: props.spacing,
                width: `${props.itemWidth}px`,
                height: `${props.itemHeight}px`,
                transition: props.transition,
                axis: props.axis,
                onItemClick: props.onItemClick,
                onItemHover: props.onItemHover,
              };

              return (
                <CarouselItem
                  {...itemProps}
                  show={i === props.index}
                  index={i}
                  disableEvents={this.state.dragging}
                >
                  {node}
                </CarouselItem>
              );
            })}
          </CarouselInnerWrapper>
        </CarouselWrapper>
        {showNavigation &&
          (!props.navigationFloat
            ? showNavigation
            : props.navigationFloat && state.showNext) ? (
            <CarouselNavButton
              {...navProps}
              direction="right"
              disabled={!state.showNext}
              onClick={this.nextItem}
              gutter={this.props.navigationGutter}
            />
          ) : null}
      </Wrapper>
    );
  }
}

Carousel.defaultProps = CAROUSEL_DEFAULTS;

export default Carousel;
