import {h} from 'preact';
import styled from 'react-emotion';
import {Context} from '../App/App';
import {Transformation} from '../../typing';
import {AssetType, MediaSymbolTypes} from '../../typing/enums';
import Image from '../Image/Image';
import Video from '../Video/Video';
import Spin from '../Spin/Spin';
import Three from '../Three/Three';
import Error from '../Error/Error';


interface AssetProps {
  width: number;
  height: number;
  spacing?: number;
  columns?: number;
  index?: number;
  mediaType: MediaSymbolTypes;
  resourceType: AssetType;
  assets?: any[];
  transformation?: Transformation;
  useBreakpoint?: boolean;
  inView?: boolean;
  preloadIntersection?: boolean;
  zoom?: boolean;
  setRef?: Function
  [key: string]: any;
}

type AssetWrapperProps = {
    width: number,
    height: number,
    spacing?: number,
    addSideMargin: boolean,
}

const AssetWrapper = styled('div')<AssetWrapperProps>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    
    margin-right: ${({ addSideMargin, spacing }) => 
        addSideMargin && spacing ? spacing : 0}px;
    
    margin-bottom: ${({ spacing }) => spacing || 0}px;
`;

const Asset = (props: AssetProps) => {
    const addSideMargin =  typeof props.index !== 'undefined' && props.columns ?
        !!((props.index +1) % props.columns) : false;

  return (
      <AssetWrapper className="assetWrapper"
           data-index={props.index}
           data-test={props.index}
           width={props.width}
           height={props.height}
           spacing={props.spacing}
           addSideMargin={addSideMargin}
           innerRef={(elm: HTMLDivElement) => elm && props.setRef && props.setRef(elm, props.index)}>
        <Context.Consumer>
          {({ config }) => {
            if (
              props.mediaType === MediaSymbolTypes.IMAGE ||
              (props.mediaType === MediaSymbolTypes.VIDEO &&
                props.resourceType === AssetType.IMAGE) ||
              (props.mediaType === MediaSymbolTypes.SPIN && props.resourceType) ||
              (props.mediaType === MediaSymbolTypes.THREE &&
                props.resourceType === AssetType.IMAGE)
            ) {
              let publicId;

              if (props.mediaType === MediaSymbolTypes.SPIN && props.assets) {
                if (props.assets.length) {
                  publicId = props.assets[0].publicId;
                } else {
                  return <Error type={MediaSymbolTypes.SPIN} width={props.width} />;
                }
              } else {
                publicId = props.publicId;
              }

              return (
                <Image
                  publicId={publicId}
                  mediaType={props.mediaType}
                  resourceType="image"
                  transformation={props.transformation}
                  active={props.inView ? true : config.selectPreloadImage()}
                  width={props.width}
                  height={props.height}
                  breakpoint={
                    props.useBreakpoint ? config.selectImageBreakpoint() : undefined
                  }
                  zoom={props.zoom && props.inView}
                />
              );
            } else if (props.mediaType === MediaSymbolTypes.VIDEO) {
              // need to mount video again with key since video has some react limitation while changing
              const key = `${props.publicId}.${JSON.stringify(
                props.transformation
              )}.${Math.round((props.width / props.height) * 100)}`;

              return (
                <Video
                  publicId={props.publicId}
                  isFirst={props.index === 0}
                  width={props.width}
                  height={props.height}
                  active={props.inView}
                  preload={config.selectPreloadVideo() || props.preloadIntersection}
                  transformation={props.transformation}
                  key={key}
                />
              );
            } else if (props.mediaType === MediaSymbolTypes.SPIN && props.assets) {
              return (
                <Spin
                  width={props.width}
                  height={props.height}
                  assets={props.assets}
                  active={props.inView}
                  preload={config.selectPreloadSpin() || props.preloadIntersection}
                  breakpoint={
                    props.useBreakpoint ? config.selectImageBreakpoint() : undefined
                  }
                  transformation={props.transformation}
                  zoom={props.zoom && props.inView}
                />
              );
            } else if (props.mediaType === MediaSymbolTypes.THREE) {

              return (
                <Three
                  publicId={props.publicId}
                  width={props.width}
                  height={props.height}
                  active={props.inView ? true : config.selectPreloadThree()}
                  transformation={props.transformation}
                />
              );
            } else {
              return <div>Not Supported</div>;
            }
          }}
        </Context.Consumer>
      </AssetWrapper>
  );
};

export default Asset;
