import { ConfigSelectors, Transformation } from "../typing";
import { isEmpty } from "./object";

export const roundToBreakpoint = (value: number, breakpoint: number) => {
  return Math.floor(value / 100.0) * 100 + breakpoint;
};

export const getAdjustedDims = (
  width: number,
  height: number,
  breakpoint: number = 100
) => {
  const nextWidth = roundToBreakpoint(width, breakpoint);
  const nextHeight = Math.floor(nextWidth / (width / height));

  return {
    width: nextWidth,
    height: nextHeight,
  };
};
export const checkSameRatio = (
  w1: number,
  h1: number,
  w2: number,
  h2: number
) => Math.floor((w1 / h1) * 100) === Math.floor((w2 / h2) * 100);

export const getTransformation = (
    resourceTransformation: Transformation = {},
    config: ConfigSelectors
): Transformation =>
    !isEmpty(resourceTransformation)
        ? resourceTransformation
        : config.selectTransformation();