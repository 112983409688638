import {LoadedAsset, Transformation} from '../typing';

type CachedAssets = {
  [key: string]: LoadedAsset[];
};

export const isSmallerThenLoaded = (
  assets: LoadedAsset[],
  width: number
): boolean => assets.filter(asset => width <= asset.width).length > 0;

export const getLargestImageLoaded = (assets: LoadedAsset[]): LoadedAsset =>
  assets.sort((a, b) => b.width - a.width)[0];

export const getAspectRatio = (width: number, height: number) =>
  Math.floor((width / height) * 100);

export const getAssetsWithSameAspectRatio = (
  chachedAssets: CachedAssets,
  publicId: string,
  width: number,
  height: number,
  transformation: string
): LoadedAsset[] => {
  if (!chachedAssets[publicId]) {
    return [];
  }

  // TODO - COMPARE TRANSFORMATIONS
  return chachedAssets[publicId].filter(asset => {
    return (
      asset.transformation === transformation &&
      getAspectRatio(asset.width, asset.height) ===
        getAspectRatio(width, height)
    );
  });
};

export const cacher = (): Function => {
  let chachedAssets: CachedAssets = {};

  return (
    publicId: string,
    url: string,
    width: number,
    height: number,
    transformation: Transformation
  ): Promise<LoadedAsset> =>
    new Promise((resolve: Function, reject: Function) => {
      const trans: string = JSON.stringify(transformation);

      const assets = getAssetsWithSameAspectRatio(
        chachedAssets,
        publicId,
        width,
        height,
        trans
      );

      if (assets.length) {
        if (isSmallerThenLoaded(assets, width)) {
          resolve(getLargestImageLoaded(assets));
          return;
        }
      }

      const img: HTMLImageElement = new Image();

      img.src = url;

      img.onload = () => {
        if (!chachedAssets[publicId]) {
          chachedAssets[publicId] = [];
        }

        const newAsset = {
          domNode: img,
          width: width,
          height: height,
          url: url,
          transformation: trans,
        };

        chachedAssets[publicId].push(newAsset);

        resolve(newAsset);
      };

      img.onerror = () => {
        reject(img);
      };
    });
};
