import produce from 'immer';
import keys from '../utils/keys';
import { Cloudinary as cloudinaryCore } from 'cloudinary-core/cloudinary-core-shrinkwrap';
import { PublicId, CloudinaryCore, Transformation, MediaAsset } from '../typing';
import { Crop } from '../typing/enums';
import { camelToSnake } from './string';
import { keysFromSnakeToCamel } from './object';
import { sortByKey } from './sort';
import { objectToQuerystring } from '../utils/object';
import { AssetType, MediaSymbolTypes } from '../typing/enums';

export const prepareCloudinaryParams = (params: { [key: string]: any }) =>
  keys('CloudinaryConfig').reduce((newObj: object, key: string) => {
    if (params[key]) {
      newObj[camelToSnake(key)] = params[key];
    }

    return newObj;
  }, {});

export const prepareAsset = (
  resource: any,
  mediaType: MediaSymbolTypes,
  resourceType: AssetType
) => {
  return {
    ...keysFromSnakeToCamel(resource),
    mediaType,
    resourceType,
  };
};

const getWidth = (width: number) => Math.ceil(width);
const getHeight = (height: number) => Math.ceil(height);
const getDpr = (cloudinary: any, width: number, height: number) =>
  width < 1500 && height < 1500 ? cloudinary.device_pixel_ratio() : 1;
const is3dAsset = (Asset: MediaAsset) =>
  Asset.format === 'gltz' || Asset.format === 'fbxz';

export const initTransformation = (
  cloudinary: any,
  defaultCrop: Crop = Crop.FILL
): Function => {
  return (
    width: number,
    height: number,
    assetType: AssetType = AssetType.IMAGE,
    transformation: any = {}
  ) => {
    const defaultKeys = [
      'crop',
      'gravity',
      'background',
      'fetch_format',
      'quality',
      'dpr',
    ];

    const baseTranformationValues: {
      crop?: string;
      gravity?: string;
      background?: string;
      fetch_format?: string;
      quality?: string;
      dpr?: string;
    } = Object.keys(transformation).reduce((acc, key) => {
      if (defaultKeys.includes(key)) {
        acc[key] = transformation[key];
      }
      return acc;
    }, {});

    const baseTransformation: any = produce(
      baseTranformationValues,
      (draft: any) => {
        draft.crop =
          draft.crop === Crop.PAD || draft.crop === Crop.FILL
            ? draft.crop
            : defaultCrop;
        if (draft.crop === Crop.PAD) {
          draft.background = draft.background || 'auto';
        } else if (draft.crop === Crop.FILL) {
          draft.gravity = draft.gravity || 'auto';
        }

        // video gets a default limit crop - background only works with pad, otherwise throws an error

        draft.fetch_format = draft.fetch_format || 'auto';
        if (assetType == AssetType.IMAGE || draft.quality) {
          draft.quality = draft.quality || 'auto';
        }
        // modification for video - need to write a util to manage all transformation - ideally in SDK
        if (assetType == AssetType.VIDEO) {
          if (draft.background && draft.background === 'auto') {
            draft.background = undefined;
          }

          if (draft.gravity && draft.gravity === 'auto') {
            draft.gravity = undefined;
          }

          if (draft.fetch_format && draft.fetch_format === 'auto') {
            draft.fetch_format = undefined;
          }
        }

        draft.dpr =
          draft.dpr || getDpr(cloudinary, getWidth(width), getHeight(height));
        draft.width = getWidth(width);
        draft.height = getHeight(height);
      }
    );

    const otherTranformationValues = Object.keys(transformation).reduce(
      (acc, key) => {
        if (!defaultKeys.includes(key)) {
          acc[key] = transformation[key];
        }
        return acc;
      },
      {}
    );

    const reqTransformation = {
      width: baseTransformation.width,
      height: baseTransformation.height,
      crop: baseTransformation.crop,
    };

    return {
      transformation: [
        baseTransformation,
        otherTranformationValues,
        reqTransformation,
      ],
    };
  };
};

export const Cloudinary = (config: { [key: string]: any }) => {
  const cloudinary = cloudinaryCore.new(prepareCloudinaryParams({ ...config }));

  //const defaultCrop = config.transformation.crop;
  const getBaseTransformation: Function = initTransformation(
    cloudinary,
    config.transformation.crop
  );

  return {
    getCore: () => cloudinary,
    getThreeUrl: (publicId: PublicId, transformation: Transformation = {}, analytics: Object = {}) => {
      const url = cloudinary.url(publicId, {
        transformation: {
          ...transformation,
          fetchFormat: 'gltf',
        },
      });

      return `${url}${objectToQuerystring({ pgw: 1, ...analytics })}`;
    },
    getImageUrl: (
      publicId: PublicId,
      width: number,
      height: number,
      transformation: Object = {},
      analytics: Object = {}
    ) => {
      const url = cloudinary.url(
        publicId,
        getBaseTransformation(width, height, AssetType.IMAGE, transformation)
      );

      return `${url}${objectToQuerystring({ pgw: 1, ...analytics })}`;
    },
    getThreeThumbnailUrl: (
      publicId: PublicId,
      width: number,
      height: number,
      transformation: Transformation = {},
      analytics: Object = {}
    ) => {
      if (transformation.flags) delete transformation.flags;
      const url = `${cloudinary.url(
        publicId,
        getBaseTransformation(width, height, AssetType.IMAGE, transformation)
      )}.png`;

      return `${url}${objectToQuerystring({ pgw: 1, tmb: 1, ...analytics })}`;
    },
    getVideoUrl: (
      publicId: PublicId,
      width: number,
      height: number,
      transformation: Object = {},
      analytics: Object = {}
    ) => {
      const videoSourceTypes = cloudinary
        .videoTag(publicId)
        .getOption('source_types');

      const videoSrc = cloudinary.video_url(`${publicId}`, {
        transformation: [
          getBaseTransformation(width, height, AssetType.VIDEO, {
            duration: 30,
            ...transformation,
          }),
        ],
      });

      return videoSourceTypes.map(
        (source: string) =>
          `${videoSrc}.${source}${objectToQuerystring({ pgw: 1, ...analytics })}`
      );
    },

    getVideoThumbnailUrl: (
      publicId: PublicId,
      width: number,
      height: number,
      transformation: Object = {},
      analytics: Object = {}
    ) => {
      width = Math.ceil(width);
      height = Math.ceil(height);

      const url = cloudinary.video_url(`${publicId}.png`, {
        transformation: [
          getBaseTransformation(width, height, AssetType.IMAGE, {
            ...transformation,
          }),
        ],
      });

      return `${url}${objectToQuerystring({ pgw: 1, tmb: 1, ...analytics })}`;
    },
    getVideoByTag: async (tag: string): Promise<Object[]> => {
      const videosResponse = await fetch(
        cloudinary.video_url(`${tag}.json`, { type: 'list' })
      );

      let videosJson;

      try {
        videosJson = await videosResponse.json();
      } catch (e) {
        console.warn(`Trying to load video resources for tag ${tag} - ${e}`);

        videosJson = { resources: [] };
      }

      const resources = videosJson.resources.map((asset: any) =>
        prepareAsset(asset, MediaSymbolTypes.VIDEO, AssetType.VIDEO)
      );

      if (!resources.length) {
        console.warn(
          `Gallery widget can't find any media assets for ${tag}`
        );
      }

      return resources.sort(sortByKey('publicId'));
    },
    getImageByTag: async (tag: string): Promise<Object[]> => {
      const imagesResponse = await fetch(
        cloudinary.url(`${tag}.json`, { type: 'list' })
      );

      let imagesJson;

      try {
        imagesJson = await imagesResponse.json();
      } catch (e) {
        console.warn(
          `Gallery widget is trying to load images resources for tag ${tag} - ${e}`
        );
        imagesJson = { resources: [] };
      }

      const resources = imagesJson.resources
        .filter((asset: MediaAsset) => !is3dAsset(asset))
        .map((asset: any) =>
          prepareAsset(asset, MediaSymbolTypes.IMAGE, AssetType.IMAGE)
        );

      if (!resources.length) {
        console.warn(`Gallery widget can't find any media assets for ${tag}`);
      }

      return resources.sort(sortByKey('publicId'));
    },
    getThreeByTag: async (tag: string): Promise<Object[]> => {
      const imagesResponse = await fetch(
        cloudinary.url(`${tag}.json`, { type: 'list' })
      );

      let imagesJson;

      try {
        imagesJson = await imagesResponse.json();
      } catch (e) {
        console.warn(
          `Gallery widget is trying to load 3d resources for tag ${tag} - ${e}`
        );
        imagesJson = { resources: [] };
      }

      const resources = imagesJson.resources
        .filter((asset: MediaAsset) => is3dAsset(asset))
        .map((asset: any) =>
          prepareAsset(asset, MediaSymbolTypes.THREE, AssetType.IMAGE)
        );

      if (!resources.length) {
        console.warn(`Gallery widget can't find any media assets for ${tag}`);
      }

      return resources.sort(sortByKey('publicId'));
    },
  };
};

export const initCloudinaryCore = (params: any): CloudinaryCore => {
  return Cloudinary(params);
};
