import * as tinycolor from 'tinycolor2';
import keys from '../utils/keys';
import { isObject as isObj } from '../utils/object';
import { isArray as isArr } from '../utils/array';

import * as E from '../typing/enums';

let validatorsErrorMsg: string[] = [];

export const isNumber = (value: any, key: any): boolean => {
  const isValid = typeof value === 'number' && !isNaN(value);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}] should be a number`);
  }

  return isValid;
};

export const isArray = (value: any, key: any): boolean => {
  const isValid = isArr(value);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}] should be an array`);
  }

  return isValid;
};

export const isStr = (value: any) => typeof value === 'string';

export const isObject = (value: any, key: any): boolean => {
  const isValid = isObj(value);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}] should be an object`);
  }

  return isValid;
};

export const isMediaAssets = (items: any[]): boolean => {
  const validItems = items.filter((item: any) => {
    if (isStr(item)) {
      return true;
    } else if (isObj(item)) {
      if (!item.tag && !item.publicId) {
        validatorsErrorMsg.push(
          `mediaAsset should have either a tag or a publicId defined: ${JSON.stringify(
            item
          )}`
        );

        return false;
      } else if (item.tag && item.publicId) {
        validatorsErrorMsg.push(
          `mediaAsset should have either a tag or a publicId defined: ${JSON.stringify(
            item
          )}`
        );

        return false;
      } else if (item.publicId) {
        if (!isStr(item.publicId)) {
          validatorsErrorMsg.push(
            `publicId should be a string: ${JSON.stringify(item)}`
          );

          return false;
        }

        if (item.resourceType) {
          if (!isAssetType(item.resourceType, 'resourceType')) {
            return false;
          }
        }

        if (item.mediaType) {
          if (!isMediaSymbolTypes(item.mediaType, 'mediaType')) {
            return false;
          }
        }
      } else if (item.tag) {
        if (!isStr(item.tag)) {
          validatorsErrorMsg.push(
            `tag should be a string: ${JSON.stringify(item)}`
          );

          return false;
        }
      }

      if (item.mediaType) {
        return isMediaSymbolTypes(item.mediaType, 'mediaType');
      }

      if (item.transformation) {
        // if (!isObj(item.transformation)) {
        //   validatorsErrorMsg.push(
        //     `transformation should be an object: ${JSON.stringify(item)}`
        //   );
        //   return false;
        // }

        // if (!isTransformation(item.transformation, 'transformation')) {
        //   return false;
        // }
      }

      if (item.thumbnailTransformation) {
        // if (!isObj(item.thumbnailTransformation)) {
        //   validatorsErrorMsg.push(
        //     `thumbnailTransformation should be an object on media ${JSON.stringify(
        //       item
        //     )}`
        //   );
        //   return false;
        // }

        // if (!isTransformation(item.thumbnailTransformation, 'mediaAssets')) {
        //   return false;
        // }
      }
    } else {
      validatorsErrorMsg.push(
        `an error with the definition of your media asset: ${JSON.stringify(
          item
        )}`
      );
      return false;
    }

    return true;
  });

  return validItems.length === items.length;
};

export const isString = (value: any, key: any): boolean => {
  const isValid = typeof value === 'string';
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}] should be a string`);
  }

  return isValid;
};

export const isNotEmpty = (value: any, key: any): boolean => {
  const isValid = value ? value.trim() !== '' : false;
  if (!isValid) {
    validatorsErrorMsg.push(`${key} is empty. It should have a value`);
  }

  return isValid;
};

export const isElement = (
  selectorOrDom: string | HTMLElement,
  key: any
): boolean => {
  try {
    return selectorOrDom instanceof HTMLElement
      ? true
      : document.querySelector(selectorOrDom)
        ? true
        : false;
  } catch (e) {
    validatorsErrorMsg.push(
      `${key} = [${selectorOrDom}]. It should be a css selector (eg. 'id' or 'class') or a dom element`
    );
    return false;
  }
};

export const isGreaterThen = (
  value: number,
  greaterThen: number,
  key: any,
  hideError?: boolean
): boolean => {
  const isValid = value > greaterThen;

  if (!isValid && !hideError) {
    validatorsErrorMsg.push(
      `${key} = [${value}].  It should be >= ${greaterThen}`
    );
  }

  return isValid;
};

export const isNotNegative = (value: number, key: any): boolean => {
  const isValid = isGreaterThen(value, -1, key, true);

  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}].  It should be >= 0`);
  }

  return isValid;
};

export const isPositive = (value: number, key: any): boolean => {
  const isValid = isGreaterThen(value, 0, key, true);

  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}].  It should be > 0`);
  }

  return isValid;
};

export const isInteger = (value: number, key: any): boolean => {
  const isValid = Number.isInteger(value);

  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}].  It should be an Integer`);
  }

  return isValid;
};

export const isBoolean = (value: any, key: any): boolean => {
  const isValid = typeof value === 'boolean';

  if (!isValid) {
    validatorsErrorMsg.push(
      `${key} = [${value}]. It should be set to true or false`
    );
  }

  return isValid;
};

const isValidEnum = (value: any, enumType: any, key: any): boolean => {
  const enumValues = Object.values(enumType);
  const isValid = enumValues.find(val => val === value) ? true : false;

  if (!isValid) {
    validatorsErrorMsg.push(
      `${key} = [${value}]. This parameter supports only the following values: ${enumValues.join(
        '|'
      )}`
    );
  }

  return isValid;
};

const isValidEnumOrBoolean = (value: any, enumType: any, key: any): boolean => {
  // if value is boolean, convert ot string to support older version
  const stringValue = typeof value === 'boolean' ? value.toString() : value;

  return isValidEnum(stringValue, enumType, key);
};

export const isColor = (value: any, key: any): boolean => {
  if (!tinycolor(value).isValid()) {
    validatorsErrorMsg.push(
      `${key} = [${value}]. It should be a valid color definition (HEX, RGB, HLS, etc.)`
    );

    return false;
  }

  return true;
};

export const isPreload = (preload: any[]): boolean => {
  const key = 'preload';
  let isValid: boolean = true;

  preload.forEach((value: any) => {
    if (isString(value, key)) {
      if (!isValidEnum(value, E.PreloadTypes, key)) {
        isValid = false;
      }
    } else {
      isValid = false;
    }
  });

  return isValid;
};

const isAspectRatio = (value: any, key: any) =>
  isValidEnum(value, E.AspectRatio, key);
const isAssetType = (value: any, key: any) =>
  isValidEnum(value, E.AssetType, key);
const isPosition = (value: any, key: any) =>
  isValidEnum(value, E.CarouselLocation, key);
const isNavigation = (value: any, key: any) =>
  isValidEnum(value, E.Navigation, key);
const isButtonShape = (value: any, key: any) =>
  isValidEnum(value, E.ButtonShape, key);
const isGalleryNavigationPosition = (value: any, key: any) =>
  isValidEnum(value, E.GalleryNavigationPosition, key);
const isMediaSymbolPosition = (value: any, key: any) =>
  isValidEnum(value, E.MediaSymbolPosition, key);
const isMediaSymbolTypes = (value: any, key: any) =>
  isValidEnum(value, E.MediaSymbolTypes, key);
const isMediaSymbolShape = (value: any, key: any) =>
  isValidEnum(value, E.MediaSymbolShape, key);
const isSelectedStyles = (value: any, key: any) =>
  isValidEnum(value, E.SelectedStyles, key);
const isSelectedBorderPosition = (value: any, key: any) =>
  isValidEnum(value, E.SelectedBorderPosition, key);
const isDirection = (value: any, key: any) =>
  isValidEnum(value, E.Direction, key);
const isCarouselStyle = (value: any, key: any) =>
  isValidEnum(value, E.CarouselStyle, key);
const isPerView = (value: any, key: any) => isGreaterThen(value, -1, key);
const isButtonSize = (value: any, key: any) => isGreaterThen(value, 16, key);
const isZoomViewerPosition = (value: any, key: any) =>
  isValidEnum(value, E.ZoomViewerPosition, key);
const isIndicatorShape = (value: any, key: any) =>
  isValidEnum(value, E.IndicatorShape, key);
const isSkin = (value: any, key: any) => isValidEnum(value, E.Skin, key);
const isTransition = (value: any, key: any) =>
  isValidEnum(value, E.Transition, key);
const isCrop = (value: any, key: any) => isValidEnum(value, E.Crop, key);

const isZoomTrigger = (value: any, key: any) =>
  isValidEnum(value, E.ZoomTrigger, key);
const isZoomType = (value: any, key: any) =>
    isValidEnum(value, E.ZoomType, key);

const isSpinAnimation = (value: any, key: any) =>
  isValidEnum(value, E.SpinAnimation, key);

const isSpinDirection = (value: any, key: any) =>
  isValidEnum(value, E.SpinDirection, key);
const isSort = (value: any, key: any) => isValidEnum(value, E.Sort, key);
const isTipPosition = (value: any, key: any) =>
  isValidEnum(value, E.TipPosition, key);

const isShowTip = (value: any, key: any) => isValidEnum(value, E.TipShow, key);
const isZoomPopupShape = (value: any, key: any) =>
  isValidEnum(value, E.ZoomPopupShape, key);

const isTransformation = (value: any, key: any): boolean => {
  let isValid = true;
  if (value.crop) {
    if (!isCrop(value.crop, key)) {
      return false;
    }
  }

  if (value.transformation) {
    if (!isArray(value.transformation, 'transformation.transformation')) {
      return false;
    }
  }

  if (value.width || value.height) {
    isValid = false;

    validatorsErrorMsg.push(
      `'${key}' currently has width or height defined. Please remove`
    );
  }

  return isValid;
};

const isControls = (value: any, key: any): boolean => isValidEnumOrBoolean(value, E.Controls, key);
const isDisplayMode = (value: any, key: any) => isValidEnum(value, E.DisplayMode, key);
const isLoaderStyle = (value: any, key: any) => isValidEnum(value, E.LoaderStyle, key);

const validatorZoomProps = {
  _validator: true,
  _type: {},
  _props: keys('ZoomConfig'),
  level: [isNumber, isNotNegative],
  viewerPosition: [isString, isZoomViewerPosition],
  viewerOffset: [isNumber, isNotNegative],
  viewerRadius: [isNumber, isNotNegative],
  viewerZIndex: [isNumber],
  showLens: [isBoolean],
  lensBorderColor: [isString, isColor],
  lensBorderWidth: [isNumber, isNotNegative],
  lensColor: [isString, isColor],
  lensOpacity: [isNumber, isNotNegative],
  lensRadius: [isNumber, isNotNegative],
  lensShadow: [isBoolean],
  trigger: [isString, isZoomTrigger],
  container: [isString, isElement],
  showTip: [isString, isShowTip],
  tipText: [isString],
  tipTouchText: [isString],
  tipPosition: [isString, isTipPosition],
  type: [isZoomType],
};

const validatorSpinProps = {
  _validator: true,
  _type: {},
  _props: keys('SpinProps'),
  animate: [isSpinAnimation],
  animationDuration: [isNumber, isNotNegative],
  spinDirection: [isSpinDirection],
  disableZoom: [isBoolean],
  tipText: [isString],
  tipTouchText: [isString],
  tipPosition: [isString, isTipPosition],
  showTip: [isString, isShowTip],
};

const validatorsThumbnailProps = {
  _validator: true,
  _props: keys('ThumbnailConfigProps'),
  _type: {},
  transformation: [isObject, isTransformation],
  spacing: [isNumber, isNotNegative],
  gutter: [isNumber, isNotNegative],
  perView: [isNumber, isPerView],
  width: [isNumber, isNotNegative],
  height: [isNumber, isNotNegative],
  borderWidth: [isNumber, isNotNegative],
  borderColor: [isString, isColor],
  radius: [isNumber, isNotNegative],
  navigationBorderColor: [isString, isColor],
  navigationBorderWidth: [isNumber, isNotNegative],
  navigationIconColor: [isString, isColor],
  navigationColor: [isString, isColor],
  navigationSize: [isNumber, isButtonSize],
  navigationShape: [isString, isButtonShape],
  navigationFloat: [isBoolean],
  mediaSymbolPosition: [isString, isMediaSymbolPosition],
  mediaSymbolType: [isString, isMediaSymbolTypes],
  mediaSymbolShape: [isString, isMediaSymbolShape], // neeed to chane to shape
  mediaSymbolColor: [isString, isColor],
  mediaSymbolOpacity: [isNumber],
  mediaSymbolIconShadow: [isBoolean],
  mediaSymbolIconColor: [isString, isColor],
  mediaSymbolSize: [isNumber],
  selectedStyle: [isString, isSelectedStyles],
  selectedBorderPosition: [isString, isSelectedBorderPosition],
  selectedBorderColor: [isString, isColor],
  selectedBorderWidth: [isNumber, isNotNegative],
  selectedBorderOpacity: [isNumber, isNotNegative],
  selectedGradientStart: [isString, isColor],
  selectedGradientEnd: [isString, isColor],
  selectedGradientDirection: [isString, isDirection],
  selectedGradientOpacity: [isNumber, isNotNegative],
};

const validatorsIndicatorProps = {
  _validator: true,
  _props: keys('IndicatorProps'),
  _type: {},
  selectedColor: [isString, isColor],
  color: [isString, isColor],
  size: [isNumber, isNotNegative],
  spacing: [isNumber],
  shape: [isString, isIndicatorShape],
};

const validatorsZoomPopupProps = {
  _validator: true,
  _props: keys('ZoomPopupProps'),
  _type: {},
  buttonShape: [isString, isZoomPopupShape],
  buttonIconColor: [isString, isColor],
  buttonColor: [isString, isColor],
  buttonSize: [isNumber, isNotNegative],
  zIndex: [isNumber],
  backdropOpacity: [isNumber, isNotNegative],
  backdropColor: [isString, isColor],
};

const validatorsDisplayProps = {
  _validator: true,
  _props: keys('DisplayProps'),
  _type: {},
  mode: [isDisplayMode],
  spacing: [isNumber, isNotNegative],
  columns: [isNumber, isPositive, isInteger],
};

const validators = {
  logErrors: [isBoolean],
  id: [isString],
  skin: [isString, isSkin],
  startIndex: [isNumber, isNotNegative],
  cloudName: [isString, isNotEmpty],
  privateCdn: [isString],
  secureDistribution: [isString],
  cname: [isString],
  sort: [isSort],
  focus: [isBoolean],
  cdnSubdomain: [isString],
  container: [isElement],
  thumbnailContainer: [isString, isElement],
  aspectRatio: [isString, isAspectRatio],
  transition: [isString, isTransition],
  analytics: [isBoolean],
  placeholderImage: [isBoolean],
  preload: [isArray, isPreload],
  selectedIndex: [isNumber, isNotNegative],
  mediaAssets: [isArray, isMediaAssets],
  transformation: [isObject, isTransformation],
  borderColor: [isString, isColor],
  borderWidth: [isNumber, isNotNegative],
  radius: [isNumber, isNotNegative],
  carouselLocation: [isString, isPosition],
  carouselStyle: [isString, isCarouselStyle],
  carouselOffset: [isNumber],
  thumbnailProps: validatorsThumbnailProps,
  indicatorProps: validatorsIndicatorProps,
  zoomPopupProps: validatorsZoomPopupProps,
  viewportBreakpoints: {
    _validator: true,
    _type: [],
    _props: keys('ViewportBreakpointConfigProps'),
    breakpoint: [isNotNegative],
    transformation: [isObject, isTransformation],
    aspectRatio: [isString, isAspectRatio],
    borderColor: [isString, isColor],
    borderWidth: [isNumber, isNotNegative],
    radius: [isNumber, isNotNegative],
    carouselLocation: [isString, isPosition],
    carouselStyle: [isString, isCarouselStyle],
    carouselOffset: [isNumber],
    navigation: [isString, isNavigation],
    navigationPosition: [isString, isGalleryNavigationPosition],
    navigationOffset: [isNumber],
    navigationButtonProps: {
      _validator: true,
      _props: keys('NavigationButtonProps'),
      _type: {},
      shape: [isString, isButtonShape],
      iconColor: [isString, isColor],
      color: [isString, isColor],
      size: [isNumber],
    },
    thumbnailProps: validatorsThumbnailProps,
    indicatorProps: validatorsIndicatorProps,
    zoomPopupProps: validatorsZoomPopupProps,
    zoomProps: validatorZoomProps,
    spinProps: validatorSpinProps,
  },
  navigation: [isString, isNavigation],
  navigationPosition: [isString, isGalleryNavigationPosition],
  navigationOffset: [isNumber],
  navigationButtonProps: {
    _validator: true,
    _type: {},
    _props: keys('NavigationButtonProps'),
    shape: [isString, isButtonShape],
    color: [isString, isColor],
    bgColor: [isString, isColor],
    size: [isNumber],
  },
  zoom: [isBoolean],
  zoomProps: validatorZoomProps,
  themeProps: {
    _validator: true,
    _type: {},
    _props: keys('ThemeConfig'),
    primary: [isString, isColor],
    onPrimary: [isString, isColor],
    active: [isString, isColor],
    onActive: [isString, isColor],
  },
  spinProps: validatorSpinProps,
  videoProps: {
    _validator: true,
    _type: {},
    _props: keys('VideoConfig'),
    autoplay: [isBoolean],
    loop: [isBoolean],
    controls: [isControls],
    sound: [isBoolean],
  },
  tipProps: {
    _validator: true,
    _type: {},
    _props: keys('TipProps'),
    textColor: [isString, isColor],
    color: [isString, isColor],
    radius: [isNumber, isNotNegative],
    opacity: [isNumber, isNotNegative],
  },
  loaderProps: {
    _validator: true,
    _type: {},
    _props: keys('LoaderConfig'),
    color: [isString, isColor],
    size: [isNumber, isNotNegative],
    opacity: [isNumber, isNotNegative],
    style: [isLoaderStyle],
    url: [isString],
  },
  displayProps: validatorsDisplayProps,
};

interface Validator {
  _validator: boolean;
  _props: string[];
  [propName: string]: any;
}

const validate = (configKeys: any, config: any, path?: string): void => {
  configKeys.forEach((key: any) => {
    const configValidators: Function[] | Validator = path
      ? validators[path][key]
      : validators[key];
    const configValue: any = config[key];

    if (
      configValidators &&
      !(configValidators instanceof Array) &&
      configValue
    ) {
      if (configValidators._validator) {
        if (
          Array.isArray(configValidators._type) &&
          !Array.isArray(configValue)
        ) {
          validatorsErrorMsg.push(`${key} must be an array.`);
        } else if (isObj(configValidators._type) && !isObj(configValue)) {
          validatorsErrorMsg.push(`${key} must be an object.`);
        } else if (configValue instanceof Array) {
          configValue.forEach(value => {
            validate(configValidators._props, value, key);
          });
        } else {
          validate(configValidators._props, configValue, key);
        }
      }

      return;
    } else {
      if (configValidators && configValue !== undefined) {
        configValidators.some(
          (validator: Function) =>
            !validator(configValue, `${path ? `${path}.${key}` : key}`)
        );
      }
    }
  });
};

export default (config: any): boolean => {
  validatorsErrorMsg = [];

  validate(keys('ConfigProps'), config);

  const isValid: boolean = validatorsErrorMsg.length === 0;

  if (!isValid) {
    const errorMsgTitle = `🌧🌧🌧 Cloudinary Product Gallery Configuration: one or more of your configuration values are not valid: 🌧🌧🌧`;
    const errorMsg = `${validatorsErrorMsg.join('\n')}`;
    if (config.logErrors) {
      console.error(
        `%c${errorMsgTitle} %c${errorMsg}`,
        'background-color: #f44235; color: #FFF;font-weight: bold;padding: 10px; line-height: 1.5; border-bottom: 2px solid #f44235;',
        'background-color: #c6d1db; color: #000;padding: 10px; line-height: 1.5; border-bottom: 2px solid #f44235;'
      );
    } else {
      throw new Error(`${errorMsgTitle}${errorMsg}`);
    }
  }

  return isValid;
};
