import { css, cx } from "emotion";
import { GalleryNavigationPosition, Navigation } from "../../typing/enums";
import Button from "../Button/Button";
import { h } from "preact";
import { ButtonProps } from "../../typing";
import * as E from '../../typing/enums';

interface NavigationButtonProps extends ButtonProps {
    navigation: Navigation;
    onClick: Function;
    position?: GalleryNavigationPosition;
    offset?: number;
    direction: 'left' | 'right';
}

const NavButton = (props: NavigationButtonProps) => {
    const baseCls = css`
    position: absolute;
    z-index: 1;
    top: calc(50% - ${props.size / 2}px);
    opacity: ${props.navigation === Navigation.MOUSE_OVER ? 0 : 1};
    display: ${props.navigation === Navigation.NONE ? 'none' : 'flex'};
    transition: opacity 0.25s ease-in;
  `;

    const buttonHalfSize = props.shape === E.ButtonShape.RECTANGLE ? 4 : 2;

    const posCls = css`
    ${props.direction}: ${props.position === GalleryNavigationPosition.MIDDLE
        ? ((props.size || 0) / buttonHalfSize) * -1
        : props.position === GalleryNavigationPosition.OFFSET
            ? props.offset
            : 0}px;
  `;

    const icon = props.direction === 'left' ? 'arrowLeft' : 'arrowRight';

    return (
        <Button
            className={cx(baseCls, posCls)}
            icon={icon}
            {...props}
            onClick={props.onClick}
            data-test={`nav-${props.direction === 'left' ? 'prev' : 'next'}`}
        />
    );
};

export default NavButton;