import * as enums from "../typing/enums";
import urlParamToJson from "./urlParamToJson";
import {BaseAsset} from "../typing";
import {RESOURCE_IMAGE_DEFAULTS} from "../config/defaults";

const parseAssetTransformation = (transformation: any) =>
    !transformation ? {} : transformation && typeof transformation === 'string' ? urlParamToJson(transformation) : transformation;

const prepareSpin = async (asset: any, cloudinary: any, transformationProps: any) => {
    const assets: Object[] = await cloudinary.getImageByTag(asset.tag);
    const isValidSpinAsset = !!(assets && assets.length);

    return isValidSpinAsset && {
        publicId: asset.tag,
        ...asset,
        ...transformationProps,
        assets,
        mediaType: enums.MediaSymbolTypes.SPIN,
    };
};


const prepareImages = async (asset: any, cloudinary: any, transformationProps: any) => {
    const images: any[] = [];

    (await cloudinary.getImageByTag(asset.tag)).forEach((imageAsset: any) => {
        images.push({
          ...imageAsset,
          ...transformationProps,
        });
      }
    );

    return images;
};

const prepareThree = async (asset: any, cloudinary: any, transformationProps: any) => {
    const baseProps = {
        mediaType: enums.MediaSymbolTypes.THREE,
        resourceType: enums.MediaSymbolTypes.THREE,
    };

    const images = await cloudinary.getThreeByTag(asset.tag);

    return images.map((imageAsset: any) => ({...imageAsset, ...baseProps, ...transformationProps}));

};

const prepareVideo = async (asset: any, cloudinary: any, transformationProps: any) => {
    let videos = await cloudinary.getVideoByTag(asset.tag);
    return videos.map((videoAsset: any) => ({...videoAsset, ...transformationProps}));
};

export const prepareImageFromPublicIdString = (publicId: string): Object => ({...(RESOURCE_IMAGE_DEFAULTS as BaseAsset), publicId});

export const prepareByPublicId = (asset: any) => {
    if (asset.mediaType === enums.MediaSymbolTypes.THREE) {
        asset.resourceType = enums.MediaSymbolTypes.THREE;
    }

    if (asset.publicId && !asset.mediaType) {
        asset.mediaType = enums.MediaSymbolTypes.IMAGE;
    }

    asset.transformation = parseAssetTransformation(asset.transformation);
    asset.thumbnailTransformation = parseAssetTransformation(asset.thumbnailTransformation);

    return asset;
};

export const prepareAssetFromTag = async (asset: any, cloudinary: any) => {
    const assets = [];
    let load3dBundle = false;

    const transformationProps = {
        transformation: parseAssetTransformation(asset.transformation),
        thumbnailTransformation: parseAssetTransformation(asset.thumbnailTransformation),
    };

    if (
        asset.mediaType === enums.MediaSymbolTypes.IMAGE ||
        !asset.mediaType
    ) {
        const images = await prepareImages(asset, cloudinary, transformationProps);
        images.length && assets.push(...images);
    }

    if (asset.mediaType === enums.MediaSymbolTypes.SPIN) {
        const spin = await prepareSpin(asset, cloudinary, transformationProps);
        spin && assets.push(spin);
    }

    if (asset.mediaType === enums.MediaSymbolTypes.VIDEO) {
        const videos = await prepareVideo(asset, cloudinary, transformationProps);
        videos.length && assets.push(...videos);
    }

    if (asset.mediaType === enums.MediaSymbolTypes.THREE) {
        const three = await prepareThree(asset, cloudinary, transformationProps);

        if (three.length) {
            load3dBundle = true;
            assets.push(...three);
        }
    }

    return { assets, load3dBundle };
};