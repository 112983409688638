import * as E from './enums';
import {Controls, PreloadTypes} from './enums';

declare global {
  interface Window {
    cloudinary: any;
    dataLayer: any;
    THREE: any;
  }
}

export interface GalleryWidget {
  galleryWidget: Function;
  [propName: string]: any;
}

export type PerView = number | undefined;
export type Color = string;
export type Cloudinary = any;
export type CloudinaryCore = {
  getCore: Function;
  getThreeUrl: Function;
  getImageUrl: Function;
  getVideoUrl: Function;
  getVideoThumbnailUrl: Function;
  getVideoByTag: Function;
  getImageByTag: Function;
};

export type PublicId = string;
export type MediaAssets = any;
export type LoadedAsset = {
  domNode: HTMLImageElement;
  url: string;
  width: number;
  height: number;
  transformation: string;
};

export type Transformation = {crop?: E.Crop; [key: string]: any};

export interface MediaAsset {
  tag?: string;
  publicId?: string;
  resourceType?: E.AssetType;
  mediaType?: E.MediaSymbolTypes;
  transformation?: Transformation;
  format?: string;
}

export const MediaAssetKeys = ['tag', 'publicId', 'resourceType', 'mediaType'];

export interface BaseAsset {
  publicId: PublicId | PublicId[];
  mediaType: E.MediaSymbolTypes;
  resourceType: E.AssetType;
  transformation: Transformation;
  thumbnailTransformation: Transformation;
}

export interface ButtonProps {
  shape: E.ButtonShape;
  iconColor: Color;
  size: E.ButtonSizes | number;
  icon: string;
  color?: Color;
  borderColor?: Color;
  borderWidth?: number;
  disabled?: boolean;
  onClick?: Function;
  className?: string;
}

export const ButtonPropsKeys = [
  'shape',
  'iconColor',
  'size',
  'icon',
  'color',
  'disabled',
  'onClick',
  'className',
  'borderColor',
  'borderWidth',
];

export interface RectangleButtonProps extends ButtonProps {
  horizontal?: boolean;
}

export interface CarouselProps {
  itemWidth?: number;
  itemHeight?: number;
  index?: number;
  axis?: E.Axis;
  transition?: E.Transition;
  spacing?: number;
  children?: any;
  perView?: number;
  navigation?: boolean;
  navigationBorderWidth?: number;
  navigationBorderColor?: Color;
  navigationColor?: Color;
  navigationIconColor?: Color;
  navigationSize?: number;
  navigationGutter?: number;
  navigationShape?: E.ButtonShape;
  navigationFloat?: boolean;
  className?: string;
  onNext?: Function;
  onPrev?: Function;
  onItemClick?: Function;
  onItemHover?: Function;
  onItemSwipe?: any; //
  allowSwipe?: Function;
}

export const CarouselPropsKeys = [
  'itemWidth',
  'itemHeight',
  'index',
  'axis',
  'transition',
  'spacing',
  'navigation',
  'children',
  'perView',
  'navigationBorderWidth',
  'navigationBorderColor',
  'navigationColor',
  'navigationIconColor',
  'navigationSize',
  'navigationGutter',
  'navigationShape',
  'navigationFloat',
  'className',
];

export interface CarouselButtonProps {
  shape?: E.ButtonShape;
  axis: E.Axis;
  size?: any;
  float?: boolean;
  spacing?: number;
  borderColor?: Color;
  borderWidth?: number;
  iconColor?: Color;
  color?: Color;
  direction?: 'left' | 'right';
  disabled?: boolean;
  gutter?: number;
  onClick?: Function;
  wrapWidth: number;
  wrapHeight: number;
}

export interface CarouselState {
  index: number;
  showNext: boolean;
  showPrev: boolean;
  selected?: number;
  dragging: boolean;
  position: number;
}

export interface NavigationButtonProps {
  shape?: E.ButtonShape;
  iconColor?: Color;
  color?: Color;
  size?: number;
}

export const NavigationButtonPropsKeys = [
  'shape',
  'iconColor',
  'color',
  'size',
];

export interface CarouselItemProps {
  type?: E.MediaSymbolTypes;
  width: string;
  height: string;
  spacing: number;
  children?: [];
  onItemClick?: Function;
  onItemHover?: Function;
  disableEvents?: boolean;
  transition?: E.Transition;
  className?: string;
  style?: Object;
  show?: boolean;
  axis?: E.Axis;
  index?: number;
}

export interface ThumbnailConfigProps {
  transformation?: Transformation;
  spacing?: number; // change to spacing
  perView?: number;
  gutter?: number;
  width?: number;
  height?: number;
  borderWidth?: number;
  borderColor?: Color;
  radius?: number;
  navigationBorderColor?: Color;
  navigationBorderWidth?: number;
  navigationColor?: Color;
  navigationIconColor?: Color;
  navigationSize?: number;
  navigationShape?: E.ButtonShape;
  navigationFloat?: boolean;
  mediaSymbolPosition?: E.MediaSymbolPosition;
  mediaSymbolType?: E.MediaSymbolTypes; // not for documentation
  mediaSymbolShape?: E.MediaSymbolShape;
  mediaSymbolColor?: Color;
  mediaSymbolOpacity?: number; //  0-1
  mediaSymbolIconShadow?: boolean; // boolean
  mediaSymbolIconColor?: Color; // rgba, hls, hex..
  mediaSymbolSize?: number;
  selectedStyle?: E.SelectedStyles;
  selectedBorderPosition?: E.SelectedBorderPosition;
  selectedBorderColor?: Color;
  selectedBorderWidth?: number;
  selectedBorderOpacity?: number;
  selectedGradientStart?: Color;
  selectedGradientEnd?: Color;
  selectedGradientDirection?: E.Direction;
  selectedGradientOpacity?: number;
  sticky?: boolean;
}

export const ThumbnailConfigPropsKeys = [
  'transformation',
  'spacing',
  'gutter',
  'perView',
  'width',
  'height',
  'borderWidth',
  'borderColor',
  'radius',
  'navigationBorderColor',
  'navigationBorderWidth',
  'navigationColor',
  'navigationIconColor',
  'navigationSize',
  'navigationShape',
  'navigationFloat',
  'mediaSymbolPosition',
  'mediaSymbolType',
  'MediaSymbolShape',
  'mediaSymbolBgColor',
  'mediaSymbolBgOpacity',
  'mediaSymbolShadow',
  'mediaSymbolColor',
  'mediaSymbolSize',
  'selectedStyle',
  'selectedBorderPosition',
  'selectedBorderColor',
  'selectedBorderWidth',
  'selectedBorderOpacity',
  'selectedGradientStart',
  'selectedGradientEnd',
  'selectedGradientDirection',
  'selectedGradientOpacity',
  'sticky',
];

export interface ThumbnailsProps {
  index: number;
  selectedIndex: number;
  items?: MediaAssets;
  axis?: E.Axis;
  navigation?: boolean;
  onItemSelect?: Function;
  assets?: any[];
  context: AppContext;
}

export interface GalleryProps {
  carouselLocation?: E.CarouselLocation;
  index?: number;
  items?: MediaAssets;
  navigation?: E.Navigation;
  navigationPosition?: E.GalleryNavigationPosition;
  navigationOffset?: number;
  navigationShape?: E.ButtonShape;
  navigationIconColor?: Color;
  navigationColor?: Color;
  navigationSize?: number;
  className?: string;
  context: AppContext;
}

export interface GalleryState {
  index: number;
}

export interface SelectedItemProps {
  style?: E.SelectedStyles;
  borderPosition?: E.SelectedBorderPosition;
  borderColor?: Color;
  borderWidth?: number;
  borderOpacity?: number;
  gradientStart?: string;
  gradientEnd?: string;
  gradientOpacity?: number;
  gradientDirection?: E.Direction;
}

export const SelectedItemPropsKeys = [
  'style',
  'borderPosition',
  'borderColor',
  'borderWidth',
  'borderOpacity',
  'gradientStart',
  'gradientEnd',
  'gradientOpacity',
  'gradientDirection',
];

export interface MediaSymbolProps {
  type: E.MediaSymbolTypes;
  shape: E.MediaSymbolShape;
  icon?: string;
  color?: Color;
  opacity?: number;
  iconShadow?: boolean;
  iconColor?: Color;
  size?: number;
  className?: string;
}

export const MediaSymbolPropsKeys = [
  'type',
  'shape',
  'icon',
  'color',
  'opacity',
  'iconShadow',
  'iconColor',
  'size',
  'className',
];

export interface ImageProps {
  src: string;
  width: number;
  height: number;
}

type Debug = 'validation' | 'thumbnails' | 'gallery' | 'carousel' | boolean;

export interface CloudinaryConfig {
  cloudName: string;
  privateCdn?: string;
  secureDistribution?: string;
  cname?: string;
  cdnSubdomain?: string;
  secure?: boolean;
}

export const CloudinaryConfigKeys = [
  'cloudName',
  'privateCdn',
  'secureDistribution',
  'cname',
  'cdnSubdomain',
  'secure',
];

export interface ConfigProps extends CloudinaryConfig {
  id?: string;
  debug?: Debug;
  logErrors?: boolean;
  container: string | HTMLElement;
  sort?: E.Sort;
  focus: boolean;
  analytics?: boolean;
  aspectRatio?: E.AspectRatio;
  transition?: E.Transition;
  placeholderImage: boolean;
  preload: PreloadTypes[];
  viewportBreakpoints: ViewportBreakpointConfigProps[];
  imageBreakpoint?: number;
  startIndex: number;
  selectedIndex: number;
  mediaAssets: MediaAsset[];
  transformation: Transformation;
  thumbnailContainer?: HTMLElement | string;
  radius: number;
  borderWidth: number;
  borderColor: Color;
  carouselStyle: E.CarouselStyle;
  carouselLocation: E.CarouselLocation;
  carouselOffset?: number;
  thumbnailProps: ThumbnailConfigProps;
  indicatorProps: IndicatorProps;
  loaderProps: LoaderConfig;
  navigation: E.Navigation;
  navigationPosition?: E.GalleryNavigationPosition;
  navigationOffset?: number;
  navigationButtonProps?: NavigationButtonProps;
  bgColor?: Color;
  zoom: boolean;
  zoomProps: ZoomConfig;
  spinProps: SpinProps;
  tipProps: TipProps;
  zoomPopupProps: ZoomPopupProps;
  videoProps: VideoConfig;
  themeProps: ThemeConfig;
  skin?: E.Skin;
  displayProps?: DisplayProps;
}

export const ConfigPropsKeys = [
  'debug',
  'logErrors',
  'container',
  'focus',
  'sort',
  'aspectRatio',
  'analytics',
  'placeholderImage',
  'preload',
  'transition',
  'viewportBreakpoints',
  'imageBreakpoint',
  'startIndex',
  'selectedIndex',
  'mediaAssets',
  'transformation',
  'thumbnailContainer',
  'carouselStyle',
  'carouselLocation',
  'carouselOffset',
  'radius',
  'borderWidth',
  'borderColor',
  'thumbnailProps',
  'indicatorProps',
  'loaderProps',
  'navigation',
  'navigationPosition',
  'navigationOffset',
  'navigationButtonProps',
  'bgColor',
  'zoom',
  'zoomProps',
  'spinProps',
  'tipProps',
  'zoomPopupProps',
  'videoProps',
  'themeProps',
  'skin',
  'displayProps',
];

export interface TipProps {
  textColor: Color;
  color: Color;
  radius: number;
  opacity: number;
}

export const TipPropsKeys = ['textColor', 'color', 'radius', 'opacity'];

export interface ZoomPopupProps {
  buttonShape?: E.ButtonShape;
  buttonColor?: Color;
  buttonIconColor?: Color;
  buttonSize?: number;
  zIndex?: number;
  backdropColor?: Color;
  backdropOpacity?: number;
}

export const ZoomPopupPropsKeys = [
  'buttonShape',
  'buttonColor',
  'buttonIconColor',
  'buttonSize',
  'zIndex',
  'backdropColor',
  'backdropOpacity',
];

export interface SpinProps {
  animate: E.SpinAnimation;
  animationDuration: number;
  spinDirection: E.SpinDirection;
  disableZoom: boolean;
  tipText: string;
  tipTouchText: string;
  tipPosition: E.TipPosition;
  showTip: E.TipShow;
}

export const SpinPropsKeys = [
  'animate',
  'animationDuration',
  'spinDirection',
  'disableZoom',
  'tipText',
  'tipTouchText',
  'tipPosition',
  'showTip',
];

export interface LoaderConfig {
  color: Color;
  size: number;
  opacity: number;
  style: string;
  url : string;
}

export const LoaderConfigKeys = ['color', 'size', 'opacity', 'style', 'url'];

export interface VideoConfig {
  autoplay: boolean;
  loop: boolean;
  controls: Controls;
  sound: boolean;
}

export const VideoConfigKeys = ['autoplay', 'loop', 'controls', 'sound'];

export interface ThemeConfig {
  primary: Color;
  onPrimary: Color;
  active: Color;
  onActive: Color;
}

export const ThemeConfigKeys = ['primary', 'onPrimary', 'active', 'onActive'];
export interface IndicatorProps {
  selectedColor: Color;
  color: Color;
  size: number;
  spacing: number;
  shape: E.IndicatorShape;
  sticky?: boolean;
}

export const IndicatorPropsKeys = [
  'selectedColor',
  'color',
  'size',
  'total',
  'spacing',
  'shape',
  'sticky',
];

// ViewportBreakpointConfigProps;
// ConfigProps;

export interface ZoomConfig {
  type?: E.ZoomType;
  level: number;
  viewerPosition: E.ZoomViewerPosition;
  viewerOffset: number;
  viewerRadius: number;
  viewerZIndex: number;
  showLens: boolean;
  lensBorderColor: Color;
  lensBorderWidth: number;
  lensColor: Color;
  lensOpacity: number;
  lensRadius: number;
  lensShadow: boolean;
  trigger?: E.ZoomTrigger;
  container?: string;
  showTip?: E.TipShow;
  tipText?: string;
  tipTouchText?: string;
  tipPosition?: E.TipPosition;
}

export const ZoomConfigKeys = [
  'type',
  'level',
  'viewerPosition',
  'viewerZIndex',
  'viewerOffset',
  'viewerRadius',
  'showLens',
  'lensBorderColor',
  'lensBorderWidth',
  'lensColor',
  'lensRadius',
  'lensOpacity',
  'lensShadow',
  'trigger',
  'container',
  'showTip',
  'tipText',
  'tipTouchText',
  'tipPosition',
];

export interface BreakpointProps {
  aspectRatio?: E.AspectRatio;
  transformation?: Transformation;
  carouselLocation?: E.CarouselLocation;
  carouselStyle?: E.CarouselStyle;
  carouselOffset?: number;
  thumbnailProps?: ThumbnailConfigProps;
  indicatorProps?: IndicatorProps;
  navigation?: E.Navigation;
  navigationPosition?: E.GalleryNavigationPosition;
  navigationOffset?: number;
  navigationButtonProps?: NavigationButtonProps;
  bgColor?: Color;
  zoomProps?: ZoomConfig;
  spinProps?: SpinProps;
  radius?: number;
  borderWidth?: number;
  borderColor?: Color;
  displayProps?: DisplayProps;
}

export const BreakpointPropsKeys = [
  'aspectRatio',
  'transformation',
  'carouselLocation',
  'carouselStyle',
  'carouselOffset',
  'thumbnailProps',
  'indicatorProps',
  'navigation',
  'navigationPosition',
  'navigationOffset',
  'navigationButtonProps',
  'bgColor',
  'zoomProps',
  'radius',
  'borderWidth',
  'borderColor',
  'displayProps',
];

export interface ViewportBreakpointConfigProps extends BreakpointProps {
  breakpoint: number;
}

export const ViewportBreakpointConfigPropsKeys = ['breakpoint'];

export interface ConfigSelectors {
  __orig?: ConfigProps;
  selectConfig: Function;
  selectAxis: Function;
  selectContainer: Function;
  selectViewerWidth: Function;
  selectThumbnailWidth: Function;
  selectThumbnailHeight: Function;
  selectThumbnailsCarouselProps: Function;
  selectPreloadImage: Function;
  selectPreloadVideo: Function;
  selectPreloadSpin: Function;
  selectPreloadThree: Function;
  selectZoomPopupProps: Function;
  [key: string]: any;
}

export interface AppContext {
  cloudinary: Cloudinary;
  config: ConfigSelectors;
  events: any;
  cacher: Function;
}

export interface Dimensions {
    width: number,
    height: number
}

export const DisplayPropsKeys = [
  'mode',
  'spacing',
  'columns',
  'topOffset',
  'bottomOffset',
];

export interface DisplayProps {
  mode?: string,
  spacing?: number,
  columns?: number,
  topOffset?: number,
  bottomOffset?: number,
}
