

const mappedParams = {
    'w': 'width',
    'h': 'height',
    'c': 'crop',
    'ar': 'aspect_ratio',
    'z': 'zoom',
    'x': 'x',
    'y': 'y',
    'q': 'quality',
    'r': 'radius',
    'a': 'angle',
    'e': 'effect',
    'o': 'opacity',
    'bo': 'border',
    'b': 'background',
    'g': 'gravity',
    'l': 'overlay',
    'u': 'underlay',
    'd': 'default_image',
    'dl': 'delay',
    'co': 'color',
    'cs': 'color_space',
    'dpr': 'dpr',
    'f': 'fetch_format',
    'pg': 'page',
    'dn': 'density',
    'flags': 'fl',
    't': 'transformation',
    'if': 'if',
    'fn': 'custom_function',
    '$': 'variable',
};

const parseParam = (param: string) => {
    const jsonParam = {};

    const paramName = param.substring(0, param.indexOf('_'));
    const paramValue = param.substring(param.indexOf('_') + 1);

    if (mappedParams[paramName]) {
        jsonParam[mappedParams[paramName]] = paramValue;
    } else {
        console.log(`'${paramName}' is missing cloudinary mapping reference API, please contact support`);
    }

    return jsonParam;
}

const parseParams = (params: string) => {
    let jsonParams = {};
    if (params) {
        params.split(/,/).forEach((param) => {
            jsonParams = { ...jsonParams, ...parseParam(param) };
        });
    }

    return jsonParams;
}

export default (url: string) => {
    try {
        const sections = url.split(/\//);
        let jsonParams: any = {};

        jsonParams = { ...parseParams(sections[0]) };
        if (sections.length > 1) {
            jsonParams.transformation = [];
            for (let i = 1; i < sections.length; i++) {
                jsonParams.transformation.push(parseParams(sections[i]));
            }
        }

        return jsonParams;

    } catch (e) {
        console.log(`Error parsing cloudinary url params to JSON ${e}`);
        return {};
    }

}