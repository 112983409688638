import {Axis} from '../typing/enums';
import {isMobile} from 'mobile-device-detect';

export const mouseTracker = (event: any, axis: Axis = Axis.HORIZONTAL) => {
  //const eventAttr = `page${axis === Axis.HORIZONTAL ? 'X' : 'Y'}`;
  let lastMousePos =
      axis === Axis.HORIZONTAL ? getMouseX(event) : getMouseY(event),
    lastMousePosX = getMouseX(event),
    lastMousePosY = getMouseY(event);

  let direction = 'next';

  return (event: any) => {
    const newMousePos =
        axis === Axis.HORIZONTAL ? getMouseX(event) : getMouseY(event),
      newMousePosX = getMouseX(event),
      newMousePosY = getMouseY(event),
      moveBy = newMousePos - lastMousePos,
      moveByX = newMousePosX - lastMousePosX,
      moveByY = newMousePosY - lastMousePosY;

    direction =
      newMousePos > lastMousePos
        ? 'prev'
        : newMousePos === lastMousePos
        ? direction
        : 'next';

    lastMousePos = newMousePos;
    lastMousePosX = newMousePosX;
    lastMousePosY = newMousePosY;

    return {
      moveBy,
      moveByX,
      moveByY,
      direction,
    };
  };
};

export const getMouseX = (event: any): number => {
  return event.touches && event.touches.length
    ? event.touches[0].pageX
    : event.changedTouches && event.changedTouches.length
    ? event.changedTouches[0].pageX
    : event.pageX;
};

export const getMouseY = (event: any): number => {
  return event.touches && event.touches.length
    ? event.touches[0].pageY
    : event.changedTouches && event.changedTouches.length
    ? event.changedTouches[0].pageY
    : event.pageY;
};

export const clickTrigger = (event: MouseEvent): Function => {
  let left = 0,
    top = 0,
    radiusLimit = 5;

  left = getMouseX(event);
  top = getMouseY(event);

  return (event: any): boolean => {
    var deltaX = getMouseX(event) - left;
    var deltaY = getMouseY(event) - top;
    var euclidean = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    return euclidean < radiusLimit;
  };
};

export const clickEventListener = (
  element: HTMLElement,
  handlerClick?: Function,
  handlerOther?: Function
): {removeEventListener: Function} => {
  let trigger: Function;

  const onMouseDown = (event: MouseEvent) => {
    trigger = clickTrigger(event);
  };

  const onMouseUp = (event: MouseEvent) => {
    if (trigger && trigger(event) && handlerClick) {
      handlerClick();
    } else if (handlerOther) {
      handlerOther();
    }
  };

  element.addEventListener(!isMobile ? 'mousedown' : 'touchstart', onMouseDown);
  element.addEventListener(!isMobile ? 'mouseup' : 'touchend', onMouseUp);

  return {
    removeEventListener: (): undefined => {
      element.removeEventListener(
        !isMobile ? 'mousedown' : 'touchstart',
        onMouseDown
      );
      element.removeEventListener(
        !isMobile ? 'mousedown' : 'touchend',
        onMouseUp
      );

      return undefined;
    },
  };
};

export const removeClickEventListener = (
  element: HTMLElement,
  handler: Function
) => {
  let trigger: Function;

  element.removeEventListener(
    !isMobile ? 'mousedown' : 'touchstart',
    (event: MouseEvent) => {
      trigger = clickTrigger(event);
    }
  );
  element.addEventListener(
    !isMobile ? 'mousedown' : 'touchend',
    (event: MouseEvent) => {
      if (trigger && trigger(event)) {
        handler(event);
      }
    }
  );
};

let wheelTimeoutId: any;

export const wheelStopTrigger = (): Promise<boolean> => {
  if (wheelTimeoutId) {
    clearTimeout(wheelTimeoutId);
  }
  return new Promise(resolve => {
    let wheel = false;

    if (!wheel) {
      wheel = true;

      wheelTimeoutId = setTimeout(() => {
        resolve(true);
      }, 250);
    }
  });
};

export const getPosition = (event: any, axis: 'x' | 'y' = 'x') =>
  event.touches
    ? event.touches[0][`page${axis.toUpperCase()}`]
    : event[`client${axis.toUpperCase()}`];
