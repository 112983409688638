import {h, Component} from 'preact';
import styled, {css} from 'react-emotion';
import {withContext} from '../../common/context';
import MediaSymbol from '../MediaSymbol/MediaSymbol';
import {AppContext, Transformation, LoadedAsset} from '../../typing';
import * as Between from 'between.js';
import {
  EaseType,
  MediaSymbolTypes,
  SpinAnimation,
  SpinDirection,
  TipPosition,
  ZoomTrigger,
  ZoomType,
} from '../../typing/enums';
import {FlexCenterStretched} from '../../utils/emotion';
import {getRgbaColor} from '../../utils/color';
import Placeholder from '../Placeholder/Placeholder';
import Zoom from '../Zoom/Zoom';
import {Events} from '../../utils/events';
import {getPosition} from '../../utils/mouse';
import {imageLoader} from './SpinHelpers';
import {getAdjustedDims, checkSameRatio} from '../../utils/assetHelpers';
import shallowCompare from '../../utils/shallowCompare';
import {isMobile} from 'mobile-device-detect';
import {clickTrigger} from '../../utils/mouse';

interface SpinProps {
  assets: any[];
  active: boolean;
  context: AppContext;
  width: number;
  height: number;
  breakpoint?: number;
  transformation?: Transformation;
  zoom: boolean;
  preload: boolean;
}

interface SpinState {
  ready: boolean;
  currentFrame: number;
  isSpinning: boolean;
  isOver: boolean;
  isZoom: boolean;
  zoomUrl?: string | null;
  error: boolean;
  eventPageX: number;
  eventPageY: number;
}

const $Canvas = styled('canvas')<{
  width: number;
  height: number;
  hide: boolean;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: ${props => (props.hide ? 'none' : 'block')};
`;

const $Wrap = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
`;

const loadZoomImage = (
  cld: any,
  cacher: Function,
  publicId: string,
  level: number,
  width: number,
  height: number,
  transformation: any
): Promise<LoadedAsset | void> => {
  return new Promise((resolve: any, reject: any) => {
    const zoomWidth = width * level;
    const zoomHeight = height * level;
    const url = cld.getImageUrl(
      publicId,
      zoomWidth,
      zoomHeight,
      transformation
    );

    cacher(publicId, url, zoomWidth, zoomHeight, transformation).then(
      (asset: LoadedAsset) => {
        resolve(asset);
      },
      () => {
        reject();
      }
    );
  });
};

class Spin extends Component<SpinProps, SpinState> {
  canvas: HTMLCanvasElement;
  canvasContext: any;
  triggerElement: HTMLElement;
  images: HTMLImageElement[];
  startX: 0;
  direction: number = -1;
  animateEndInstance: any;
  maxWidth: number = 0;
  cacher: Function;
  mouseClickTracker: Function;
  state = {
    ready: false,
    currentFrame: 0,
    isSpinning: false,
    isOver: false,
    isZoom: false,
    error: false,
    zoomUrl: '',
    eventPageX: 0,
    eventPageY: 0,
  };

  constructor(props: SpinProps) {
    super(props);

    this.cacher = this.props.context.cacher;

    if (this.props.active) {
      this.load(this.props.width, this.props.height, 0);
    }
  }

  shouldComponentUpdate(nextProps: SpinProps, nextState: SpinState) {
    return shallowCompare(this, nextProps, nextState);
  }

  componentWillUnmount() {
    if (this.triggerElement) {
      this.triggerElement.removeEventListener('mouseover', this.onMouseOver);
      this.triggerElement.removeEventListener('mousedown', this.onMouseDown);
      this.triggerElement.removeEventListener('mouseout', this.onMouseOut);
    }

    if (!isMobile) {
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);
      window.removeEventListener('mouseout', this.windowMouseOut);
    } else {
      window.removeEventListener('touchmove', this.onMouseMove);
      window.removeEventListener('touchend', this.onMouseUp);
      window.removeEventListener('touchcancel', this.onMouseUp);
    }
  }

  setMaxWidth = (nextWidth: number = 0) => {
    this.maxWidth = nextWidth > this.maxWidth ? nextWidth : this.maxWidth;
  };

  reset = () => {
    this.maxWidth = 0;
    this.setState(()=> ({ ready: false}));
  };

  load = (nextWidth: number, nextHeight: number, frame?: number) => {
    const _setState = () => {
      this.setState(()=> ({
          ready: true,
          ...( frame !== undefined && frame >= 0 ? {currentFrame: frame} : {}),
          zoomUrl: this.images[this.state.currentFrame].getAttribute('src')
      }));
    };

    this.setMaxWidth(nextWidth);

    imageLoader(
      this.cacher,
      this.props.assets,
      this.props.context.cloudinary,
      nextWidth,
      nextHeight,
      this.getTransformation()
    ).then(
      (images: HTMLImageElement[]) => {
        this.images = images;
        _setState();
      },
      () => {}
    );
  };

  getTransformation = (transformation?: Transformation): Transformation => {
    return {
      gravity: 'center',
      ...(transformation || this.props.transformation),
    };
  };

  componentDidUpdate(prevProps: SpinProps) {
    const {active, width, height, breakpoint} = this.props;

    const currDims = getAdjustedDims(
      prevProps.width,
      prevProps.height,
      prevProps.breakpoint
    );

    const nextDims = getAdjustedDims(width, height, breakpoint);

    const isSameAspectRatio = checkSameRatio(
      currDims.width,
      currDims.height,
      nextDims.width,
      nextDims.height
    );

    const isSameTransformation =
      prevProps.transformation === this.props.transformation;

    if (this.props.context.cacher) {
      this.cacher = this.props.context.cacher;
    }

    if (!isSameAspectRatio || !isSameTransformation) {
      this.reset();
    }

    if (active) {
      if (this.props.width > this.maxWidth) {
        this.load(width, height, active ? 0 : undefined);
      }
    }

    if (!active && this.state.isZoom) {
      this.onZoomOut();
    }

    if (this.canvas && this.state.ready) {
      if (!this.canvasContext) {
        this.canvasContext = this.canvas.getContext('2d');
        this.initMouseEvents();

        const animation = this.props.context.config.selectSpinPropsAnimate();
        if (
          animation === SpinAnimation.START ||
          animation === SpinAnimation.BOTH
        ) {
          this.animateEnd();
        }
      }

      this.renderImageIntoCanvas();
    }
  }

  renderImageIntoCanvas = () => {
    this.canvasContext.drawImage(
      this.images[this.state.currentFrame],
      0,
      0,
      this.props.width,
      this.props.height
    );
  };

  initMouseEvents = () => {
    if (!isMobile) {
      this.triggerElement.addEventListener('mousedown', this.onMouseDown);
      this.triggerElement.addEventListener('mouseover', this.onMouseOver);
      this.triggerElement.addEventListener('mouseout', this.onMouseOut);
    } else {
      this.triggerElement.addEventListener('touchstart', this.onMouseDown);
    }
  };

  windowMouseOut = (e: any): void => {
    const event = e ? e : window.event;
    const from = event.relatedTarget || event.toElement;

    if (!from || from.nodeName == 'HTML') {
      this.stop();
    }
  };

  onMouseOver = () => {
    this.setState(()=>({isOver: true}));
  };

  onMouseOut = () => {
    this.setState(()=>({isOver: false}));
    document.body.style.cursor = '';
  };

  onMouseMove = (event: any) => {
    if (!this.props.active) {
      return;
    }
    const eventPos = getPosition(event);
    const dx = eventPos - this.startX;
    const absDx = Math.abs(dx);

    if (absDx > 5) {
      this.startX = eventPos;
      this.direction = (dx / absDx) * -1;

      if (
        this.props.context.config.selectSpinPropsSpinDirection() ===
        SpinDirection.COUNTERCLOCKWISE
      ) {
        this.direction = this.direction * -1;
      }
      // in order to make swupe faste add treshhold to config - p1
      if (isMobile) {
        this.direction = this.direction < 0 ? -2 : 2;
      }

      this.changeFrame();
    }
  };

  changeFrame = () => {
    const frame = this.state.currentFrame + this.direction;
    const totalFrames = this.props.assets.length;
    const currentFrame =
      frame < 0 ? totalFrames - 1 : frame > totalFrames - 1 ? 0 : frame;

    this.setState(()=>({currentFrame}));
  };

  stop = () => {
    if (this.state.isSpinning) {
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);
      const animation = this.props.context.config.selectSpinPropsAnimate();
      if (animation === SpinAnimation.END || animation === SpinAnimation.BOTH) {
        this.animateEnd();
      }

      document.body.style.cursor = '';
      this.setState(() => ({isSpinning: false}));
    }
  };

  onMouseUp = (event: any) => {
    this.stop();
    if (
      this.mouseClickTracker &&
      this.mouseClickTracker(event) &&
      !this.props.context.config.selectSpinPropsDisableZoom()
    ) {
      this.zoomIn(event);
    }
  };

  onZoomOut = () => {
    this.stop();

    this.setState(() => ({isZoom: false, zoomUrl: undefined}));
  };

  onMouseLeave = () => {
    this.stop();
  };

  private animateEnd = (): void => {
    const currentFrame = this.state.currentFrame;
    const isRight =
      this.props.context.config.selectSpinPropsSpinDirection() ===
      SpinDirection.COUNTERCLOCKWISE;

    let start =
      this.direction < 0
        ? currentFrame
        : currentFrame + this.props.assets.length;
    let end =
      this.direction > 0
        ? currentFrame
        : currentFrame + this.props.assets.length;

    this.animateEndInstance = new Between(
      isRight ? end : start,
      isRight ? start : end
    )
      .time(this.props.context.config.selectSpinPropsAnimationDuration())
      .easing(Between.Easing[EaseType.CUBIC].Out)
      .on('update', (value: number) => {
        value = Math.ceil(value);

        const nextFrame =
          value >= this.props.assets.length
            ? value - this.props.assets.length
            : value;

        this.setState(() => ({currentFrame: nextFrame }));
      });
    this.setState(() => ({isSpinning: false }));
  };

  onDragStart = (event: any) => {
    event.preventDefault();
  };

  zoomIn = (event: any) => {
    const {context, width, height} = this.props;

    this.setState(() => ({
      isZoom: true,
      zoomUrl: this.images[this.state.currentFrame].getAttribute('src'),
      eventPageX: event.pageX,
      eventPageY: event.pageY
    }));

    loadZoomImage(
      context.cloudinary,
      context.cacher,
      this.props.assets[this.state.currentFrame].publicId,
      context.config.selectZoomPropsLevel(),
      width,
      height,
      this.getTransformation()
    ).then(
      (asset: LoadedAsset) => {
        this.setState(() => ({ zoomUrl: asset.url}));
      },
      () => {
        // if zoom failed load instead the regular url instead of showing an error
        this.setState(() => ({ zoomUrl: this.images[this.state.currentFrame].getAttribute('src')}));
      }
    );
  };

  onMouseDown = (event: any) => {
    if (!this.props.active) {
      return;
    }
    this.startX = getPosition(event);

    if (!isMobile) {
      this.triggerElement.addEventListener('mouseout', this.onMouseLeave);
      //this.triggerElement.addEventListener('mouseup', this.stopPropagation);
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
      window.addEventListener('mouseout', this.windowMouseOut);
    } else {
      window.addEventListener('touchmove', this.onMouseMove);
      window.addEventListener('touchend', this.onMouseUp);
      window.addEventListener('touchcancel', this.onMouseUp);
      this.triggerElement.addEventListener('touchend', (event: MouseEvent) => {
        event.preventDefault();
      });
      this.triggerElement.addEventListener(
        'touchcancel',
        (event: MouseEvent) => {
          event.preventDefault();
        }
      );
    }

    this.triggerElement.addEventListener('dragstart', this.onDragStart);

    if (this.animateEndInstance) {
      this.animateEndInstance.pause();
    }

    this.mouseClickTracker = clickTrigger(event);
    this.setState(() => ({ isSpinning: true}));

    document.body.style.cursor = 'ew-resize';

    this.props.context.events(
      Events.SPIN_START,
      this.props.assets[this.state.currentFrame].publicId
    );

    event.stopPropagation();
  };

  renderMediaSymbol = () => {
    const config = this.props.context.config;
    const mediaSymbolProps = {
      ...config.selectMediaSymbolProps(),
      color: config.selectTipPropsColor(),
      iconColor: config.selectTipPropsTextColor(),
      opacity: config.selectTipPropsOpacity(),
    };
    const position = config.selectSpinPropsTipPosition();

    const msgCls = css({
      backgroundColor: getRgbaColor(
        config.selectTipPropsColor(),
        config.selectTipPropsOpacity()
      ),
      color: config.selectTipPropsTextColor(),
      padding: '8px 12px',
      borderRadius: config.selectTipPropsRadius(),
      marginBottom:
        position === TipPosition.CENTER || position === TipPosition.BOTTOM
          ? '12px'
          : 0,
      marginTop: position === TipPosition.TOP ? '12px' : 0,
      fontSize: '2vh',
    });

    return (
      <FlexCenterStretched
        data-test="spin-media-icon-wrap"
        absolute
        className={css({
          flexDirection: 'column',
          opacity:
            this.state.isSpinning || this.state.isOver || this.state.isZoom
              ? 0
              : 1,
          transition: 'opacity .25s ease-in',
          justifyContent: 'center',
        })}
      >
        {position === TipPosition.CENTER ? (
          <FlexCenterStretched
            className={css({
              flexDirection: 'column',
            })}
          >
            <div className={msgCls} data-test="tip-wrap">
              {isMobile
                ? this.props.context.config.selectSpinPropsTipTouchText()
                : this.props.context.config.selectSpinPropsTipText()}
            </div>
            <MediaSymbol
              {...mediaSymbolProps}
              size={this.props.width / 6}
              type={MediaSymbolTypes.SPIN}
            />
          </FlexCenterStretched>
        ) : null}

        {position !== TipPosition.CENTER ? (
          <MediaSymbol
            {...mediaSymbolProps}
            size={this.props.width / 6}
            type={MediaSymbolTypes.SPIN}
          />
        ) : null}

        {position !== TipPosition.CENTER ? (
          <FlexCenterStretched
            absolute
            className={css({
              flexDirection: 'column',
              justifyContent:
                config.selectSpinPropsTipPosition() === TipPosition.BOTTOM
                  ? 'flex-end'
                  : config.selectSpinPropsTipPosition() === TipPosition.TOP
                  ? 'flex-start'
                  : '',
            })}
          >
            <div className={msgCls} data-test="tip-wrap">
              {isMobile
                ? this.props.context.config.selectSpinPropsTipTouchText()
                : this.props.context.config.selectSpinPropsTipText()}
            </div>
          </FlexCenterStretched>
        ) : null}
      </FlexCenterStretched>
    );
  };

  renderTrigger = () => (
    <FlexCenterStretched
      absolute
      className={css({cursor: 'ew-resize'})}
      innerRef={(el: any) => (this.triggerElement = el)}
    />
  );

  renderLoading = () => (
    <FlexCenterStretched absolute data-test="spin-loading-wrap">
      <Placeholder
        width={this.props.width}
        height={this.props.height}
        publicId={this.props.assets[0].publicId}
        mediaType={MediaSymbolTypes.IMAGE}
      />
    </FlexCenterStretched>
  );

  render(props: SpinProps) {
    const {config} = props.context;
    const isZoom = !config.selectZoomPropsDisableZoom() && config.selectZoom();
    return (
      <$Wrap data-test="spin-wrap" data-ready={this.state.ready}>
        <$Canvas
          width={props.width}
          height={props.height}
          innerRef={(el: any) => (this.canvas = el)}
          hide={!this.state.ready}
        />
        <FlexCenterStretched
          absolute
          hide={!this.state.ready}
          className={css({
            zIndex: this.state.isZoom ? 0 : 1,
          })}
        >
          {this.renderMediaSymbol()}
          {this.renderTrigger()}
        </FlexCenterStretched>
        {this.state.ready && isZoom ? (
          <FlexCenterStretched
            absolute
            data-test="spin-zoom-wrap"
            className={css({
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: this.state.isZoom ? 1 : 0,
              width: '100%',
              height: '100%',
            })}
          >
            <Zoom
              width={this.props.width}
              height={this.props.height}
              url={
                this.state.zoomUrl ||
                this.props.assets[this.state.currentFrame].url
              }
              publicId={this.props.assets[this.state.currentFrame].publicId}
              asset={this.props.assets[this.state.currentFrame]}
              type={
                isMobile && config.selectZoomPropsType() !== ZoomType.POPUP
                  ? ZoomType.INLINE
                  : config.selectZoomPropsType()
              }
              viewerPosition={config.selectZoomPropsViewerPosition()}
              viewerContainer={
                isMobile
                  ? undefined
                  : this.props.context.config.selectZoomPropsContainer()
              }
              level={this.props.context.config.selectZoomPropsLevel()}
              trigger={ZoomTrigger.CLICK}
              onZoomOut={this.onZoomOut}
              zoomIn={this.state.isZoom}
              zoomInX={this.state.isZoom ? this.state.eventPageX : undefined}
              zoomInY={this.state.isZoom ? this.state.eventPageY : undefined}
            />
          </FlexCenterStretched>
        ) : null}

        {!this.state.ready ? this.renderLoading() : null}
      </$Wrap>
    );
  }
}

export default withContext(Spin);
