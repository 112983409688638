import { h, Component } from 'preact';
import styled from 'react-emotion';
import { AppContext, ConfigSelectors, MediaAssets } from "../../typing";
import Asset from "../Asset/Asset";
import { VIEWER_DEFAULTS } from "../../config/defaults";
import { getTransformation } from "../../utils/assetHelpers";
import ScrollListItems from '../ScrollListItems/ScrollListItems';
import { withContext } from "../../common/context";

export interface ScrollViewerProps {
    width: number;
    height: number;
    index: number;
    scrollToIndex: number;
    items: MediaAssets;
    config: ConfigSelectors,
    setItem: Function,
    context: AppContext,
}

const $Wrap: any = styled('div')`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  > img {
    max-width: 100%;
    width: 100%;
  }

  border: ${(props: any) =>
    props.borderWidth + 'px solid ' + props.borderColor};
  border-radius: ${(props: any) => props.radius}px;
`;

const INTERSECT_THRESHOLD = 0.5,
    INTERSECT_MAX_MARGIN_PERC = 50;

const calcAssetDimensions = (props: ScrollViewerProps) => {
    let {width, height, context: {config}} = props,
    columns: number = config.selectDisplayPropsColumns(),
    spacing: number = config.selectDisplayPropsSpacing(),
    diviedByColumns = (value: number) => (value - (spacing * (columns - 1))) / columns;

    if (columns > 1) {
        width = diviedByColumns(width);
        height = diviedByColumns(height);
    }

    return {width, height};
};

class ScrollViewer extends Component<ScrollViewerProps> {

    renderAsset = (item: any, index: number, viewableItems: number, setItemRef: Function) => {
        const props = {...VIEWER_DEFAULTS, ...this.props},
            selectedAsset = this.props.index,
            config = props.context.config,
            inView = selectedAsset <= index && index <= selectedAsset + (viewableItems - 1),
            columns: number = config.selectDisplayPropsColumns(),
            spacing: number = config.selectDisplayPropsSpacing(),
            preloadIntersection = selectedAsset + (viewableItems * 1.5) >= index,
            {width, height} = calcAssetDimensions(props);

        return (<Asset
                    {...item}
                    setRef={setItemRef}
                    index={index}
                    mediaType={item.mediaType}
                    resourceType={item.resourceType}
                    width={width}
                    columns={columns}
                    height={height}
                    inView={inView}
                    preloadIntersection={preloadIntersection}
                    useBreakpoint={true}
                    zoom={config.selectZoom()}
                    spacing={spacing}
                    transformation={getTransformation(
                        item.transformation,
                        config
                    )}
                />);
    };

    calculateIntersectMargin = (assetHeight: number): string => {
        const winHeight = window.innerHeight;
        //make sure the margin from bottom allows for the threshold value to be achieved according to the asset height
        //ensuring that there's enough space for intersection
        const margin = Math.max(Math.max(
                winHeight * (INTERSECT_MAX_MARGIN_PERC / 100),
                winHeight - (assetHeight * (INTERSECT_THRESHOLD + 0.3))), 0),
            marginTop = this.props.context.config.selectDisplayPropsTopOffset(),
            marginBottom = margin - marginTop;

        return `-${marginTop}px 0px -${marginBottom}px 0px`;
    };

    render(propsIn: ScrollViewerProps) {
        const props = {...VIEWER_DEFAULTS, ...propsIn},
        config = props.context.config,
        columns = config.selectDisplayPropsColumns(),
        {height} = calcAssetDimensions(props);

        return <$Wrap
            data-test="scroll-viewer-wrap"
            borderWidth={config.selectBorderWidth()}
            borderColor={config.selectBorderColor()}
            radius={config.selectRadius()}>

            <ScrollListItems
                setSelected={props.setItem}
                itemHeight={height}
                scrollToIndex={props.scrollToIndex}
                items={props.items}
                renderItem={this.renderAsset}
                intersectThreshold={INTERSECT_THRESHOLD}
                intersectionMargin={this.calculateIntersectMargin(height)}
                topOffset={config.selectDisplayPropsTopOffset()}
                columns={columns}/>
        </$Wrap>;
    }
}

export default withContext(ScrollViewer);
