import { h, Component } from 'preact';
import styled from 'react-emotion';
import { withContext } from '../../common/context';
import { AppContext, Transformation } from '../../typing';
import Placeholder from '../Placeholder/Placeholder';
import { FlexCenterStretched } from '../../utils/emotion';
import { MediaSymbolTypes } from '../../typing/enums';
import Error from '../Error/Error';
import {AnyLoader, Light, PerspectiveCamera, Renderer, Scene} from 'three';
import { THREE_DRACO_URL } from "../../typing/env";

interface ThreeProps {
  publicId: string;
  active: boolean;
  context: AppContext;
  width: number;
  height: number;
  breakpoint?: number;
  transformation?: Transformation;
}

interface ThreeState {
  ready: boolean;
  error: boolean;
}

const $Wrap = styled('div') <{
  width: number;
  height: number;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: relative;
  width: 100%;
  height: 100%;
`;

class Three extends Component<ThreeProps, ThreeState> {
  wrap: HTMLElement;
  scene: Scene;
  renderer: Renderer;
  camera: PerspectiveCamera;
  loader: AnyLoader;
  controls: any;
  light: Light;
  mouseX: number;
  mouseY: number;
  requestID?: number;

  state = {
    error: false,
    ready: false,
  };

  componentWillUnmount() {
    this.stopAnimate();
  }

  shouldComponentUpdate(nextProps: ThreeProps) {
    if (nextProps.active && !this.loader) {
      this.init(this.props.width, this.props.height);
    }

    if (nextProps.active && this.loader) {
      this.animate();
    } else if (!nextProps.active && this.loader) {
      this.stopAnimate();
    }

    if (nextProps.width !== this.props.width && this.loader) {
      this.renderer.setSize(nextProps.width, nextProps.height);
    }

    return false;
  }

  stopAnimate = () => {
    if (this.requestID) {
      cancelAnimationFrame(this.requestID);
      this.requestID = undefined;
    }
  };

  getThreeUrl = () => this.props.context.cloudinary.getThreeUrl(this.props.publicId, this.props.transformation);

  init = (width: number, height: number) => {
    if (!window.THREE) {
      return;
    }

    this.loader = new window.THREE.GLTFLoader();
    window.THREE.DRACOLoader.setDecoderPath(THREE_DRACO_URL);
    // TODO looks like our definitions don't match expected three.js version, we need to investigate
    (this.loader as any).setDRACOLoader(new window.THREE.DRACOLoader());
    const camera = (this.camera = new window.THREE.PerspectiveCamera(
      45,
      width / height,
      10,
      100
    ));

    const controls = (this.controls = new window.THREE.OrbitControls(
      camera,
      this.wrap
    ));
    controls.update();
    const scene = (this.scene = new window.THREE.Scene());
    scene.background = new window.THREE.Color(
      this.props.context.config.selectBgColor()
    );

    //new THREE.Color(0xff0000');//
    const renderer = (this.renderer = new window.THREE.WebGLRenderer({
      antialias: true,
    }));

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true;
    //@ts-ignore
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.wrap.appendChild(renderer.domElement);
    const light = (this.light = new window.THREE.HemisphereLight(
      0xbbbbff,
      0x444422
    ));

    light.position.set(0, 1, 0);
    scene.add(light);

    this.loadInto();
  };

  loadInto() {
    const url = this.getThreeUrl();
    this.loader.load(
      url,
      (gltf: any) => {
        const { light, camera, scene, controls } = this;
        scene.add(gltf.scene);
        // const envMap = this.getBackgroundMap();

        // gltf.scene.traverse(function(child: any) {
        //   // if (child.isMesh) {
        //   //   if (child.material) {
        //   //     // child.material.envMap = envMap;
        //   //     //child.material.wireframe = true;
        //   //     // child.material.color.r = 0;
        //   //     // console.log(child.material);
        //   //     // child.material.opacity = 0.5;
        //   //   }
        //   // } else {
        //   //   //console.info(child);
        //   // }
        // });

        const boundingBox = new window.THREE.Box3();
        boundingBox.setFromObject(gltf.scene);
        const center = new window.THREE.Vector3();
        boundingBox.getCenter(center);
        const size = new window.THREE.Vector3();

        boundingBox.getSize(size);

        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = camera.fov * (Math.PI / 180);
        let cameraZ = Math.abs(maxDim / Math.tan(fov / 2.0));
        camera.position.z = center.z + cameraZ;
        camera.position.x = center.x;
        camera.position.y = center.y;

        light.position.set(
          camera.position.x,
          camera.position.y,
          camera.position.z
        );


        camera.near = cameraZ / 9;
        camera.far = 10000;
        camera.lookAt(center);
        camera.updateProjectionMatrix();

        this.addLightAndBox(scene, gltf.scene, camera);

        if (controls) {
          controls.target = center;
          controls.maxDistance = cameraZ * 3;
          controls.minDistance = cameraZ / 3;
          controls.saveState();
          controls.update();
        }

        camera.updateProjectionMatrix();
      },

      (xhr: any) => {
        const total = (xhr.loaded / xhr.total) * 100;

        if (total === 100 || total === Infinity) {
          this.setState(()=>({ready: true}));

          this.forceUpdate();
        }
      },
      () => {
        this.setState(()=>({error: true}));

        this.forceUpdate();
      }
    );
  }

  getMetrics = (object: any) => {
    var boundingBox = new window.THREE.Box3();
    boundingBox.setFromObject(object);
    var center = new window.THREE.Vector3();
    boundingBox.getCenter(center);
    var size = new window.THREE.Vector3();
    boundingBox.getSize(size);
    var maxDim = Math.max(size.x, size.y, size.z);
    return { maxDim, boundingBox, center };
  };

  addLightAndBox = (scene: any, object: any, camera: any) => {
    const metrics = this.getMetrics(object);

    const boxSize = Math.max(
      2 * (camera.position.z - metrics.center.z),
      metrics.maxDim * 2
    );

    for (let i = 0; i < 2; i++) {
      //@ts-ignore
      const light = new THREE.SpotLight(0xffffff, 0.5);
      var mult = i == 0 ? -1 : 1;
      light.position.set(
        metrics.center.x + (mult * boxSize) / 3,
        metrics.center.y + boxSize / 2,
        metrics.center.z + (mult * boxSize) / 3
      );
      light.target = object;
      light.shadow.mapSize.width = 512;
      light.shadow.mapSize.height = 512;
      light.castShadow = true;
      light.shadow.camera.near = 0.01;
      scene.add(light);
    }
  };


  getBackgroundMap = () => {
    const url =
      'https://res.cloudinary.com/demo/image/upload/v1547475875/3d/envmap-indoor.jpg';
    //@ts-ignore
    var textureLoader = new THREE.TextureLoader();
    const textureEquirec = textureLoader.load(url);
    //@ts-ignore
    textureEquirec.mapping = THREE.EquirectangularReflectionMapping;
    //@ts-ignore
    textureEquirec.magFilter = THREE.LinearFilter;
    //@ts-ignore
    textureEquirec.minFilter = THREE.LinearMipMapLinearFilter;
    //@ts-ignore
    textureEquirec.encoding = THREE.sRGBEncoding;
    return textureEquirec;
  };

  animate = () => {
    this.requestID = requestAnimationFrame(this.animate);

    this.renderer.render(this.scene, this.camera);
  };

  stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  render(props: ThreeProps) {
    return (
      <FlexCenterStretched relative>
        <$Wrap
          innerRef={(elem: HTMLElement) => (this.wrap = elem)}
          data-test="three-wrap"
          width={props.width}
          height={props.height}
        />
        {!this.state.error && !this.state.ready && (
          <FlexCenterStretched absolute>
            <Placeholder
              publicId={props.publicId}
              width={props.width}
              height={props.height}
              mediaType={MediaSymbolTypes.SPIN}
            />
          </FlexCenterStretched>
        )}
        {this.state.error && (
          <FlexCenterStretched absolute>
            <Error width={props.width} type={MediaSymbolTypes.THREE} />
          </FlexCenterStretched>
        )}
      </FlexCenterStretched>
    );
  }
}

export default withContext(Three);
