import { h, Component } from 'preact';
import { createPortal } from 'preact/compat';
import styled, { css } from 'react-emotion';
import { Context } from '../App/App';
import Popup from '../Popup/Popup';
import { withContext } from '../../common/context';
import { ZOOM_DEFAULTS } from '../../config/defaults';
import { AppContext, Color, MediaAsset } from '../../typing';
import {
  ZoomViewerPosition,
  ZoomTrigger,
  TipPosition,
  TipShow,
  ZoomType, DisplayMode,
} from '../../typing/enums';
import { getObjectByPrefix } from '../../utils/object';
import { getRgbaColor } from '../../utils/color';
import { Events } from '../../utils/events';
import {
  onTransitionEnd,
  setTransition,
  removeTransition,
} from '../../utils/transition';
import { isMobile } from 'mobile-device-detect';
import {
  mouseTracker,
  clickTrigger,
  getMouseX,
  getMouseY,
} from '../../utils/mouse';
import { FlexCenterStretched } from '../../utils/emotion';
import shallowCompare from '../../utils/shallowCompare';
import Asset from '../Asset/Asset';

interface ZoomProps {
  publicId: string;
  type: ZoomType;
  viewerPosition?: ZoomViewerPosition;
  viewerContainer?: string;
  viewerOffset?: number;
  showLens?: boolean;
  lensBorderColor?: Color;
  lensBorderWidth?: string;
  lensColor?: Color;
  lensOpacity?: number;
  lensShadow?: boolean;
  trigger: ZoomTrigger;
  onZoomIn?: Function;
  onZoomOut?: Function;
  width: number;
  height: number;
  level: number;
  url: string;
  context: AppContext;
  zoomIn: boolean;
  zoomInX: number;
  zoomInY: number;
  asset?: MediaAsset; // temp solution to support popup zoom in spin - need to find better solutoin
}

interface ZoomState {
  left: number;
  top: number;
  isZoomed: boolean;
}

const $Wrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
`;

const $Trigger = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  user-select: none;
  cursor: ${(props: any) => (props.zoomed ? 'zoom-out' : 'zoom-in')};
`;

const $FlyoutWrapper = styled('div')`
  position: absolute;
  background-color: ${(props: any) => props.bgColor};
  width: ${(props: any) => Number(props.width)}px;
  height: ${(props: any) => Number(props.height)}px;
  border-radius: ${(props: any) => props.radius}px;
  left: ${(props: any) => props.coords.x}px;
  top: ${(props: any) => props.coords.y}px;
  ${(props: any) => (props.zIndex ? 'z-index:' + props.zIndex : '')};
  ${(props: any) => (props.renderToContainer ? '' : 'overflow: hidden;')};
  ${(props: any) =>
    props.useOpacity ? (props.zoomed ? 'opacity: 1;' : 'opacity: 0;') : ''};
  ${(props: any) => (props.useOpacity ? 'transition: opacity 450ms;' : '')};
`;

const $ZoomImage = styled('img')`
  position: absolute;
  width: ${(props: any) =>
    props.zoomed ? props.level * props.width : props.width}px;
  height: ${(props: any) =>
    props.zoomed ? props.level * props.height : props.height}px;
  left: ${(props: any) => (!props.zoomed ? 0 : props.left)}px;
  top: ${(props: any) => (!props.zoomed ? 0 : props.top)}px;
  max-width: none !important;
  max-height: none !important;
  opacity: ${(props: any) => (props.zoomed ? 1 : 0)};
`;

const $Lens = styled('div')`
  width: ${(props: any) => Number(props.width)}px;
  height: ${(props: any) => Number(props.height)}px;
  border-radius: ${(props: any) => props.radius}px;
  ${(props: any) =>
    Number(props.top) ? 'top:' + Number(props.top) + 'px' : ''};
  ${(props: any) =>
    Number(props.left) ? 'left:' + Number(props.left) + 'px' : ''};
  position: absolute;
  opacity: ${(props: any) => (props.show ? 1 : 0)};
  background-color: ${(props: any) => getRgbaColor(props.color, props.opacity)};
  ${(props: any) =>
    props.shadow
      ? 'box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px 0 rgba(0, 0, 0, 0.2);'
      : ''};
  ${(props: any) =>
    props.borderWidth > 0
      ? 'border: ' + props.borderWidth + 'px solid ' + props.borderColor + ';'
      : ''};
`;

class Zoom extends Component<ZoomProps, ZoomState> {
  wrapper: HTMLElement;
  zoomWrapper: HTMLElement;
  trigger: HTMLElement;
  lens: HTMLElement;
  mouseClickTracker: Function;
  containerCls: string;
  mouseTracker: any;

  state = {
    left: 0,
    top: 0,
    isZoomed: false,
  };

  componentDidMount(): void {
    if (this.wrapper) {
      this.forceUpdate();
    }
  }

  componentDidUpdate(prevProps: ZoomProps) {
    if (prevProps.zoomIn !== this.props.zoomIn) {
      if (this.props.zoomIn) {
        let event = new MouseEvent('mousedown', {
          view: window,
          bubbles: true,
          cancelable: true,
        });

        this.zoomIn(event, true);
      }
    }
  }

  shouldComponentUpdate(nextProps: ZoomProps, nextState: ZoomState) {
    return shallowCompare(this, nextProps, nextState);
  }

  getCursorPos = (
    e: any,
    isCustomZoom: boolean = false
  ): { x: number; y: number } => {
    let a,
      x = 0,
      y = 0;
    e = e || window.event;
    /*get the x and y positions of the image:*/
    a = this.wrapper.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x =
      (isCustomZoom && this.props.zoomInX ? this.props.zoomInX : getMouseX(e)) -
      a.left;
    y =
      (isCustomZoom && this.props.zoomInY ? this.props.zoomInY : getMouseY(e)) -
      a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;

    return {
      x,
      y,
    };
  };

  isInline = () =>
    ZoomType.INLINE === this.props.type && !this.props.viewerContainer;

  isPopup = () =>
    ZoomType.POPUP === this.props.type && !this.props.viewerContainer;

  onMove = (e: any) => {
    if (isMobile) {
      this.moveByTouch(e);
      e.stopPropagation();
    } else if (this.isInline()) {
      this.moveByMouse(e);
    } else {
      this.moveByLens(e);
    }
  };

  moveByLens = (e: any, isCustomZoom: boolean = false) => {
    const { level } = this.props;
    let x: number, y: number;
    /*prevent any other actions that may occur when moving over the image:*/
    e.preventDefault();
    /*get the cursor's x and y positions:*/

    const pos = this.getCursorPos(e, isCustomZoom);
    const offsetShadow = this.props.context.config.selectZoomPropsLensShadow()
      ? 1
      : 0;

    /*calculate the position of the lens:*/
    x = pos.x - this.lens.offsetWidth / level;
    y = pos.y - this.lens.offsetHeight / level;

    /*prevent the lens from being positioned outside the image:*/
    if (x > this.props.width - this.lens.offsetWidth - offsetShadow) {
      x = this.props.width - this.lens.offsetWidth - offsetShadow;
    }

    if (x < 0) {
      x = offsetShadow;
    }

    if (y > this.props.height - this.lens.offsetHeight - offsetShadow) {
      y = this.props.height - this.lens.offsetHeight - offsetShadow;
    }
    if (y < 0) {
      y = offsetShadow;
    }

    /*set the position of the lens:*/
    this.lens.style.left = x + 'px';
    this.lens.style.top = y + 'px';
    /*display what the lens "sees":*/

    // need to fix calulation on mobile to start where touch started on screen - fix for release to start in center
    this.setState(() => ({
      left: isMobile ? this.props.width * (level - 1) * -1 : x * level * -1,
      top: isMobile ? this.props.height * (level - 1) * -1 : y * level * -1,
    }));
  };

  moveByTouch = (e: any) => {
    e.preventDefault();
    const level = this.props.level - 1;

    if (this.mouseTracker) {
      const tracker = this.mouseTracker(e);
      let x = this.state.left * -1 + tracker.moveByX * -1;
      let y = this.state.top * -1 + tracker.moveByY * level * -1;

      if (x > this.props.width * level) {
        x = x > this.props.width ? this.props.width * level : x;
      }

      if (x < 0) {
        x = 0;
      }

      if (y > this.props.height * level) {
        y = this.props.height * level;
      }

      if (y < 0) {
        y = 0;
      }

      this.setState(() => ({
        left: x * -1,
        top: y * -1,
      }));
    }
  };

  moveByMouse = (e: any) => {
    e.preventDefault();

    const mousePos = this.getCursorPos(e);

    this.setState(() => ({
      left: mousePos.x * -(this.props.level - 1),
      top: mousePos.y * -(this.props.level - 1),
    }));
  };

  onMouseDown = (event: any) => {
    this.mouseClickTracker = clickTrigger(event);
    this.mouseTracker = mouseTracker(event);
  };

  onMouseUp = (event: any) => {
    if (this.mouseClickTracker && this.mouseClickTracker(event)) {
      if (this.state.isZoomed) {
        this.zoomOut();
      } else {
        this.zoomIn(event);
      }

      if (isMobile) {
        event.stopPropagation();
      }
    }
  };

  mouseOver = (event: any) => {
    if (this.props.trigger === ZoomTrigger.HOVER) {
      this.zoomIn(event);
    }
  };

  mouseOut = (e: any) => {
    if (
      e.offsetX >= this.props.width ||
      e.offsetX < 0 ||
      e.offsetY >= this.props.height ||
      e.offsetY < 0
    ) {
      this.zoomOut();
    }
  };

  touchStart = (event: MouseEvent) => {
    this.onMouseDown(event);
    if (this.state.isZoomed) {
      event.stopPropagation();
    }
  };

  touchEnd = (event: MouseEvent) => {
    this.onMouseUp(event);
    if (this.state.isZoomed) {
      event.stopPropagation();
    }
  };

  getWrapperCoords = (): { x: number; y: number } => {
    if (this.wrapper && !this.props.viewerContainer) {
      const { height, width, context } = this.props;
      const position = this.props.viewerPosition;
      const viewerOffset = context.config.selectZoomPropsViewerOffset();
      const rect: any = this.wrapper.getBoundingClientRect();
      const scrollLeft: number = window.pageXOffset;
      const scrollTop: number = window.pageYOffset;
      const displayMode = context.config.selectDisplayPropsMode();
      const displaySpacing = context.config.selectDisplayPropsSpacing();
      const displayColumns = context.config.selectDisplayPropsColumns();
      const isMultiColumns = displayMode === DisplayMode.EXPANDED &&  displayColumns > 1;
      const offset = isMultiColumns ? displaySpacing : viewerOffset;

      let top: number;

      if (this.isInline()) {
        top = 0;
      } else if (position === ZoomViewerPosition.BOTTOM) {
        top = scrollTop + rect.top + height + offset;
      } else if (position === ZoomViewerPosition.TOP) {
        top = scrollTop + rect.top - height - offset;
      } else {
        top = rect.top + scrollTop;
      }

      let left: number;

      if (this.isInline()) {
        left = 0;
      } else if (position === ZoomViewerPosition.RIGHT) {
        left = scrollLeft + rect.right + offset;
      } else if (position === ZoomViewerPosition.LEFT) {
        left = scrollLeft + rect.left - width - offset;
      } else {
        left = rect.left + scrollLeft;
      }

      return { y: top, x: left };
    }

    return { y: 0, x: 0 };
  };

  zoomIn = (event: any, isCustomZoom: boolean = false) => {
    if (!this.isPopup()) {
      this.setTransition();
    }

    this.setState(() => ({
      isZoomed: true,
    }));

    if (!this.isPopup()) {
      this.moveByLens(event, isCustomZoom);
    }

    this.props.context.events(Events.ZOOM_IN, this.props.publicId);

    this.props.onZoomIn && this.props.onZoomIn();

  };

  zoomOut = () => {
    if (!this.isPopup()) {
      this.setTransition();
    }

    this.setState(() => ({
      isZoomed: false,
    }));

    this.props.context.events(Events.ZOOM_OUT, this.props.publicId);

  };

  setTransition = () => {
    const duration = 150;
    if (this.zoomWrapper && this.isInline() && !isMobile) {
      setTransition(this.zoomWrapper, `all ${duration}ms`);

      const transitionEndCb = () => {
        if (!this.state.isZoomed && this.props.onZoomOut) {
          this.props.onZoomOut();
        }

        removeTransition(this.zoomWrapper);
      };

      onTransitionEnd(this.zoomWrapper, transitionEndCb, duration);
    }
  };

  renderInfo = () => {
    const config = this.props.context.config;
    const position = config.selectZoomPropsTipPosition();
    const msgCls = css({
      backgroundColor: getRgbaColor(
        config.selectTipPropsColor(),
        config.selectTipPropsOpacity()
      ),
      color: config.selectTipPropsTextColor(),
      padding: '8px 12px',
      borderRadius: config.selectTipPropsRadius(),
      marginBottom:
        position === TipPosition.CENTER || position === TipPosition.BOTTOM
          ? '12px'
          : 0,
      marginTop: position === TipPosition.TOP ? '12px' : 0,
      fontSize: '2vh',
    });

    const showTipConfig = config.selectZoomPropsShowTip();

    const isRender =
      !this.state.isZoomed &&
        (showTipConfig === TipShow.ALL ||
          (isMobile && showTipConfig === TipShow.TOUCH) ||
          (!isMobile && showTipConfig === TipShow.DESKTOP))
        ? true
        : false;

    return isRender ? (
      <FlexCenterStretched
        data-test="zoom-media-icon-wrap"
        absolute
        className={css({
          flexDirection: 'column',
          opacity: this.state.isZoomed ? 0 : 1,
          transition: 'opacity .25s ease-in',
          justifyContent:
            config.selectZoomPropsTipPosition() === TipPosition.BOTTOM
              ? 'flex-end'
              : config.selectZoomPropsTipPosition() === TipPosition.TOP
                ? 'flex-start'
                : 'center',
        })}
      >
        <div className={msgCls} data-test="tip-wrap">
          {isMobile
            ? config.selectZoomPropsTipTouchText()
            : config.selectZoomPropsTipText()}
        </div>
      </FlexCenterStretched>
    ) : null;
  };

  render(propsIn: ZoomProps, state: ZoomState) {
    return (
      <Context.Consumer>
        {({ config }: AppContext) => {
          const props = { ...ZOOM_DEFAULTS, ...propsIn };
          const lensWidth = props.width / props.level;
          const lensHeight = props.height / props.level;
          const position = config.selectZoomPropsViewerPosition();
          const lensProps = getObjectByPrefix('lens', config.selectZoomProps());
          const zoomContainer = props.viewerContainer;

          if (zoomContainer) {
            this.setContainerStyles();
          }

          return (
            <$Wrapper
              innerRef={(elem: HTMLElement) => (this.wrapper = elem)}
              data-test="zoom-wrap"
            >
              {this.wrapper && this.isInline() && !this.isPopup() ? (
                <$FlyoutWrapper
                  width={props.width}
                  height={props.height}
                  radius={config.selectZoomPropsViewerRadius()}
                  url={props.url}
                  left={state.left}
                  top={state.top}
                  zoomed={state.isZoomed}
                  coords={
                    state.isZoomed ? this.getWrapperCoords() : { x: 0, y: 0 }
                  }
                  position={position}
                  spacing={config.selectZoomPropsViewerOffset()}
                  level={props.level}
                  useOpacity={false}
                  data-test="zoom-flyout-wrap"
                >
                  <$ZoomImage
                    itemprop="image"
                    src={props.url}
                    width={props.width}
                    height={props.height}
                    url={props.url}
                    left={state.left}
                    top={state.top}
                    zoomed={state.isZoomed}
                    level={props.level}
                    innerRef={(elem: HTMLElement) => (this.zoomWrapper = elem)}
                  />
                </$FlyoutWrapper>
              ) : null}
              {this.wrapper &&
                !this.isPopup() &&
                (!this.isInline() || zoomContainer) ? (
                  createPortal(

                    <$FlyoutWrapper
                      zIndex={config.selectZoomPropsViewerZIndex()}
                      width={props.width}
                      height={props.height}
                      radius={config.selectZoomPropsViewerRadius()}
                      url={props.url}
                      left={state.left}
                      top={state.top}
                      coords={
                        state.isZoomed
                          ? this.getWrapperCoords()
                          : { x: -1000000, y: -1000000 }
                      }
                      position={position}
                      spacing={config.selectZoomPropsViewerOffset()}
                      level={props.level}
                      renderToContainer={zoomContainer}
                      zoomed={state.isZoomed}
                      useOpacity={true}
                      data-test="zoom-flyout-wrap"
                    >
                      <$ZoomImage
                        itemprop="image"
                        src={props.url}
                        width={props.width}
                        height={props.height}
                        url={props.url}
                        left={state.left}
                        top={state.top}
                        zoomed={state.isZoomed}
                        level={props.level}
                      />
                    </$FlyoutWrapper>
                  , zoomContainer && document.querySelector(zoomContainer) || document.body)
                ) : null}
              {!this.isPopup() ? (
                <$Lens
                  show={
                    state.isZoomed &&
                    !this.isInline() &&
                    config.selectZoomPropsShowLens()
                  }
                  width={lensWidth}
                  height={lensHeight}
                  radius={config.selectZoomPropsLensRadius()}
                  innerRef={(elem: HTMLElement) => (this.lens = elem)}
                  {...lensProps}
                  data-test="zoom-lens"
                />
              ) : null}
              {this.renderInfo()}
              {this.isPopup() && this.state.isZoomed ? (
                <Popup
                  onClose={this.zoomOut}
                  {...config.selectZoomPopupProps()}
                  render={(width: number, height: number) => {
                    const asset =
                      props.asset ||
                      config.selectMediaAssets().filter((asset: MediaAsset) => {
                        return props.publicId === asset.publicId;
                      })[0];

                    return (
                      <Asset
                        {...asset}
                        width={width}
                        height={height}
                        useBreakpoint={false}
                        inView={true}
                        zoom={false}
                        transformation={
                          asset.transformation || config.selectTransformation()
                        }
                      />
                    );
                  }}
                />
              ) : null}
              <$Trigger
                data-test="zoom-trigger"
                innerRef={(elem: HTMLElement) => (this.trigger = elem)}
                zoomed={state.isZoomed}
                onMouseDown={
                  !isMobile && props.trigger === ZoomTrigger.CLICK
                    ? this.onMouseDown
                    : undefined
                }
                onMouseUp={
                  !isMobile && props.trigger === ZoomTrigger.CLICK
                    ? this.onMouseUp
                    : undefined
                }
                onMouseMove={
                  (!isMobile && !this.isPopup() && state.isZoomed) ||
                    state.isZoomed
                    ? this.onMove
                    : undefined
                }
                onMouseOut={
                  !isMobile && this.state.isZoomed && !this.isPopup()
                    ? this.mouseOut
                    : undefined
                }
                onMouseOver={
                  !isMobile && !this.isPopup() ? this.mouseOver : undefined
                }
                //@ts-ignore
                onTouchStart={isMobile ? this.touchStart : undefined}
                //@ts-ignore
                onTouchMove={
                  isMobile && state.isZoomed && !this.isPopup()
                    ? this.onMove
                    : undefined
                }
                //@ts-ignore
                onTouchEnd={isMobile ? this.touchEnd : undefined}
                //@ts-ignore
                onTouchCancel={isMobile ? this.touchEnd : undefined}
              />
            </$Wrapper>
          );
        }}
      </Context.Consumer>
    );
  }

  setContainerStyles = () => {
    if (this.props.viewerContainer) {
      const elem: HTMLElement | null = document.querySelector(
        this.props.viewerContainer
      );

      if (elem) {
        if (!this.state.isZoomed) {
          elem.classList.remove(this.containerCls);
        } else {
          const cls = css`
            overflow: hidden;
            width: ${this.props.width}px;
            height: ${this.props.height}px;
            ${!elem.style.position ? 'position: relative;' : ''};
          `;
          elem.classList.add((this.containerCls = cls));
        }
      }
    }
  };
}

export default withContext(Zoom);
