type ScrollToOptions = {offset?: number};

const findElementOffset = (elm: HTMLElement): number => {
    let offset = 0;
    let offsetParent : HTMLElement | null = elm;

    while (offsetParent) {
        offset += offsetParent.offsetTop;
        offsetParent = offsetParent.offsetParent as HTMLElement;
    }

    return offset;
};

const scrollTo = (selector: string | HTMLElement, options: ScrollToOptions = {}) => {
    const element = typeof selector === 'string' ?
        document.querySelector(selector) as HTMLElement :
        selector;

    if (element) {
        const elmOffset = findElementOffset(element);

        window.scrollTo({
            top: elmOffset + (options.offset ? options.offset : 0),
            behavior: "smooth",
        });
    }
};

export default scrollTo;