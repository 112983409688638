const isTransitionEnded = () => {
  const isExplorer11 = ~window.navigator.userAgent.indexOf('Trident/');

  if (isExplorer11) {
    return ;
  }

  const el: HTMLElement = document.createElement('fakeelement');

  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  for (const t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
};

export const onTransitionEnd = (el: HTMLElement, onEnded: Function, timeoutInMs?: number) => {
  const type = isTransitionEnded();

  if (type) {
    el.addEventListener(type, () => {
            onEnded();
        },
        {once: true}
    );
  } else {
    timeoutInMs && setTimeout(onEnded, timeoutInMs);
  }
};

const prefix = (() => {
  //@ts-ignore
  const styles: any = window.getComputedStyle(document.documentElement, ''),
    pre = (Array.prototype.slice
      .call(styles)
      .join('')
      .match(/-(moz|webkit|ms)-/) ||
      (styles.OLink === '' && ['', 'o']))[1],
    //@ts-ignore
    dom = 'WebKit|Moz|MS|O'.match(new RegExp('(' + pre + ')', 'i'))[1];
  return {
    dom: dom,
    lowercase: pre,
    css: '-' + pre + '-',
    js: pre[0].toUpperCase() + pre.substr(1),
  };
})();

export const setTransition = (el: HTMLElement, transition: string): void => {
  el.style[`${prefix.js}Transition`] = transition;
};

export const removeTransition = (el: HTMLElement): void => {
  setTransition(el, 'none');
};
