import {Component, h,} from 'preact';
import {addOne as stickyFillAdd} from 'stickyfilljs';
import styled from 'react-emotion';
import {isMobile} from "mobile-device-detect";
import {AppContext, Dimensions, MediaAsset,} from '../../typing';
import {Axis, CarouselStyle, DisplayMode} from '../../typing/enums';
import Thumbnails from '../Thumbnails/Thumbnails';
import Indicator from '../Indicator/Indicator';
import {getSizeByAxisAndDisplayMode} from "../../utils/wrapperHelpers";
import {withContext} from "../../common/context";

type AssetsNavigatorProps = {
    index: number,
    items: Array<MediaAsset>,
    selectItem: Function,
    axis: Axis,
    viewerDims: Dimensions,
    width: number,
    mode: DisplayMode,
    sticky: boolean,
    topOffset: number,
    bottomOffset: number,
    context: AppContext,
};

type AssetsNavigatorState = {
    switchStickyPosition: boolean,
}

type WrapperProps = {
    axis: Axis;
    wrapWidth: number;
    wrapHeight: number;
    mode: DisplayMode,
    sticky: boolean,
    topOffset: number,
    bottomOffset: number,
    switchPosition: boolean,
};

const getStickyPosition = ({mode, sticky, topOffset, bottomOffset, switchPosition = false, wrapHeight}: WrapperProps) => {
    let stickyPosition = 'top',
        offsetValue = topOffset,
        alignPosition = sticky ? 'flex-start' : 'flex-end';

    if (switchPosition) {
        offsetValue = sticky ? bottomOffset : topOffset;
        stickyPosition = sticky ? 'bottom' : 'top';
        alignPosition = sticky ? 'flex-end' : 'flex-start';
    }

    return (!isMobile && mode === DisplayMode.EXPANDED && sticky) ?
        `position: sticky;
        align-self: ${alignPosition};
        ${stickyPosition}: ${offsetValue}px;` : '';
};


const $Wrapper = styled('div') <WrapperProps>`
  display: flex;
  align-items: start;
  justify-content: center;
  transition: .5s ease-in-out;
    
  ${(props) => getStickyPosition(props)}
  
  ${getSizeByAxisAndDisplayMode}  
`;

class AssetsNavigator extends Component<AssetsNavigatorProps, AssetsNavigatorState> {

    private wrapperRef: HTMLElement | null = null;
    private ignoreSelection: boolean = false;
    private clickedItem: number | null = null;


    constructor(props: AssetsNavigatorProps) {
        super(props);
        this.handleStickyPosition = this.handleStickyPosition.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleThumbnailSelectionForScroll = this.handleThumbnailSelectionForScroll.bind(this);
    }

    componentDidMount(): void {
        if (this.wrapperRef) {
            stickyFillAdd(this.wrapperRef);
        }

        window.addEventListener('scroll', this.handleStickyPosition);
    }

    componentDidUpdate(): void {
        if (this.props.mode === DisplayMode.EXPANDED) {
            this.handleThumbnailSelectionForScroll();
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.handleStickyPosition)
    }

    handleThumbnailSelectionForScroll() {
         if(this.clickedItem !== this.props.index) {
             this.ignoreSelection = false;
         }
    }

    handleStickyPosition() {
        if (!this.wrapperRef) {
            return;
        }

        const wrapperHeight = this.wrapperRef.clientHeight,
            windowHeight = window.innerHeight,
            topOffset = this.props.topOffset || 0,
            switchStickyPosition = (wrapperHeight > (windowHeight - topOffset));

        this.setState(() => ({switchStickyPosition}));
    }

    handleOnClick(index: number) {
        // Don't ignore user click when in "classic" mode
        // OR when in "expanded" mode and -
        //      Click on the same thumbnail (diff is 0)
        //      Click on the next / prev thumbnail (diff is 1)
        if (this.props.mode === DisplayMode.EXPANDED && Math.abs(this.props.index - index) > 1) {
            this.ignoreSelection = true;
        }

        this.clickedItem = index;
        this.props.selectItem(index);
    }

    render(props: AssetsNavigatorProps, state: AssetsNavigatorState) {
        const { config } = this.props.context;

        return (
            <$Wrapper
                innerRef={(elm: HTMLElement) => {
                    this.wrapperRef = (this.props.mode === DisplayMode.EXPANDED) ? elm : null;
                }}
                mode={this.props.mode}
                sticky={this.props.sticky}
                topOffset={this.props.topOffset}
                bottomOffset={this.props.bottomOffset}
                axis={props.axis}
                wrapWidth={props.width}
                wrapHeight={props.viewerDims.height}
                switchPosition={this.state.switchStickyPosition}
                data-test="gallery-thumbnail-wrap">

                {config.selectCarouselStyle() === CarouselStyle.THUMBNAILS ? (
                    <Thumbnails
                        index={props.index}
                        selectedIndex={!this.ignoreSelection && this.props.index}
                        onItemSelect={this.handleOnClick}
                    />
                ) : null}

                {config.selectCarouselStyle() === CarouselStyle.INDICATORS ? (
                    <Indicator
                        wrapWidth={props.width}
                        wrapHeight={props.viewerDims.height}
                        {...config.selectIndicatorProps()}
                        selected={!this.ignoreSelection && this.props.index}
                        amount={config.selectMediaAssets().length}
                        onItemSelect={this.handleOnClick}
                        axis={props.axis}
                        mode={this.props.mode}/>
                ) : null}
            </$Wrapper>
        );
    }
}

export default withContext(AssetsNavigator);



