import { h, render } from 'preact';
import produce from 'immer';
import merge from 'deepmerge';
import { THREE_URL, USE_ANALYTICS, ANALYTICS_URL, VERSION } from "./typing/env";
import { CONFIG_DEFAULTS } from './config/defaults';
import {
  GalleryWidget as TypeGalleryWidget,
  ConfigProps,
  CloudinaryCore,
  MediaAsset,
} from './typing';
import * as enums from './typing/enums';
import { sortByKey } from './utils/sort';
import { cacher } from './utils/cacher';
import { getElement, getWidth } from './utils/dom';
import { flatten } from './utils/json';
import { isFunction } from './utils/function';
import { Events } from './utils/events';
import { initCloudinaryCore } from './utils/cloudinary';
import mediaQuery from './utils/mediaQuery';
import jsloader from './utils/loader';
import MouseEventPolyfill from './utils/mouseEventPolyfill';
import validate from './common/validators';
import App from './components/App/App';
import NotFound from './components/NotFound/NotFound';
import skins from './assets/skins';
import {
  prepareAssetFromTag,
  prepareByPublicId,
  prepareImageFromPublicIdString,
} from "./utils/prepareMediaAssets";

type Options = { [propName: string]: any };

// for IE 11
MouseEventPolyfill();

export const prepareMediaAssets = async (
  options: Options,
  sort: enums.Sort,
  cloudinary: any
): Promise<any[]> => {
  let mediaAssets: any[] = [];
  let loadExternal3dBundle = false;

  for (const asset of options.mediaAssets) {
    if (typeof asset === 'string') {
      const imageFromPublicId = prepareImageFromPublicIdString(asset);
      mediaAssets.push(imageFromPublicId);
    } else if (asset.tag) {
      const {assets, load3dBundle} = await prepareAssetFromTag(asset, cloudinary);

      assets.length && mediaAssets.push(...assets);
      loadExternal3dBundle = loadExternal3dBundle || load3dBundle;

    } else {
      const serializedAsset  = prepareByPublicId(asset);

      if (serializedAsset.mediaType === enums.MediaSymbolTypes.THREE) {
        loadExternal3dBundle = true;
      }

      mediaAssets.push(serializedAsset);
    }
  }

  if (!window.THREE && loadExternal3dBundle) {
    await jsloader(THREE_URL || '');
  }

  mediaAssets =
    sort === enums.Sort.NONE
      ? mediaAssets
      : mediaAssets.sort(
        sortByKey(`${sort === enums.Sort.DESC ? '-' : ''}publicId`)
      );

  return mediaAssets;
};

const prepareConfig = async (
  options: Options = {},
  config: any,
  cloudinary: CloudinaryCore
): Promise<ConfigProps> => {
  config = merge(
    config,
    options.skin ? merge(skins[options.skin], options) : options
  );

  if (options.preload) {
    config.preload = options.preload;
  }

  let mediaAssets: MediaAsset[] = [];

  if (options.mediaAssets) {
    mediaAssets = await prepareMediaAssets(
        options,
        options.sort !== undefined ? options.sort : config.sort,
        cloudinary
    );
  }

  if (options.skin) {
    if (skins[options.skin].viewportBreakpoints) {
      config.viewportBreakpoints = skins[options.skin].viewportBreakpoints;
    }
  }

  if (options.viewportBreakpoints) {
    config.viewportBreakpoints = options.viewportBreakpoints;
  }

  return produce(config, draft => {
    if (options.mediaAssets) {
      draft.mediaAssets = mediaAssets;
    }
  });
};

class GalleryWidget {
  constructor(options: Options) {
    this.options = options;
    // TODO: need to find a way to merge only once
    this.cloudinaryCore = initCloudinaryCore(
      merge(CONFIG_DEFAULTS, this.options)
    );

    this.cacher = cacher();

    this.sendAnalytics(options);
  }

  private element: any;
  public config: any = CONFIG_DEFAULTS;
  private options: any;
  private cloudinaryCore: CloudinaryCore;
  private mediaQueryManager: any;
  private events: { [key: string]: Function[] } = {};
  private cacher: Function;

  private sendAnalytics(options: any): void {
    if (USE_ANALYTICS) {
      const name = 'gallery_widget_init';
      const qs = flatten(
        produce(options, draft => {
          delete draft.mediaAssets;
          delete draft.container;
        })
      );

      fetch(`${ANALYTICS_URL}/${name}?${qs}`);
    }
  }

  private prepareElement(): Promise<boolean> {
    this.setElement();

    return new Promise(resolve => {
      if (this.element && getWidth(this.element) > 0) {
        resolve(true);
      } else {
        setTimeout(() => {
          if (this.element && getWidth(this.element) > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, 250);
      }
    });
  }

  private setElement = () => {
    this.element = getElement(this.config.container);
  };

  private registerEvent = (eventName: string, callback: Function) => {
    if (!this.events[eventName]) {
      this.events[eventName] = [callback];
    } else {
      this.events[eventName].push(callback);
    }
  };

  public async render(options?: any, isNewMediaAssets: boolean = false): Promise<{ ok: boolean }> {
    this.config = await prepareConfig(
      options || this.options,
      this.config,
      this.cloudinaryCore
    );

    const isValidElement: boolean = await this.prepareElement();

    if (!isValidElement) {
      throw new Error(
        'Element is probably not rendered to the DOM - something with your layout or CSS definition'
      );
    }

    if (this.mediaQueryManager) {
      this.mediaQueryManager.destroy();
    }

    if (!this.config.mediaAssets.length) {
      render(
        <NotFound msg="No media assets available for preview" />,
        this.element
      );
    } else {
      if (this.config.viewportBreakpoints.length) {
        this.mediaQueryManager = new mediaQuery(
          this.config.viewportBreakpoints,
          (breakpointConfig: ConfigProps): void => {
            // check if element exist - since in some cases if element is not exist media wueries are still running and can stuck thr page - happend when not using correctly  - its a sfaety function - need to check perfpormance
            const isElement = document.body.contains(this.element);

            if (isElement) {
              render(
                <App
                  {...merge(this.config, breakpointConfig)}
                  cloudinaryCore={this.cloudinaryCore}
                  events={this.events}
                  cacher={this.cacher}
                  isNewMediaAssets={isNewMediaAssets}
                />,
                this.element);
            } else {
              if (this.mediaQueryManager) {
                this.mediaQueryManager.destroy();
              }
            }
          }
        );
      } else {
        render(
          <App
            {...this.config}
            cloudinaryCore={this.cloudinaryCore}
            events={this.events}
            cacher={this.cacher}
            isNewMediaAssets
          />,
          this.element);
      }
    }

    return { ok: true };
  }

  public async update(options: Options = {}): Promise<{ ok: boolean }> {
    // checking if cloudName exist - to prevent update befopre first render - since missing all props
    if (!this.config.cloudName) {
      console.warn(
        'Product Gallery:: missing cloudName, are you calling the update method before calling the render method?'
      );
      return { ok: false };
    }


    if (validate(options)) {
      return this.render(options, options.mediaAssets ? true : false);
    }

    return { ok: true };
  }

  public focus() {
    this.element.focus();
  }

  public hide() {
    this.element.style.display = 'none';
  }

  public destroy() {
    if (this.element instanceof HTMLElement) {
      render(null, this.element);
    }

    if (this.mediaQueryManager) {
      this.mediaQueryManager.destroy();
    }
  }

  public on(eventName: string, callback: Function) {
    if (
      isFunction(callback) &&
      Object.values(Events).filter(event => {
        return event.name === eventName;
      }).length === 1
    ) {
      this.registerEvent(eventName, callback);
    }
  }
}

((win: Window): void => {
  const cloudinary: TypeGalleryWidget = {
    ...(win.cloudinary || {}),
    galleryWidget: (options: Options): any => {
      if (validate(options)) {
        return new GalleryWidget(options);
      }
    },
    galleryWidgetInfo: {
      VERSION: VERSION,
      ENUMS: enums,
      DEFAULTS: CONFIG_DEFAULTS,
    }
  };

  win.cloudinary = cloudinary;
})(self);