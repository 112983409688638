import {h, Component} from 'preact';
import styled, {css} from 'react-emotion';
import {withContext} from '../../common/context';
import {FlexCenterStretched} from '../../utils/emotion';
import MediaSymbol from '../MediaSymbol/MediaSymbol';
import Error from '../Error/Error';
import Placeholder from '../Placeholder/Placeholder';
import {AppContext, Transformation} from '../../typing';
import {Events} from '../../utils/events';
import shallowCompare from '../../utils/shallowCompare';
import {isMobile, isIOS} from 'mobile-device-detect';
import {clickEventListener} from '../../utils/mouse';
import {Controls, MediaSymbolTypes, DisplayMode} from '../../typing/enums';
import {checkSameRatio} from '../../utils/assetHelpers';

//https://www.w3.org/2010/05/video/mediaevents.html
interface VideoProps {
  publicId: string;
  isFirst: boolean;
  width: number;
  height: number;
  active: boolean;
  context: AppContext;
  transformation?: Transformation;
  preload: boolean;
}

interface VideoState {
  ready: boolean;
  isPlay: boolean;
  url: string[];
  isOver: boolean;
  error: boolean;
  key: string;
}

//@ts-ignore
const $Video = styled('video', {shouldForwardProp: prop => prop})`
  pointer-events: ${(props: any) => props.controls ? 'all' : 'none'};
`;

const progressEvent = (): Function => {
  let counter = 0;

  return (progress: number): boolean => {
    if (progress > counter) {
      counter++;
      return true;
    } else {
      return false;
    }
  };
};

class Video extends Component<VideoProps, VideoState> {
  video: any;
  timeoutId: any;
  progressEvent: Function;
  mouseClickTracker: any;
  trigger: HTMLElement;
  errorCounter: number = 0;

  componentWillMount() {
    // hack - apperntly video tag is not removed from the dom on unmount, as a solution need to remove element from the dom in order for video to render again with the new sources
    // happens when trnansformation change or aspect ratio
    // need to find better solution - seems like preact bug
    const elem = this.props.context.config
      .selectContainer()
      .querySelector('video');
    if (elem) {
      elem.remove();
    }
  }

  componentDidMount() {
    if (this.props.active) {
      this.init();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);

    if (this.mouseClickTracker) {
      this.mouseClickTracker = this.mouseClickTracker.removeEventListener();
    }

    window.removeEventListener('keyup', this.onKeyUp);

    //debugger;

    // console.log(
    //   this.props.context.config.selectContainer().querySelector('video')
    // );

    // const elem = this.props.context.config
    //   .selectContainer()
    //   .querySelector('video');
    // if (elem) {
    //   elem.remove();
    // }
  }

  shouldComponentUpdate(nextProps: VideoProps, nextState: VideoState) {
    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate(prevProps: VideoProps, prevState: VideoState) {
    const clickCaptureLayer = this.isNativeControls() ? this.video : this.trigger;
    if (clickCaptureLayer && !this.mouseClickTracker) {
      this.mouseClickTracker = clickEventListener(clickCaptureLayer, this.toggle);
    }

    // if (this.props.publicId === 'pixels') {
    //   debugger;
    // }

    if (this.video && this.state.ready) {
      const isSameAspectRatio = checkSameRatio(
        this.props.width,
        this.props.height,
        prevProps.width,
        prevProps.height
      );

      const isSameTransformation =
        prevProps.transformation === this.props.transformation;

      if (!prevState.ready && (this.props.active && this.isAutoPlay())) {
        this.play();
      } else if (!this.props.active) {
        this.stop();
      } else if (
        isSameAspectRatio &&
        this.props.active !== prevProps.active &&
        this.isAutoPlay()
      ) {
        this.play();
      } else if (
        (!this.props.active &&
          (this.props.width > prevProps.width ||
            this.props.height > prevProps.height)) ||
        !isSameAspectRatio ||
        !isSameTransformation
      ) {
        this.setState(() => ({
          ready: isSameAspectRatio || isSameTransformation ? true : false,
        }));

        this.init();
      }
    } else if (this.props.active && !this.state.url) {
      this.init();
    }
  }

  controlsType = () => this.props.context.config.selectVideoPropsControls() + '';
  isMinimalControls = () => this.controlsType() === Controls.DEPRECATED_CONTROLS_ON || this.controlsType() === Controls.PLAY;
  isNativeControls = () => this.controlsType() === Controls.ALL;
  isControls = () => this.isMinimalControls() || this.isNativeControls();
  isAutoPlay = () => this.props.context.config.selectVideoPropsAutoplay() || !this.isControls();
  isLoop = () => this.props.context.config.selectVideoPropsLoop();
  playSound = () => this.props.context.config.selectVideoPropsSound();

  init = () => {
    const {width, height, transformation} = this.props;
    const url = this.props.context.cloudinary.getVideoUrl(
      this.props.publicId,
      width,
      height,
      transformation
    );

    this.setState(() => ({
      url: url,
      key: url[0],
    }));

    window.removeEventListener('keyup', this.onKeyUp);

    if (isMobile) {
      this.video.setAttribute('playsinline', '');
    }

    if (this.isControls()) {
      window.addEventListener('keyup', this.onKeyUp);
    }
  };

  onKeyUp = (event: any) => {
    if (event.keyCode === 32) {
      if (this.state.ready && this.props.active) {
        this.toggle();
      }
    }
  };

  onEnded = () => {
    this.progressEvent = progressEvent();

    this.setState(() => ({
      isPlay: false,
    }));
  };

  onTimeUpdate = () => {
    if (this.video) {
      const progress =
        Math.floor(
          ((this.video.currentTime / this.video.duration) * 100) / 25
        ) || 0;

      if (progress === 0 || !this.progressEvent) {
        this.progressEvent = progressEvent();
      }

      if (this.progressEvent(progress) && progress < 4) {
        this.props.context.events(Events.VIDEO_PROGRESS, this.props.publicId, {
          progress: progress * 25,
        });
      }
    }
  };

  onError = () => {
    this.errorCounter = this.errorCounter + 1;

    if (this.errorCounter === this.state.url.length) {
      this.setState(() => ({
        error: true,
      }));
    }

    // myHeaders.append('Content-Type', 'text/xml'); - when resolved add code below
    // var headers = new Headers();
    // headers.append('x-cld-error', 'true');

    // fetch(this.state.url[0], {
    //   method: 'get',
    //   headers,
    // }).then(response => {
    //   if (!response.ok) {
    //   }
    // });
  };

  onCanPlay = () => {
    this.setState(() => ({
      ready: true,
    }));
    //this.video.removeEventListener('canplay', this.onCanPlay);
  };

  renderMediaSymbol = () => {
    const mediaSymbolProps = this.props.context.config.selectMediaSymbolProps();
    const {isOver, isPlay} = this.state;
    const isShow = !isPlay || (isPlay && isOver);
    return (
      <FlexCenterStretched
        absolute
        data-test="video-media-icon-wrap"
        className={css({
          opacity: isShow ? 1 : 0,
          transition: 'opacity .4s ease-in',
        })}
      >
        <MediaSymbol
          {...mediaSymbolProps}
          size={this.props.width / 6}
          icon={this.state.isPlay ? 'pause' : 'play'}
          data-test={this.state.isPlay ? 'video-pause' : 'video-play'}
        />
      </FlexCenterStretched>
    );
  };

  renderTrigger = () => {
    return (
      <FlexCenterStretched
        innerRef={(elem: HTMLElement) => {
          this.trigger = elem;
        }}
        data-test="video-trigger"
        absolute
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      />
    );
  };

  renderLoading = () => {
    return (
      <FlexCenterStretched
        absolute
        className={css({alignItems: 'start'})}
        data-test="video-loading-wrap"
      >
        <Placeholder
          publicId={this.props.publicId}
          width={this.props.width}
          height={this.props.height}
          mediaType={MediaSymbolTypes.VIDEO}
          transformation={this.props.transformation}
        />
      </FlexCenterStretched>
    );
  };

  onMouseOver = () => {
    if (isMobile) return;

    this.setState(() => ({
      isOver: true,
    }));

    this.timeoutId = setTimeout(() => {
      this.setState(() => ({
        isOver: false,
      }));
    }, 5000);

    if (this.isControls()) {
      document.body.style.cursor = 'pointer';
    }
  };

  onMouseOut = () => {
    if (isMobile) return;

    this.setState(() => ({
      isOver: false,
    }));

    if (this.isControls()) {
      document.body.style.cursor = '';
    }
  };

  toggle = () => {
    if (this.state.isPlay) {
      this.stop();
    } else {
      this.play();
    }
  };

  play = () => {
    this.setState(() => ({
      isPlay: true,
    }));
    //https://developers.google.com/web/updates/2016/03/play-returns-promise
    var playPromise = this.video.play();
    // In browsers that don’t yet support this functionality,
    // playPromise won’t be defined.
    if (playPromise !== undefined) {
      playPromise
        .then(function() {
          // Automatic playback started!
        })
        .catch((error: any) => {
          this.setState(() => ({
            isPlay: false,
          }));

          console.error('Video Error: Click to play!', error);
        });
    }

    this.video.play();

    this.props.context.events(Events.VIDEO_START, this.props.publicId);
  };

  stop = () => {
    this.setState(() => ({
      isPlay: false,
    }));

    this.video.pause();

    if (this.props.active) {
      this.props.context.events(Events.VIDEO_PAUSE, this.props.publicId);
    }
  };

  shouldMute = () => {
    const mode = this.props.context.config.selectDisplayPropsMode();
    const isAutoplayAndFirst = this.props.isFirst && this.isAutoPlay();
    return isMobile || isAutoplayAndFirst || (mode === DisplayMode.EXPANDED) || !this.playSound();
  };

  render(props: any, state: VideoState) {
    const showVideoTag = props.preload ||props.active || this.video;

    return (
      <FlexCenterStretched relative data-test="video-wrap">
        {showVideoTag ? (
            <$Video
              //@ts-ignore
            disablepictureinpicture controlslist="nodownload"
            controls={this.isNativeControls()}
            itemprop="video"
            innerRef={(elem: any) => (this.video = elem)}
            width={props.width}
            height={props.height}
            muted={this.shouldMute()}
            key={state.key}
            loop={this.isLoop() || !this.isControls()}
            //@ts-ignore
            autoplay={isMobile && isIOS}
            //@ts-ignore
            playsinline={isMobile}
            onCanPlay={this.onCanPlay}
            onTimeUpdate={this.onTimeUpdate}
            onEnded={
              this.isControls() && !this.isLoop() ? this.onEnded : undefined
            }
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
          >
            {state.url &&
              state.url.map((url: string) => (
                <source onError={this.onError} src={url} key={url} />
              ))}
          </$Video>
        ) : null}
        {this.isMinimalControls() && state.ready && !state.error
          ? this.renderMediaSymbol()
          : undefined}
        {this.isMinimalControls() && state.ready && !state.error
          ? this.renderTrigger()
          : undefined}
        {!state.ready && !state.error && !this.isNativeControls() ? this.renderLoading() : null}
        {state.error ? (
          <FlexCenterStretched absolute>
            <Error width={props.width} type={MediaSymbolTypes.VIDEO} />
          </FlexCenterStretched>
        ) : null}
      </FlexCenterStretched>
    );
  }
}

export default withContext(Video);
