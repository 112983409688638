import {ImageProps} from './Image';
import {getAdjustedDims, checkSameRatio} from '../../utils/assetHelpers';
import {MediaSymbolTypes} from '../../typing/enums';
import {LoadedAsset} from '../../typing';

const getHighResUrl = (
  props: ImageProps,
  width: number,
  height: number,
  transformation: any
): string => {
  const {mediaType, publicId, context} = props;

  switch (mediaType) {
    case MediaSymbolTypes.VIDEO:
      return context.cloudinary.getVideoThumbnailUrl(
        publicId,
        width,
        height,
        transformation
      );
    case MediaSymbolTypes.THREE:
      return context.cloudinary.getThreeThumbnailUrl(
        publicId,
        width,
        height,
        transformation
      );
    default:
      return context.cloudinary.getImageUrl(
        publicId,
        width,
        height,
        transformation
      );
  }
};

const getZoomUrl = (
  props: ImageProps,
  width: number,
  height: number,
  transformation: any
): string => {
  const {mediaType, publicId, context} = props;

  return mediaType === MediaSymbolTypes.VIDEO
    ? context.cloudinary.getVideoThumbnailUrl(
        publicId,
        width,
        height,
        transformation
      )
    : context.cloudinary.getImageUrl(publicId, width, height, transformation, {
        pgwact: 1,
      });
};

const loadHighResImage = (
  props: ImageProps,
  cacher: Function,
  width: number,
  height: number,
  transformation: any
) => {
  return new Promise((resolve: any, reject: any) => {
    const url = getHighResUrl(props, width, height, transformation);
    cacher(props.publicId, url, width, height, transformation).then(
      (asset: LoadedAsset) => {
        resolve(asset);
      },
      () => {
        reject();
      }
    );
  });
};

const loadZoomImage = (
  props: ImageProps,
  cacher: Function,
  width: number,
  height: number,
  transformation: any
) =>
  new Promise((resolve: any, reject: any) => {
    const zoomLevel = props.context.config.selectZoomPropsLevel();
    const zoomWidth = width * zoomLevel;
    const zoomHeight = height * zoomLevel;
    const url = getZoomUrl(props, zoomWidth, zoomHeight, transformation);

    cacher(props.publicId, url, zoomWidth, zoomHeight, transformation).then(
      (asset: LoadedAsset) => {
        resolve(asset);
      },
      () => {
        reject();
      }
    );
  });
export default {
  getAdjustedDims,
  checkSameRatio,
  loadHighResImage,
  loadZoomImage,
};
