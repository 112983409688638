import {toCamelCase} from './string';

export const flatten = function(data: any): string {
  let obj = {};
  let result: string[] = [];

  const recurse = (cur: any, prop: any) => {
    if (Object(cur) !== cur) {
      obj[prop] = cur;
      result.push(`${prop}=${encodeURIComponent(cur)}`);
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop ? toCamelCase(`${prop} ${i}`) : '' + i);
      if (l == 0) obj[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? toCamelCase(`${prop} ${p}`) : p);
      }
      if (isEmpty) obj[prop] = {};
    }
  };

  recurse(data, '');

  return result.join('&');
};
