import {h} from 'preact';
import styled, {css, cx} from 'react-emotion';
import SvgIcon from '../SvgIcon/SvgIcon';
import * as ICONS from '../../assets/icons';
import {MediaSymbolProps} from '../../typing';
import {MediaSymbolTypes, MediaSymbolShape} from '../../typing/enums';
import {
  MEDIA_ICON_DEFAULTS,
  MEDIA_ICON_WITH_BG_DEFAULTS,
} from '../../config/defaults';
import {getRgbaColor} from '../../utils/color';

const $Wrap = styled('div')`
  display: inline-flex;
`;

const getIcon = (type: MediaSymbolTypes): string => {
  return type === MediaSymbolTypes.SPIN
    ? ICONS.spin
    : type === MediaSymbolTypes.VIDEO
    ? ICONS.play
    : type === MediaSymbolTypes.THREE
    ? ICONS.i3D
    : type === MediaSymbolTypes.IMAGE
    ? ICONS.image
    : '';
};

const getBackgroundColorCls = (color: string, opacity: number) => css`
  background-color: ${getRgbaColor(color, opacity)};
`;

const getShadowCls = () => css`
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.7));
`;

const isBackgroundColor = (
  style: MediaSymbolShape,
  type: MediaSymbolTypes
): boolean => style !== MediaSymbolShape.NONE;

const getStyleCls = (props: MediaSymbolProps): string => {
  const style = props.shape;
  const size = props.size || 0;

  const roundCls = css`
    border-radius: 100%;
    padding: ${size / 5}px;
  `;

  const squareCls = css`
    padding: ${size / 5}px;
  `;

  const radiusCls = css`
    border-radius: ${size / 5}px;
    padding: ${size / 5}px;
  `;

  return cx(
    css`
      align-items: center;
      justify-content: center;
      width: ${props.size}px;
      height: ${props.size}px;
    `,
    style === MediaSymbolShape.ROUND
      ? roundCls
      : style === MediaSymbolShape.SQUARE
      ? squareCls
      : style === MediaSymbolShape.RADIUS
      ? radiusCls
      : ''
  );
};

const getPropsDefaults = (propsIn: MediaSymbolProps) =>
  propsIn.shape === MediaSymbolShape.NONE
    ? MEDIA_ICON_DEFAULTS
    : {...MEDIA_ICON_DEFAULTS, ...MEDIA_ICON_WITH_BG_DEFAULTS};

const MediaSymbol = (propsIn: MediaSymbolProps) => {
  const props = {...getPropsDefaults(propsIn), ...propsIn};

  const bgCls = isBackgroundColor(props.shape, props.type)
    ? getBackgroundColorCls(props.color || '', props.opacity || 0)
    : '';
  const styleCls = getStyleCls(props);
  const shadowCls = props.iconShadow ? getShadowCls() : '';

  return (
    <$Wrap {...props} className={cx(bgCls, styleCls, props.className)}>
      <SvgIcon
        className={shadowCls}
        color={props.iconColor}
        path={props.icon ? ICONS[props.icon] : getIcon(props.type)}
      />
    </$Wrap>
  );
};

export default MediaSymbol;
