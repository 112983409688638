import {h} from 'preact';
import styled, {keyframes} from 'react-emotion';

interface Props {
    width?: number;
    color?: string;
    opacity?: number;
    className?: string;
}

const circleAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader: any = styled('div')`
  display: inline-block;
  position: relative;
  width: ${(props: any) => props.width}px;
  height: ${(props: any) => props.width}px;
  opacity: ${(props: any) => props.opacity};

 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 8px solid ${(props: any) => props.color};
  border-radius: 50%;
  animation: ${circleAnimation} 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${(props: any) => props.color} transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.3s;
  }
  &:nth-child(2) {
    animation-delay: -0.2s;
  }
  &:nth-child(3) {
    animation-delay: -0.10s;
  }
`;

const LoadingCssOnly = ({color, opacity, width, className}: Props) => (
    <Loader color={color} opacity={opacity} width={width} className={className}>
        <div />
        <div />
        <div />
        <div />
    </Loader>);

export default LoadingCssOnly;
