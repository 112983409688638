import {Skin} from '../typing/enums';

export default {
  [Skin.ADIDAS]: {
    bgColor: '#EBEDEE',
    aspectRatio: '1:1',
    navigation: 'none',
    themeProps: {
      shape: 'square',
      primary: '#000',
      onPrimary: '#FFF',
    },
    carouselLocation: 'left',
    thumbnailProps: {
      width: 54,
      height: 54,
      spacing: 10,
      borderWidth: 1,
      selectedStyle: 'border',
      selectedBorderWidth: 2,
      selectedBorderPosition: 'bottom',
      MediaSymbolShape: 'none',
      mediaSymbolColor: '#FFF',
      navigationShape: 'square',
      navigationSize: 24,
      navigationFloat: true,
    },
    viewportBreakpoints: [
      {
        breakpoint: 600,
        carouselLocation: 'bottom',
        navigation: 'always',
        thumbnailProps: {
          gutter: 0,
          selectedBorderPosition: 'top-bottom',
          navigation: false,
        },
      },
    ],
  },
  [Skin.CA]: {
    aspectRatio: '3:4',
    carouselOffset: 20,
    carouselLocation: 'left',
    navigation: 'none',
    themeProps: {
      primary: '#000',
      onPrimary: '#FFF',
    },

    radius: 10,

    thumbnailProps: {
      width: 120,
      height: 180,
      gutter: 20,
      spacing: 10,
      borderWidth: 0,
      radius: 10,
      selectedStyle: 'border',
      selectedBorderPosition: 'bottom',
      selectedBorderWidth: 10,
      selectedBorderColor: '#D42E54',
      selectedBorderOpacity: 0.5,
      mediaSymbolColor: '#FFF',
      navigationShape: 'round',
      navigationSize: 35,
      navigationIconColor: '#000',
      navigationColor: '#FFF',
      navigationBorderColor: '#B5B2B1',
      navigationBorderWidth: 2,
    },
    zoomProps: {
      //level: 3,
      trigger: 'hover',
      viewerPosition: 'right',
      viewerOffset: 10,
      lensShadow: false,
    },
    // viewportBreakpoints: [
    //   {
    //     breakpoint: 600,
    //     carouselLocation: 'bottom',
    //     thumbnailProps: {
    //       gutter: 0,
    //       selectedBorderPosition: 'top-bottom',
    //       navigation: false,
    //     },
    //   },
    // ],
  },
};
